a,
a:hover,
a:visited,
a:focus {
  text-decoration: none;
  outline: none;
}
.error {
  color: #ff580c;
  background-color: #ffd9cc;
  border: 1px solid #ff580c;
}
.btn.disabled {
  opacity: 0.45;
}
.valid {
  color: #ff580c;
  background: #ff580c;
  border: 1px solid #ff580c;
}
.error-msg {
  position: relative;
  left: 380px;
  font-size: 1.2rem;
  width: 225px;
  border-radius: 4px;
  padding: 5px 7px;
  background-color: #fed3d5;
  color: #c03634;
  display: none;
  top: -44px;
}
@media screen and (min-width:992px) and (max-width:1199px) {
  .error-msg {
    left: 325px;
  }
}
.error-msg.fixed {
  position: inherit;
  width: auto;
  margin-top: 10px;
}
.error-msg p {
  font-size: 1.2rem!important;
  line-height: 1.8rem!important;
  margin-bottom: 0!important;
  color: #c03634!important;
}
@media screen and (max-width:991px) {
  .error-msg {
    position: initial;
    width: auto;
    margin-top: 10px;
  }
}
.error-msg .arrow-left {
  width: 0;
  height: 0;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  position: absolute;
  top: 16px;
  left: -6px;
  border-right: 7px solid #fed3d5;
}
@media screen and (max-width:991px) {
  .error-msg .arrow-left {
    display: none;
  }
}
.ring-loader {
  display: flex !important;
  justify-content: center;
  align-items: center;
  text-indent: -1000px;
  color: transparent !important;
  text-align: left !important;
  overflow: hidden !important;
}
.ring-loader:after {
  content: " ";
  position: absolute;
  display: block;
  width: 15px;
  height: 15px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #ff804b;
  border-color: #ff804b transparent #ff804b transparent;
  animation: ring-loader 1.2s linear infinite;
}
@keyframes ring-loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.form-control.form-error.has-error {
  border: 1px solid #c03634 !important;
}
.alert-danger {
  color: #2d3436;
  background-color: #fff0f0;
  border: none;
  border-left: 5px solid #e84848;
  border-radius: 0.4rem;
  line-height: 2rem;
}
.alert-warning {
  color: #2d3436;
  background-color: #fff7dd;
  border: none;
  border-left: 5px solid #f0ce64;
  border-radius: 0.4rem;
  line-height: 2rem;
}
.alert-info {
  color: #2d3436;
  background-color: #e7f7ff;
  border: none;
  border-left: 5px solid #31708f;
  border-radius: 0.4rem;
  line-height: 2rem;
}
.has-error .form-control-feedback {
  color: #c03634;
  top: 10px;
}
.has-success .form-control-feedback {
  color: #3c763d;
}
.float-container,
header.header-simple .navigation-container .container #main-menu,
header .navigation-container .container #main-menu,
#inscription .count-society,
#inscription-entreprise .count-society {
  float: left;
  width: 100%;
}
strong {
  font-weight: bold;
}
.d-none {
  display: none;
}
.centered-div {
  display: block;
  margin-right: auto;
  margin-left: auto;
}
.fit-content {
  width: fit-content;
}
/**
  BOOTSTRAP-EXTENSION GRID 5 COLUMNS
*/
.col-xs-5ths,
.col-sm-5ths,
.col-md-5ths,
.col-lg-5ths {
  position: relative;
  min-height: 1px;
  padding: 0 10px;
}
.col-xs-5ths {
  width: 20%;
  float: left;
}
@media (min-width: 768px) {
  .col-sm-5ths {
    width: 20%;
    float: left;
  }
}
@media (min-width: 992px) {
  .col-md-5ths {
    width: 20%;
    float: left;
  }
}
@media (min-width: 1200px) {
  .col-lg-5ths {
    width: 20px;
    float: left;
  }
}
/**
  SPRITE
**/
/* old ? */
/**
  SPRITE PROFIL
**/
/**
  SPRITE PROFILE SERVICES
**/
.search-service {
  background-position: -3px 0;
  width: 101px;
  height: 101px;
}
.hands-service {
  background-position: -114px 0;
  width: 101px;
  height: 101px;
}
.info-service {
  background-position: -225px 0;
  width: 101px;
  height: 101px;
}
.thumbsdown-service {
  background-position: -336px 0;
  width: 70px;
  height: 70px;
}
.money-service {
  background-position: -416px 0;
  width: 70px;
  height: 70px;
}
.google-service {
  background-position: -496px 0;
  width: 70px;
  height: 70px;
}
.note-service {
  background-position: -576px 0;
  width: 70px;
  height: 70px;
}
.thumbsup-service {
  background-position: -646px 0;
  width: 101px;
  height: 101px;
}
.hands-money-service {
  background-position: -1296px 0;
  width: 101px;
  height: 101px;
}
.checked-service {
  background-position: -849px 0;
  width: 101px;
  height: 101px;
}
.bilan-service {
  background-position: -1076px 0;
  width: 101px;
  height: 101px;
}
.declaration-service {
  background-position: -749px 0;
  width: 101px;
  height: 101px;
}
.files-service {
  background-position: -1010px 0;
  width: 59px;
  height: 65px;
}
.file-service {
  background-position: -951px 0;
  width: 59px;
  height: 65px;
}
/**
  SPRITE APP STORE
**/
/**
  SPRITE OLD
**/
/**
LOGO PRESSE
*/
/*
GENERAL
*/
/*
NAVIGATION
*/
/*
PROFIL ICONS
*/
/*sprite categorie univers thin*/
html {
  font-size: 9px;
}
@media (min-width:768px) {
  html {
    font-size: 10px;
  }
}
a {
  color: #ff804b;
}
a:hover,
a:active {
  color: #e77544;
}
hr {
  border-top: 0;
  border-bottom: 1px solid #cecece;
  width: 100%;
  height: 0;
}
.wrapper-grey-2 {
  background-color: #f0f0f0;
  border: 1px solid #e8e5e5;
}
#page-content {
  width: 100%;
}
@media (max-width:767px) {
  #page-content {
    margin-top: 55px;
  }
  #page-content.smooth_content {
    margin-top: 0;
  }
}
.sprite {
  background-image: url('/images/habitatpresto/sprites.png');
  background-repeat: no-repeat;
}
.sprite-general {
  background-image: url('/images/habitatprestopro/sprites.png');
  background-repeat: no-repeat;
}
.sprite-nav {
  background-image: url('/images/habitatprestopro/sprite-navigation.png');
  background-repeat: no-repeat;
}
.sprite-profile {
  background-image: url('/images/habitatprestopro/sprite-profile.png');
  background-repeat: no-repeat;
}
.sprite-univers-thin {
  background-image: url('/images/habitatpresto/sprite-univers-thin.png');
  background-repeat: no-repeat;
}
.sprite-apps {
  background-image: url('/images/habitatprestopro/sprite-apps.png');
  background-repeat: no-repeat;
}
.sprite-services {
  background-image: url('/images/habitatprestopro/sprite-site-internet.png');
  background-repeat: no-repeat;
}
.sprite-align {
  display: inline-block;
  vertical-align: middle;
}
.float-r {
  float: right;
}
.align-right {
  text-align: right;
}
.uppercase {
  text-transform: uppercase;
}
.bold {
  font-weight: bold;
}
/*********************button***********************/
.orange {
  color: #ff804b;
}
a.orange,
a.orange:active,
a.orange:visited {
  color: #ff804b;
  text-decoration: none;
}
a.orange:hover {
  color: #ff804b;
  text-decoration: underline;
}
.bt-orange {
  background-color: #ff804b;
  color: #FFF;
  border-radius: 0.8rem;
  font-weight: bold;
  padding: 10px 20px;
  text-align: center;
  display: inline-block;
}
a.bt-orange,
a.bt-orange:active,
a.bt-orange:visited {
  background-color: #ff804b;
  color: #FFFFFF;
  font-weight: bold;
  border-radius: 0.8rem;
  padding: 10px 20px;
}
a.bt-orange:hover {
  background-color: #e77544;
  color: #FFFFFF;
  border-radius: 0.8rem;
  padding: 10px 20px;
}
.bt-border-orange {
  background-color: #FFF;
  color: #ff804b;
  font-weight: bold;
  border: 2px solid #ff804b;
  border-radius: 0.8rem;
  padding: 10px 20px;
  text-align: center;
}
a.bt-border-orange,
a.bt-border-orange:active,
a.bt-border-orange:visited {
  background-color: #FFFFFF;
  color: #ff804b;
  font-weight: bold;
  border: 2px solid #ff804b;
  border-radius: 0.8rem;
  padding: 10px 20px;
}
a.bt-border-orange:hover {
  background-color: #e77544;
  color: #FFFFFF;
  border: 2px solid #e77544;
  border-radius: 0.8rem;
  padding: 10px 20px;
}
.bt-bleu {
  background-color: #28a3b2;
  color: #FFFFFF;
  font-weight: bold;
  border-radius: 0.8rem;
  padding: 10px 20px;
  text-align: center;
  display: inline-block;
}
a.bt-bleu,
a.bt-bleu:active,
a.bt-bleu:visited {
  background-color: #28a3b2;
  font-weight: bold;
  color: #FFFFFF;
  border-radius: 0.8rem;
  padding: 10px 20px;
}
a.bt-bleu:hover {
  background-color: #1e96a5;
  color: #FFFFFF;
  border-radius: 0.8rem;
  padding: 10px 20px;
}
.bt-big {
  font-size: 18px;
  padding: 15px 20px !important;
}
/***************positionnenment***********/
.mt-5 {
  margin-top: 5px;
}
.mt-10 {
  margin-top: 10px;
}
.mt-15 {
  margin-top: 15px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-30 {
  margin-top: 30px;
}
.mt-40 {
  margin-top: 40px!important;
}
.mt-50 {
  margin-top: 50px;
}
.mt-60 {
  margin-top: 60px;
}
.mt-80 {
  margin-top: 80px;
}
.mt-100 {
  margin-top: 100px;
}
.mt-140 {
  margin-top: 140px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-15 {
  margin-bottom: 15px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mb-40 {
  margin-bottom: 40px;
}
.mb-60 {
  margin-bottom: 60px;
}
.mb-100 {
  margin-bottom: 100px;
}
.ml-5 {
  margin-left: 5px;
}
.ml-10 {
  margin-left: 10px;
}
.mr-0 {
  margin-right: 0 !important;
}
.mr-10 {
  margin-right: 10px;
}
.no-padding {
  padding: 0!important;
}
.no-margin {
  margin: 0!important;
}
.pt-10 {
  padding-top: 10px;
}
.pt-20 {
  padding-top: 20px;
}
.pr-0 {
  padding-right: 0;
}
.pl-0 {
  padding-left: 0;
}
.pb-10 {
  padding-bottom: 10px;
}
.p-20 {
  padding: 20px 0;
}
.p-30 {
  padding: 30px 0;
}
.p-40 {
  padding: 40px 0;
}
.lh-10 {
  line-height: 10px;
}
.lh-15 {
  line-height: 15px;
}
.lh-20 {
  line-height: 20px;
}
.lh-25 {
  line-height: 25px;
}
.lh-30 {
  line-height: 30px;
}
.legal-notice {
  font-size: 1.2rem;
  line-height: 1.6rem;
  color: #757575;
  margin-top: 15px;
  font-family: Arial;
  text-align: justify;
}
.legal-notice a {
  text-decoration: underline;
  color: #474747;
}
.pagination {
  justify-content: center;
}
.modal-content {
  border: none !important;
  border-radius: 1rem !important;
}
@font-face {
  font-family: "Open Sans Condensed";
  src: url('/build/fonts/opensans-condlight-webfont.eot');
  src: url('/build/fonts/opensans-condlight-webfont.eot?#iefix') format('embedded-opentype'), url('/build/fonts/opensans-condlight-webfont.woff2') format('woff2'), url('/build/fonts/opensans-condlight-webfont.woff') format('woff'), url('/build/fonts/opensans-condlight-webfont.ttf') format('truetype'), url('/build/fonts/opensans-condlight-webfont.svg#opensans-condlight-webfont') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Open Sans Condensed";
  src: url('/build/fonts/opensans-condbold-webfont.eot');
  src: url('/build/fonts/opensans-condbold-webfont.eot?#iefix') format('embedded-opentype'), url('/build/fonts/opensans-condbold-webfont.woff2') format('woff2'), url('/build/fonts/opensans-condbold-webfont.woff') format('woff'), url('/build/fonts/opensans-condbold-webfont.ttf') format('truetype'), url('/build/fonts/opensans-condbold-webfont.svg#opensans-condbold-webfont') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Open Sans";
  src: url('/build/fonts/opensans-regular-webfont.eot');
  src: url('/build/fonts/opensans-regular-webfont.eot?#iefix') format('embedded-opentype'), url('/build/fonts/opensans-regular-webfont.woff2') format('woff2'), url('/build/fonts/opensans-regular-webfont.woff') format('woff'), url('/build/fonts/opensans-regular-webfont.ttf') format('truetype'), url('/build/fonts/opensans-regular-webfont.svg#opensans-regular-webfont') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Open Sans";
  src: url('/build/fonts/opensans-bold-webfont.eot');
  src: url('/build/fonts/opensans-bold-webfont.eot?#iefix') format('embedded-opentype'), url('/build/fonts/opensans-bold-webfont.woff2') format('woff2'), url('/build/fonts/opensans-bold-webfont.woff') format('woff'), url('/build/fonts/opensans-bold-webfont.ttf') format('truetype'), url('/build/fonts/opensans-bold-webfont.svg#opensans-bold-webfont') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
/**
  TAILLES
*/
#sizes .list .base {
  font-size: 1.4rem;
  line-height: 1.8rem;
}
#sizes .list .xxl {
  font-size: 4.8rem;
  line-height: 4.8rem;
}
#sizes .list .xl_l {
  font-size: 3.6rem;
  line-height: 3.6rem;
}
#sizes .list .l {
  font-size: 3.3rem;
  line-height: 3.6rem;
}
#sizes .list .m {
  font-size: 2.4rem;
  line-height: 3rem;
}
#sizes .list .s {
  font-size: 1.8rem;
  line-height: 2.2rem;
}
#sizes .list .xs {
  font-size: 1.2rem;
  line-height: 1.5rem;
}
#sizes .list .xxs {
  font-size: 1rem;
  line-height: 1.4rem;
}
#sizes .list .quotation {
  font-size: 1.4rem;
  line-height: 2.4rem;
}
/**
  FONT COMPLETES
*/
#completes .list .base {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: #474747;
}
#completes .list .xxl_title_uppercase {
  font-size: 4.8rem;
  line-height: 4.8rem;
  color: white;
}
#completes .list .s_subtitle {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.8rem;
  line-height: 2.2rem;
  color: white;
}
#completes .list .s_form_submit {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.8rem;
  line-height: 2.2rem;
  color: #474747;
}
#completes .list .xl_l_key_number {
  font-size: 3.6rem;
  line-height: 3.6rem;
  color: #474747;
}
#completes .list .l {
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #474747;
}
#completes .list .quotation {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.4rem;
  line-height: 2.4rem;
  color: #474747;
  font-style: italic;
}
#completes .list .m {
  font-family: 'Open Sans', sans-serif;
  font-size: 2.4rem;
  line-height: 3rem;
  color: #474747;
}
#completes .list .m_cond {
  font-size: 2.4rem;
  line-height: 3rem;
  color: #474747;
}
#completes .list .xxs {
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  line-height: 1.4rem;
  color: #474747;
}
body,
.txt-base,
.txt-base:hover,
.txt-base:focus,
.txt-base:visited {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: #474747;
}
.privacy-center-button {
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 5px 15px;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  background-color: #bbbbbb;
  border-top-left-radius: 4px;
  z-index: 3000;
  font-family: 'Open Sans', sans-serif;
}
.privacy-center-button:hover {
  color: #FFFFFF;
  background-color: #02c4b3;
}
.google-button {
  all: unset;
  margin: auto;
  width: fit-content;
  height: 52px;
  background-color: #4285f4;
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  transition: all 300ms ease-in-out;
  display: flex;
  justify-content: space-between;
}
.google-button .google-icon-wrapper {
  margin: 1px;
  width: 50px;
  height: 50px;
  border-radius: 1px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.google-button .google-button-text {
  display: flex;
  color: #ffffff!important;
  font-size: 16px;
  letter-spacing: 0.2px;
  margin-left: 1em;
  margin-right: 1em;
  margin-top: 0!important;
  margin-bottom: 0!important;
  align-items: center;
}
.google-button:hover {
  box-shadow: 0 0 3px 3px rgba(66, 133, 244, 0.3);
  cursor: pointer;
}
.google-button:active {
  background: #3367d6;
}
/**
  PADDING DES BOUTONS
*/
/**
  BORDER DES BOUTONS
*/
/**
  STYLE DES BOUTONS
*/
.button.white1 {
  color: #05d8c6;
  border: 1px solid #05d8c6;
  font-weight: bold;
  background-color: transparent;
  transition: 0.3s;
  border-radius: 0.8rem 0.8rem 0.8rem 0.8rem;
  padding: 10px 20px;
}
.button.white1:hover {
  color: white;
  border: 1px solid #28a3b2;
  font-weight: bold;
  background-color: #28a3b2;
  transition: 0.3s;
  border-radius: 0.8rem 0.8rem 0.8rem 0.8rem;
}
.button.white2 {
  color: #ff804b;
  border: 1px solid #ff804b;
  font-weight: bold;
  background-color: transparent;
  transition: 0.3s;
  border-radius: 0.8rem 0.8rem 0.8rem 0.8rem;
  padding: 10px 20px;
}
.button.white2:hover {
  color: white;
  border: 1px solid #ff804b;
  font-weight: bold;
  background-color: #ff804b;
  transition: 0.3s;
  border-radius: 0.8rem 0.8rem 0.8rem 0.8rem;
}
.button.green1 {
  color: white;
  border: 1px solid #28a3b2;
  font-weight: bold;
  background-color: #28a3b2;
  transition: 0.3s;
  border-radius: 0.8rem 0.8rem 0.8rem 0.8rem;
  padding: 10px 20px;
}
.button.green1:hover {
  color: white;
  border: 1px solid #02c4b3;
  font-weight: bold;
  background-color: #02c4b3;
  transition: 0.3s;
  border-radius: 0.8rem 0.8rem 0.8rem 0.8rem;
}
.button.orange1 {
  color: white;
  border: 1px solid #ff804b;
  font-weight: bold;
  background-color: #ff804b;
  transition: 0.3s;
  border-radius: 0.8rem 0.8rem 0.8rem 0.8rem;
  padding: 10px 20px;
}
.button.orange1:hover {
  color: white;
  border: 1px solid #e77544;
  font-weight: bold;
  background-color: #e77544;
  transition: 0.3s;
  border-radius: 0.8rem 0.8rem 0.8rem 0.8rem;
}
#paddings .list .button.padding-1 {
  padding: 10px 20px;
}
/**
  BUTTON STYLES
*/
#styles .list .button.style-white1 {
  color: #05d8c6;
  border: 1px solid #05d8c6;
  font-weight: bold;
  background-color: transparent;
  transition: 0.3s;
  border-radius: 0.8rem 0.8rem 0.8rem 0.8rem;
}
#styles .list .button.style-white2 {
  color: #ff804b;
  border: 1px solid #ff804b;
  font-weight: bold;
  background-color: transparent;
  transition: 0.3s;
  border-radius: 0.8rem 0.8rem 0.8rem 0.8rem;
}
#styles .list .button.style-green1 {
  color: white;
  border: 1px solid #28a3b2;
  font-weight: bold;
  background-color: #28a3b2;
  transition: 0.3s;
  border-radius: 0.8rem 0.8rem 0.8rem 0.8rem;
}
#styles .list .button.style-green2 {
  color: white;
  border: 1px solid #02c4b3;
  font-weight: bold;
  background-color: #02c4b3;
  transition: 0.3s;
  border-radius: 0.8rem 0.8rem 0.8rem 0.8rem;
}
#styles .list .button.style-orange1 {
  color: white;
  border: 1px solid #ff804b;
  font-weight: bold;
  background-color: #ff804b;
  transition: 0.3s;
  border-radius: 0.8rem 0.8rem 0.8rem 0.8rem;
}
#styles .list .button.style-orange2 {
  color: white;
  border: 1px solid #e77544;
  font-weight: bold;
  background-color: #e77544;
  transition: 0.3s;
  border-radius: 0.8rem 0.8rem 0.8rem 0.8rem;
}
.card {
  border-radius: 1rem;
  background-color: #FFFFFF;
  margin-bottom: 30px;
  width: 100%;
  padding: 15px;
}
.iti__flag {
  background-image: url("../img/flags.png");
}
@media (min-resolution: 192dpi) {
  .iti__flag {
    background-image: url("../img/flags@2x.png");
  }
}
.d-flex-align-center {
  display: flex;
  align-items: center;
}
.d-flex-align-center label {
  font-weight: normal;
}
.d-flex-justify-center {
  display: flex;
  justify-content: center;
}
.absolute-icon-parent {
  position: relative;
}
.absolute-icon-parent .see-password-icon {
  position: absolute;
  right: 1.5rem;
  top: .8rem;
  cursor: pointer;
  color: lightgrey;
}
.input_wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  height: 40px;
  position: relative;
  cursor: pointer;
  margin: 15px 0;
}
.input_wrapper input[type="checkbox"] {
  width: 80px;
  height: 40px;
  cursor: pointer;
  appearance: none;
  background: #5c6369;
  border-radius: 3rem;
  position: relative;
  outline: 0;
  transition: all .2s;
}
.input_wrapper input[type="checkbox"]::after {
  position: absolute;
  content: "";
  top: 3px;
  left: 3px;
  width: 34px;
  height: 34px;
  background: #ffffff;
  z-index: 2;
  border-radius: 6rem;
  transition: all .35s;
}
.input_wrapper svg {
  position: absolute;
  top: 50%;
  transform-origin: 50% 50%;
  fill: #fff;
  transition: all .35s;
  z-index: 1;
}
.input_wrapper .is_checked {
  width: 18px;
  left: 2%;
  top: 38%;
  transform: translateX(0px) translateY(-40%) scale(1);
}
.input_wrapper .is_unchecked {
  width: 15px;
  left: 6%;
  top: 40%;
  transform: translateX(10px) translateY(-40%) scale(1);
}
/* Checked State */
.input_wrapper input[type="checkbox"]:checked {
  background: #ff804b;
}
.input_wrapper input[type="checkbox"]:checked:after {
  left: calc(55%);
}
.input_wrapper input[type="checkbox"]:checked + .is_checked {
  transform: translateX(0) translateY(-30%) scale(1);
}
.input_wrapper input[type="checkbox"]:checked ~ .is_unchecked {
  transform: translateX(-190%) translateY(-30%) scale(0);
}
.new-form-style form .form-control {
  font-size: 1.4rem;
  min-height: 3.5rem;
  border-radius: .3rem;
}
.new-form-style form textarea.form-control {
  height: auto;
}
.new-form-style form select[multiple],
.new-form-style form select[size] {
  height: auto;
}
.new-form-style form .form-group {
  margin-right: 0;
  margin-left: 0;
  text-align: left;
  width: 100%;
}
.new-form-style form .form-group label {
  padding: 5px 0;
  font-weight: bold;
}
/****** LAYOUTS ******/
.menu-pro-interface {
  margin: 20px 0 30px;
}
@media screen and (max-width:991px) {
  .menu-pro-interface {
    position: fixed;
    display: flex;
    top: 0;
    right: -100%;
    z-index: 20;
    width: 100%;
    margin: 0;
    height: 100%;
    justify-content: flex-end;
    -webkit-justify-content: flex-end;
    background: transparent;
  }
}
@media screen and (min-width:992px) {
  .menu-pro-interface > ul {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    background-color: #FFFFFF;
  }
}
@media screen and (min-width:992px) {
  .menu-pro-interface > ul > li:first-of-type,
  .menu-pro-interface > ul > li:last-of-type {
    display: none;
  }
}
@media screen and (min-width:992px) {
  .menu-pro-interface > ul > li {
    box-sizing: border-box;
    background-color: #FFFFFF;
    align-self: center;
    text-align: center;
    min-height: 43px;
    margin-top: 10px;
  }
}
.menu-pro-interface > ul > li .available-projects {
  max-height: 43px;
}
@media screen and (max-width:991px) {
  .menu-pro-interface > ul > li .available-projects {
    max-height: 58px;
  }
}
.menu-pro-interface > ul > li > a {
  position: relative;
  color: #474747;
  padding: 11px 15px;
  min-height: 43px;
  display: inline-block;
  line-height: 2rem;
  font-size: 1.6rem;
  transition: .3s;
  border-bottom: 5px solid transparent;
}
.menu-pro-interface > ul > li > a .number-of-projects,
.menu-pro-interface > ul > li > a .attention-picto {
  position: absolute;
  display: block;
  padding: 5px;
  border-radius: 100%;
  color: #FFFFFF;
  font-weight: bold;
  z-index: 11;
}
@media screen and (max-width:991px) {
  .menu-pro-interface > ul > li > a .number-of-projects,
  .menu-pro-interface > ul > li > a .attention-picto {
    position: relative;
    top: 0;
    display: inline-flex !important;
    align-content: center;
    justify-content: center;
  }
}
.menu-pro-interface > ul > li > a .number-of-projects {
  background-color: #ff804b;
  width: 30px;
  height: 30px;
  top: -15px;
  right: -15px;
}
.menu-pro-interface > ul > li > a .attention-picto {
  background: #f91617;
  width: 15px;
  height: 15px;
  top: 0;
  right: 0;
}
.menu-pro-interface > ul > li > a .more {
  color: #ff804b;
  font-weight: bold;
}
.menu-pro-interface > ul > li > a:hover,
.menu-pro-interface > ul > li > a:focus,
.menu-pro-interface > ul > li > a:active,
.menu-pro-interface > ul > li > a.active {
  border-bottom: 5px solid #ff804b;
}
.menu-pro-interface > ul > li > a:hover .more,
.menu-pro-interface > ul > li > a:focus .more,
.menu-pro-interface > ul > li > a:active .more,
.menu-pro-interface > ul > li > a.active .more {
  color: #FFFFFF;
}
@media screen and (max-width:991px) {
  .menu-pro-interface.nav-menu-mobile .close-menu-mobile {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -999;
  }
  .menu-pro-interface.nav-menu-mobile > ul {
    margin: 0;
    z-index: 11;
    overflow-y: auto;
    flex-direction: column;
    justify-content: flex-start;
    -webkit-justify-content: flex-start;
    width: 63%;
    height: 100%;
    background: #fff;
    box-shadow: 0 0 20px 5px rgba(0, 0, 0, 0.5);
  }
  .menu-pro-interface.nav-menu-mobile > ul li {
    text-align: left;
    width: 100%;
    border-top: 1px solid #f0f0f0;
  }
  .menu-pro-interface.nav-menu-mobile > ul li:first-of-type {
    border-top: none;
  }
  .menu-pro-interface.nav-menu-mobile > ul li .more {
    color: #ff804b;
    font-weight: bold;
  }
  .menu-pro-interface.nav-menu-mobile > ul li a {
    display: block;
    padding: 20px 25px;
    border-radius: 0rem;
  }
  .menu-pro-interface.nav-menu-mobile > ul li a:hover,
  .menu-pro-interface.nav-menu-mobile > ul li a:focus,
  .menu-pro-interface.nav-menu-mobile > ul li a:active {
    color: #FFFFFF;
    background-color: #28a3b2;
    border-radius: 0rem;
  }
  .menu-pro-interface.nav-menu-mobile > ul li a:hover .number-of-projects,
  .menu-pro-interface.nav-menu-mobile > ul li a:focus .number-of-projects,
  .menu-pro-interface.nav-menu-mobile > ul li a:active .number-of-projects {
    background-color: #FFFFFF;
    color: #28a3b2;
  }
  .menu-pro-interface.nav-menu-mobile .services-title {
    padding: 20px 25px;
    font-size: 1.6rem;
  }
  .menu-pro-interface.nav-menu-mobile #servicesLinksMobile a {
    color: #474747;
    font-size: 1.6rem;
  }
  .menu-pro-interface.nav-menu-mobile #servicesLinksMobile a:hover {
    color: #fff;
  }
}
.footer-pro {
  color: #fff;
  font-family: 'Open Sans', sans-serif;
  padding: 30px 0 15px 0;
  font-size: 1.5rem;
}
.footer-pro .heading {
  font-weight: bold;
  font-size: 1.6rem;
  margin-bottom: 10px;
}
@media screen and (max-width:767px) {
  .footer-pro .heading {
    font-size: 1.8rem;
    margin-top: 15px;
  }
}
.footer-pro hr {
  margin: 20px 0;
}
.footer-pro a {
  color: #fff;
}
.footer-pro ul {
  line-height: 30px;
}
.footer-pro .phone a {
  color: #05d8c6;
}
.footer-pro .community-list ul {
  width: 195px;
  margin: auto;
}
.footer-pro .community-list a {
  float: left;
  display: inline-block;
  margin: 0 10px;
}
.footer-pro .community-list .facebook {
  background-image: url('/images/habitatprestopro/sprites.png');
  background-repeat: no-repeat;
  background-position: -323px -115px;
  width: 44px;
  height: 42px;
}
.footer-pro .community-list .twitter {
  background-image: url('/images/habitatprestopro/sprites.png');
  background-repeat: no-repeat;
  background-position: -370px -115px;
  width: 42px;
  height: 42px;
}
.footer-pro .community-list .youtube {
  background-image: url('/images/habitatprestopro/sprites.png');
  background-repeat: no-repeat;
  background-position: -415px -115px;
  width: 43px;
  height: 42px;
}
.footer-pro .community-list .google {
  background-image: url('/images/habitatprestopro/sprites.png');
  background-repeat: no-repeat;
  background-position: -507px -115px;
  width: 43px;
  height: 43px;
}
.footer-pro .community-list .pinterest {
  background-image: url('/images/habitatprestopro/sprites.png');
  background-repeat: no-repeat;
  background-position: -462px -115px;
  width: 42px;
  height: 42px;
}
.footer-pro .logo {
  background-image: url('/images/habitatprestopro/sprites.png');
  background-repeat: no-repeat;
  display: inline-block;
  vertical-align: middle;
}
@media screen and (max-width:575px) {
  .footer-pro .link-column > div:nth-of-type(2) .heading {
    display: none;
  }
  .footer-pro .link-column > div:nth-of-type(2) ul {
    display: none;
  }
  .footer-pro .link-column > div:nth-of-type(3) .heading {
    display: none;
  }
  .footer-pro .link-column > div:nth-of-type(3) ul {
    display: none;
  }
}
.modal#unpaid-modal .modal-backdrop {
  background-color: #D9D9D9;
  opacity: 0.6;
}
.modal#unpaid-modal .modal-dialog {
  width: 90%;
  max-width: 500px;
  margin: 90px auto;
}
.modal#unpaid-modal .modal-body {
  padding: 40px 60px 0;
  font-size: 1.5rem;
}
.modal#unpaid-modal .modal-body p {
  line-height: 1.4em;
  margin: 3rem 0;
}
.modal#unpaid-modal .modal-footer {
  border-top: none;
  padding: 20px;
}
.modal#unpaid-modal .modal-footer .btn-primary {
  background-color: #75A876;
  border-color: #75A876;
  padding: 10px 30px;
  font-weight: bold;
  font-size: 1.5rem;
  border-radius: 10px;
}
.modal#unpaid-modal .modal-footer .btn-primary:hover {
  background-color: #5a905c;
  border-color: #5a905c;
}
.modal#unpaid-modal .modal-footer .btn-secondary {
  border-color: #ff804b;
  background-color: white;
  color: #ff804b;
  border-radius: 10px;
  padding: 10px 30px;
  font-weight: bold;
  font-size: 1.5rem;
}
.modal#unpaid-modal .modal-footer .btn-secondary:hover {
  background-color: #e6e6e6;
  border-color: #ff5c18;
}
/**
  HEADER
*/
header.header-simple {
  position: relative;
}
@media (min-width:768px) {
  header.header-simple {
    height: 60px;
  }
}
header.header-simple .sprite-fleche {
  background-image: url('/images/habitatprestopro/sprites.png');
  background-repeat: no-repeat;
  background-position: -106px -240px;
  width: 5px;
  height: 9px;
  display: inline-block;
}
header.header-simple .logo-container {
  box-shadow: 0 1px 5px 0 rgba(223, 223, 223, 0.44);
  width: 100%;
  height: 56px;
}
@media (min-width:768px) {
  header.header-simple .logo-container {
    position: absolute;
    height: 56px;
    left: 0;
    top: 0;
    z-index: 2;
  }
}
header.header-simple .logo-container .logo {
  display: block;
  height: 56px;
  width: 247px;
  margin-top: 12px;
  /* background: @logo-img no-repeat center center;*/
}
@media (max-width:767px) {
  header.header-simple .logo-container .logo {
    display: block;
    width: 200px;
    height: 27px;
    margin: 15px auto !important;
  }
}
header.header-simple .navigation-container {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  background-color: #fff;
  position: absolute;
  top: 56px;
  color: #fff;
}
@media (min-width:768px) {
  header.header-simple .navigation-container {
    height: 0;
    left: 0;
    width: 100%;
  }
}
@media (max-width:767px) {
  header.header-simple .navigation-container {
    z-index: 1;
    box-shadow: 0 1px 5px 0 rgba(223, 223, 223, 0.44);
    background-color: #5b5b5b;
  }
}
header.header-simple .navigation-container .container {
  position: relative;
  /**
        BLOCK MAIN-MENU
      */
}
header.header-simple .navigation-container .container #main-menu .menu .menu-item {
  float: left;
}
@media (min-width:768px) {
  header.header-simple .navigation-container .container #main-menu .menu .menu-item {
    margin-right: 30px;
  }
}
header.header-simple .navigation-container .container #main-menu .menu-button {
  display: table;
  height: 43px;
  width: 100%;
  padding-top: 4px;
}
@media (min-width:768px) {
  header.header-simple .navigation-container .container #main-menu .menu-button[aria-expanded="true"],
  header.header-simple .navigation-container .container #main-menu .menu-button:hover {
    border-bottom: 3px solid #28a3b2;
    height: 46px;
  }
  header.header-simple .navigation-container .container #main-menu .menu-button[aria-expanded="true"] .menu-caret,
  header.header-simple .navigation-container .container #main-menu .menu-button:hover .menu-caret {
    background-position: -80px 0;
    width: 10px;
    height: 9px;
  }
}
header.header-simple .navigation-container .container #main-menu .menu-button a {
  display: table-cell;
  vertical-align: middle;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: #474747;
  color: #fff;
}
header.header-simple .navigation-container .container #main-menu .menu-button a .menu-caret {
  margin-left: 5px;
  display: inline-block;
}
@media (min-width:768px) {
  header.header-simple .navigation-container .container #main-menu .menu-button a .menu-caret {
    background-image: url('/images/habitatprestopro/sprite-navigation.png');
    background-repeat: no-repeat;
    background-position: -95px 0;
    width: 10px;
    height: 9px;
  }
}
header.header-simple .navigation-container .container #main-menu .dropdown-menu {
  background-color: #f0f0f0;
  border-radius: 0 0 3px 3px;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.4) inset;
  padding-bottom: 0;
  top: 40px;
  width: 240px;
}
@media (min-width:768px) {
  header.header-simple .navigation-container .container #main-menu .dropdown-menu {
    border-top: 3px solid #28a3b2;
  }
}
@media (max-width:767px) {
  header.header-simple .navigation-container .container #main-menu .dropdown-menu {
    display: none;
  }
}
header.header-simple .navigation-container .container #main-menu .dropdown-menu li {
  padding: 5px 0;
  color: #474747 !important;
}
header.header-simple .navigation-container .container #main-menu .dropdown-menu li a {
  color: #474747 !important;
}
header.header-simple .navigation-container .container #main-menu .dropdown-menu li .dropdown-item {
  padding: 3px 20px 3px 10px;
}
header.header-simple .navigation-container .container #main-menu .dropdown-menu li .dropdown-item .icon {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}
header.header-simple .navigation-container .container #main-menu .dropdown-menu li .dropdown-item .casque {
  background-image: url('/images/habitatprestopro/sprites.png');
  background-repeat: no-repeat;
  background-position: -26px -255px;
  width: 23px;
  height: 19px;
}
header.header-simple .navigation-container .container #main-menu .dropdown-menu li .dropdown-item .habitat {
  background-image: url('/images/habitatprestopro/sprites.png');
  background-repeat: no-repeat;
  background-position: -128px -255px;
  width: 19px;
  height: 23px;
}
header.header-simple .navigation-container .container #main-menu .dropdown-menu li .dropdown-item .marteau {
  background-image: url('/images/habitatprestopro/sprites.png');
  background-repeat: no-repeat;
  background-position: -56px -256px;
  width: 19px;
  height: 25px;
}
header.header-simple .navigation-container .container #main-menu .dropdown-menu li .dropdown-item .micro {
  background-image: url('/images/habitatprestopro/sprites.png');
  background-repeat: no-repeat;
  background-position: -171px -253px;
  width: 14px;
  height: 24px;
}
header.header-simple .navigation-container .container #main-menu .dropdown-menu li .dropdown-item:hover {
  background-color: white;
}
header.header-simple .navigation-container .container #main-menu .dropdown-menu li .dropdown-item:hover .casque {
  background-position: 0 -254px;
  width: 23px;
  height: 19px;
}
header.header-simple .navigation-container .container #main-menu .dropdown-menu li .dropdown-item:hover .habitat {
  background-position: -105px -255px;
  width: 19px;
  height: 23px;
}
header.header-simple .navigation-container .container #main-menu .dropdown-menu li .dropdown-item:hover .marteau {
  background-position: -78px -256px;
  width: 19px;
  height: 25px;
}
header.header-simple .navigation-container .container #main-menu .dropdown-menu li .dropdown-item:hover .micro {
  background-position: -153px -253px;
  width: 14px;
  height: 24px;
}
header.header-simple .navigation-container .container #main-menu .dropdown-menu li:hover {
  background: white;
}
header.header-simple .navigation-container .container .quickaccess {
  float: left;
}
@media (min-width:768px) {
  header.header-simple .navigation-container .container .quickaccess {
    position: absolute;
    right: 0;
    top: -56px;
    z-index: 2;
  }
}
header.header-simple .navigation-container .container .quickaccess .list {
  float: right;
}
@media (min-width:768px) {
  header.header-simple .navigation-container .container .quickaccess .list {
    height: 56px;
    margin-left: -20px;
  }
}
header.header-simple .navigation-container .container .quickaccess .list .menu-item {
  display: table;
  height: 100%;
  float: left;
}
@media (min-width:768px) {
  header.header-simple .navigation-container .container .quickaccess .list .menu-item {
    margin-left: 20px;
  }
}
header.header-simple .navigation-container .container .quickaccess .list .menu-item .menu-button {
  display: table-cell;
  vertical-align: middle;
}
header.header-simple .navigation-container .container .quickaccess .list .menu-item .menu-button #register {
  color: #ff804b;
  border: 1px solid #ff804b;
  font-weight: bold;
  background-color: transparent;
  transition: 0.3s;
  border-radius: 0.8rem 0.8rem 0.8rem 0.8rem;
  padding: 10px 20px;
  display: inline-block;
  margin-bottom: 20px;
  text-align: center;
  display: block !important;
  margin-bottom: 0 !important;
  background-color: white;
}
header.header-simple .navigation-container .container .quickaccess .list .menu-item .menu-button #register:hover {
  color: white;
  border: 1px solid #ff804b;
  font-weight: bold;
  background-color: #ff804b;
  transition: 0.3s;
  border-radius: 0.8rem 0.8rem 0.8rem 0.8rem;
}
header.header-simple .navigation-container .container .quickaccess .list .menu-item .menu-button #register:hover {
  background-color: #e77544;
}
header.header-simple .navigation-container .container .quickaccess .list .menu-item .menu-button #is_member {
  color: white;
  border: 1px solid #ff804b;
  font-weight: bold;
  background-color: #ff804b;
  transition: 0.3s;
  border-radius: 0.8rem 0.8rem 0.8rem 0.8rem;
  padding: 10px 20px;
  text-align: center;
}
header.header-simple .navigation-container .container .quickaccess .list .menu-item .menu-button #is_member:hover {
  color: white;
  border: 1px solid #e77544;
  font-weight: bold;
  background-color: #e77544;
  transition: 0.3s;
  border-radius: 0.8rem 0.8rem 0.8rem 0.8rem;
}
header.header-simple .navigation-container .container .quickaccess .list .menu-item .menu-button #is_member * {
  display: inline-block;
  vertical-align: middle;
}
@media (min-width:768px) {
  header.header-simple .navigation-container .container .quickaccess .list .menu-item .menu-button #is_member .icon {
    background-image: url('/images/habitatprestopro/sprites.png');
    background-repeat: no-repeat;
    background-position: -235px -65px;
    width: 21px;
    height: 19px;
    margin-right: 10px;
  }
}
.menu-item-fill {
  width: 100%;
  padding: 0 15px;
}
.menu-item-fill:not(.button-item) {
  border-bottom: 2px solid #5b5b5b;
}
.menu-item-fill:not(.button-item):hover {
  /*background-color: @grey-4;*/
}
.menu-item-fill.button-item {
  margin-top: 10px;
}
@media (max-width:767px) {
  header #menu-open {
    position: absolute;
    height: 100%;
  }
  header #menu-open .menu-icon {
    background-image: url('/images/habitatprestopro/sprite-navigation.png');
    background-repeat: no-repeat;
    background-position: -185px 0;
    width: 25px;
    height: 19px;
    position: absolute;
    top: 19px;
    left: 16px;
  }
  header .logo-container .logo {
    margin: 0 auto;
    background-position: center center;
  }
  header .navigation-container {
    display: none;
  }
  header .navigation-container.opened {
    display: block;
  }
  header .navigation-container .container {
    padding: 0;
  }
  header .navigation-container .container #main-menu .menu .menu-item {
    width: 100%;
    padding: 0 15px;
  }
  header .navigation-container .container #main-menu .menu .menu-item:not(.button-item) {
    border-bottom: 2px solid #5b5b5b;
  }
  header .navigation-container .container #main-menu .menu .menu-item:not(.button-item):hover {
    /*background-color: @grey-4;*/
  }
  header .navigation-container .container #main-menu .menu .menu-item.button-item {
    margin-top: 10px;
  }
  header .navigation-container .container #main-menu .menu .menu-item:not(.button-item) {
    border-bottom: 2px solid #FFFFFF;
  }
  header .navigation-container .container #main-menu .menu .menu-item.button-item {
    margin-top: 10px;
  }
  header .navigation-container .container .quickaccess {
    /*padding-bottom: 20px;*/
  }
  header .navigation-container .container .quickaccess .list {
    height: auto;
  }
  header .navigation-container .container .quickaccess .list .menu-item {
    width: 100%;
    padding: 0 15px;
  }
  header .navigation-container .container .quickaccess .list .menu-item:not(.button-item) {
    border-bottom: 2px solid #5b5b5b;
  }
  header .navigation-container .container .quickaccess .list .menu-item:not(.button-item):hover {
    /*background-color: @grey-4;*/
  }
  header .navigation-container .container .quickaccess .list .menu-item.button-item {
    margin-top: 10px;
  }
  header .navigation-container .container .quickaccess .list .menu-item:not(.button-item) {
    border-bottom: 2px solid #FFFFFF;
  }
  header .navigation-container .container .quickaccess .list .menu-item.button-item {
    margin-top: 10px;
  }
  header .navigation-container .container .quickaccess .list .menu-item .menu-button {
    height: 46px;
  }
  header .navigation-container .container .quickaccess .list .menu-item .menu-button #register {
    width: 100%;
    display: block;
  }
  header .navigation-container .container .quickaccess .list .menu-item .menu-button #is_member {
    width: 100%;
    display: block;
  }
  header .navigation-container .container .quickaccess .list .menu-item .menu-button #is_consumer {
    color: #fff;
    text-transform: uppercase;
  }
}
footer {
  background-color: #082b37;
  background-image: url('/images/habitatprestopro/bg-footer.jpg');
  background-size: contain;
  padding: 40px 0 30px;
}
footer .strong_text,
footer .menu .column .heading,
footer .contact .phone,
footer .contact .newsletter-form .text .heading,
footer .community-container .text {
  font-weight: bold;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.8rem;
  line-height: 2.2rem;
  color: #474747;
  color: white;
}
footer .normal_text,
footer .menu .column .item a,
footer .contact .newsletter-form .text .subheading {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: #474747;
  color: white;
}
@media (max-width:767px) {
  footer .menu .object-row-container {
    display: table;
    width: 100%;
  }
  footer .menu .object-row-separator {
    height: 20px;
  }
}
footer .menu .column .heading {
  padding: 2px 0;
}
footer .menu .column .item {
  padding: 5px 0;
  display: table-cell;
  vertical-align: middle;
}
footer .menu .column .item a {
  display: block;
}
footer .menu .column .item a:hover {
  text-decoration: underline;
}
@media (min-width:768px) {
  footer .contact {
    padding: 0;
  }
}
@media (max-width:767px) {
  footer .contact {
    margin-top: 20px;
    padding: 0 30px;
  }
}
footer .contact .phone .number {
  color: #05d8c6;
}
footer .contact .newsletter-form .text {
  margin-top: 20px;
}
footer .contact .newsletter-form .text .subheading {
  font-weight: normal;
}
footer .contact .newsletter-form .line {
  margin-top: 20px;
  display: table;
  width: 100%;
}
footer .contact .newsletter-form .line .email-container {
  padding: 0;
  display: table-cell;
  vertical-align: top;
}
footer .contact .newsletter-form .line .email-container input[type=text] {
  height: 40px;
}
footer .contact .newsletter-form .line .submit-container {
  padding: 0;
  display: table-cell;
  vertical-align: top;
}
footer .contact .newsletter-form .line .submit-container input[type=submit] {
  color: white;
  border: 1px solid #ff804b;
  font-weight: bold;
  background-color: #ff804b;
  transition: 0.3s;
  border-radius: 0.8rem 0.8rem 0.8rem 0.8rem;
  padding: 10px 20px;
  border-radius: 0 3px 3px 0;
  margin-left: -5px;
  padding: 10px;
  height: 40px;
}
footer .contact .newsletter-form .line .submit-container input[type=submit]:hover {
  color: white;
  border: 1px solid #e77544;
  font-weight: bold;
  background-color: #e77544;
  transition: 0.3s;
  border-radius: 0.8rem 0.8rem 0.8rem 0.8rem;
}
footer .contact .newsletter-form .line .submit-container input[type=submit]:hover {
  border-radius: 0 3px 3px 0;
}
footer .contact .newsletter-form .mention-news {
  font-size: 1.2rem;
  line-height: 1.4rem;
  margin-top: 10px;
  color: #FFFFFF;
  text-align: justify;
}
footer .community-container {
  margin-top: 40px;
}
@media (max-width:767px) {
  footer .community-container {
    margin-top: 20px;
  }
}
footer .community-container .text {
  text-align: center;
  margin-bottom: 20px;
}
@media (max-width:767px) {
  footer .padd-community {
    padding-left: 0;
    padding-right: 0;
  }
}
footer .corporation .positioner {
  display: table;
  margin: 0 auto;
}
footer .corporation .positioner .line {
  color: white;
  float: left;
}
@media (max-width:767px) {
  footer .corporation .positioner .line {
    width: 100%;
    text-align: center;
  }
}
footer .corporation .positioner .line .line-part {
  display: inline-block;
  text-align: center;
  margin: 3px 0 0;
}
@media (min-width:768px) {
  footer .corporation .positioner .line .line-part {
    float: left;
    margin: 0 0 0 3px;
  }
}
footer .corporation .positioner .line .logo {
  background-image: url('/images/habitatprestopro/sprites.png');
  background-repeat: no-repeat;
  display: inline-block;
  vertical-align: middle;
}
footer .mt-30 {
  margin-top: 30px;
}
footer .mt-20 {
  margin-top: 20px;
}
footer hr {
  border-bottom: 1px solid white;
}
footer .sm-hidden {
  display: none;
}
@media (max-width:767px) {
  footer .sm-hidden {
    display: block;
  }
}
footer .mt-10 {
  margin-top: 10px;
}
footer .form-control {
  height: 40px;
}
footer .siteplan {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
footer .siteplan-item:nth-child(n+2):before {
  content: "\2022";
  display: inline-block;
  margin: 0 20px;
}
footer .siteplan-item {
  color: white;
  margin-bottom: 10px;
  line-height: 1;
}
footer .siteplan-item a {
  color: white;
}
footer .siteplan-item a:hover {
  text-decoration: underline;
}
/**
  HEADER
*/
header {
  position: sticky;
  top: 0;
  z-index: 1030;
}
@media (min-width:768px) {
  header {
    height: 102px;
  }
}
header .sprite-fleche {
  background-image: url('/images/habitatprestopro/sprites.png');
  background-repeat: no-repeat;
  background-position: -106px -240px;
  width: 5px;
  height: 9px;
  display: inline-block;
}
header .logo-container {
  border-bottom: 1px solid #ebebeb;
  width: 100%;
  height: 56px;
  background-color: #FFFFFF;
}
@media (min-width:768px) {
  header .logo-container {
    position: absolute;
    height: 56px;
    left: 0;
    top: 0;
    z-index: 2;
  }
}
header .logo-container .logo {
  display: block;
  height: 56px;
  width: 247px;
  margin-top: 12px;
}
@media (max-width:767px) {
  header .logo-container .logo {
    display: block;
    width: 200px;
    height: 27px;
    margin: 15px auto !important;
  }
}
header .navigation-container {
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.44);
  position: absolute;
  top: 56px;
  background-color: #5b5b5b;
  color: #FFFFFF;
}
@media (min-width:768px) {
  header .navigation-container {
    height: 46px;
    left: 0;
    width: 100%;
  }
}
@media (max-width:767px) {
  header .navigation-container {
    z-index: 1;
  }
}
header .navigation-container .container {
  position: relative;
  /*****BLOCK MAIN-MENU****/
}
header .navigation-container .container #main-menu .menu .nav-item {
  float: left;
}
@media (min-width:768px) {
  header .navigation-container .container #main-menu .menu .nav-item {
    margin-right: 30px;
  }
}
header .navigation-container .container #main-menu .menu-button {
  display: table;
  height: 43px;
  width: 100%;
  padding-top: 4px;
}
@media (min-width:768px) {
  header .navigation-container .container #main-menu .menu-button[aria-expanded=true],
  header .navigation-container .container #main-menu .menu-button:hover {
    border-bottom: 3px solid #28a3b2;
    height: 46px;
  }
  header .navigation-container .container #main-menu .menu-button[aria-expanded=true] .menu-caret,
  header .navigation-container .container #main-menu .menu-button:hover .menu-caret {
    background-position: -80px 0;
    width: 10px;
    height: 9px;
  }
}
header .navigation-container .container #main-menu .menu-button a {
  display: table-cell;
  vertical-align: middle;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: #474747;
  color: #FFFFFF;
}
header .navigation-container .container #main-menu .menu-button a:hover {
  text-decoration: none;
}
@media (max-width:991px) {
  header .navigation-container .container #main-menu .menu-button a {
    font-size: 1.35rem;
  }
}
header .navigation-container .container #main-menu .menu-button a .menu-caret {
  margin-left: 5px;
  display: inline-block;
}
@media (min-width:768px) {
  header .navigation-container .container #main-menu .menu-button a .menu-caret {
    background-image: url('/images/habitatprestopro/sprite-navigation.png');
    background-repeat: no-repeat;
    background-position: -95px 0;
    width: 10px;
    height: 9px;
  }
}
header .navigation-container .container #main-menu .dropdown {
  height: 43px;
}
@media (min-width:768px) {
  header .navigation-container .container #main-menu .dropdown[aria-expanded=true],
  header .navigation-container .container #main-menu .dropdown:hover {
    border-bottom: 3px solid #28a3b2;
    height: 46px;
  }
  header .navigation-container .container #main-menu .dropdown[aria-expanded=true] .menu-caret,
  header .navigation-container .container #main-menu .dropdown:hover .menu-caret {
    background-position: -80px 0;
    width: 10px;
    height: 9px;
  }
}
header .navigation-container .container #main-menu .dropdown .dropdown-toggle {
  display: table-cell;
  vertical-align: middle;
  color: #FFFFFF;
}
header .navigation-container .container #main-menu .dropdown a {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: #474747;
  display: block;
}
header .navigation-container .container #main-menu .dropdown a:hover {
  text-decoration: none;
}
@media (max-width:991px) {
  header .navigation-container .container #main-menu .dropdown a {
    font-size: 1.35rem;
  }
}
header .navigation-container .container #main-menu .dropdown a .menu-caret {
  margin-left: 5px;
  display: inline-block;
}
@media (min-width:768px) {
  header .navigation-container .container #main-menu .dropdown a .menu-caret {
    background-image: url('/images/habitatprestopro/sprite-navigation.png');
    background-repeat: no-repeat;
    background-position: -95px 0;
    width: 10px;
    height: 9px;
  }
}
header .navigation-container .container #main-menu .dropdown-menu {
  background-color: #f0f0f0;
  border-radius: 0 0 3px 3px;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.4) inset;
  padding-bottom: 0;
  width: 240px;
}
@media (min-width:768px) {
  header .navigation-container .container #main-menu .dropdown-menu {
    border-top: 3px solid #28a3b2;
  }
}
@media (max-width:767px) {
  header .navigation-container .container #main-menu .dropdown-menu {
    display: none;
  }
}
header .navigation-container .container #main-menu .dropdown-menu .dropdown-item {
  padding: 3px 20px 3px 10px;
}
header .navigation-container .container #main-menu .dropdown-menu .dropdown-item:hover {
  background-color: #FFFFFF;
}
header .navigation-container .container .quickaccess {
  float: left;
}
@media (min-width:768px) {
  header .navigation-container .container .quickaccess {
    position: absolute;
    right: 0;
    top: -56px;
    z-index: 2;
  }
}
header .navigation-container .container .quickaccess a:hover {
  text-decoration: none;
}
header .navigation-container .container .quickaccess .login-contact {
  float: right;
  margin-top: 20px;
}
@media screen and (max-width:767px) {
  header .navigation-container .container .quickaccess .login-contact {
    display: none;
  }
}
header .navigation-container .container .quickaccess .login-contact .user-icon {
  background: url('/theme/lisboa/membre/picto-planche-sprite.png') no-repeat;
  display: inline-block;
  width: 35px;
  height: 25px;
  float: left;
  margin-top: -8px;
  background-position: -185px -154px;
}
header .navigation-container .container .quickaccess .login-contact .user {
  color: #474747;
}
header .navigation-container .container .quickaccess .list {
  float: right;
}
@media (min-width:768px) {
  header .navigation-container .container .quickaccess .list {
    height: 56px;
    margin-left: -20px;
  }
}
header .navigation-container .container .quickaccess .list .menu-item {
  display: table;
  height: 100%;
  float: left;
}
@media (min-width:768px) {
  header .navigation-container .container .quickaccess .list .menu-item {
    margin-left: 20px;
  }
}
header .navigation-container .container .quickaccess .list .menu-item .menu-button {
  display: table-cell;
  vertical-align: middle;
}
header .navigation-container .container .quickaccess .list .menu-item .menu-button #register {
  color: #ff804b;
  border: 1px solid #ff804b;
  font-weight: bold;
  background-color: transparent;
  transition: 0.3s;
  border-radius: 0.8rem 0.8rem 0.8rem 0.8rem;
  padding: 10px 20px;
  display: inline-block;
  margin-bottom: 20px;
  text-align: center;
  display: block !important;
  margin-bottom: 0 !important;
  background-color: #FFFFFF;
}
header .navigation-container .container .quickaccess .list .menu-item .menu-button #register:hover {
  color: white;
  border: 1px solid #ff804b;
  font-weight: bold;
  background-color: #ff804b;
  transition: 0.3s;
  border-radius: 0.8rem 0.8rem 0.8rem 0.8rem;
}
header .navigation-container .container .quickaccess .list .menu-item .menu-button #register:hover {
  background-color: #e77544;
}
header .navigation-container .container .quickaccess .list .menu-item .menu-button #is_member {
  color: white;
  border: 1px solid #ff804b;
  font-weight: bold;
  background-color: #ff804b;
  transition: 0.3s;
  border-radius: 0.8rem 0.8rem 0.8rem 0.8rem;
  padding: 10px 20px;
  text-align: center;
}
header .navigation-container .container .quickaccess .list .menu-item .menu-button #is_member:hover {
  color: white;
  border: 1px solid #e77544;
  font-weight: bold;
  background-color: #e77544;
  transition: 0.3s;
  border-radius: 0.8rem 0.8rem 0.8rem 0.8rem;
}
header .navigation-container .container .quickaccess .list .menu-item .menu-button #is_member * {
  display: inline-block;
  vertical-align: middle;
}
@media (min-width:768px) {
  header .navigation-container .container .quickaccess .list .menu-item .menu-button #is_member .icon {
    background-image: url('/images/habitatprestopro/sprites.png');
    background-repeat: no-repeat;
    background-position: -235px -65px;
    width: 21px;
    height: 19px;
    margin-right: 10px;
  }
}
header.pro-space {
  height: 60px;
}
header.pro-space .logo-container {
  height: 60px;
}
header.pro-space .logo-container .logo {
  float: left;
  width: auto;
}
@media screen and (max-width:767px) {
  header.pro-space .logo-container .logo img {
    width: 75%;
  }
}
header.pro-space .logo-container .ekomi-widget-container {
  float: left;
  margin-right: 15px;
  margin-top: -15px;
}
header.pro-space .logo-container .login-contact {
  float: right;
  margin-top: 20px;
}
header.pro-space .logo-container .login-contact .separator {
  border-left: 1px solid #e8e5e5;
  border-right: 1px solid #e8e5e5;
  height: 30px;
  margin: 0 15px;
  display: inline-block;
  float: left;
}
@media screen and (max-width:991px) {
  header.pro-space .logo-container .login-contact .separator {
    display: none;
  }
}
header.pro-space .logo-container .login-contact .user-icon {
  background: url('/theme/lisboa/membre/picto-planche-sprite.png') no-repeat;
  display: inline-block;
  width: 35px;
  height: 25px;
  float: left;
  background-position: -185px -154px;
}
@media screen and (max-width:991px) {
  header.pro-space .logo-container .login-contact .user-icon {
    display: none;
  }
}
header.pro-space .logo-container .login-contact .user {
  display: inline-block;
  float: left;
  margin-top: 8px;
}
@media screen and (max-width:991px) {
  header.pro-space .logo-container .login-contact .user {
    display: none;
  }
}
header.pro-space .logo-container .login-contact .log-out {
  background: url('/theme/lisboa/membre/picto-planche-sprite.png');
  display: inline-block;
  width: 30px;
  height: 25px;
  float: left;
  background-position: -184px 96px;
}
@media screen and (max-width:991px) {
  header.pro-space .logo-container .login-contact .log-out {
    display: none;
  }
}
@media screen and (min-width:992px) {
  header.pro-space .logo-container .login-contact #menu-open {
    display: none;
  }
}
@media screen and (max-width:991px) {
  header.pro-space .logo-container .login-contact #menu-open {
    float: right;
    position: fixed;
    top: 20px;
    height: 20px;
    margin-left: -45px;
  }
  header.pro-space .logo-container .login-contact #menu-open .menu-icon {
    background-image: url('/images/habitatprestopro/sprite-navigation.png');
    background-repeat: no-repeat;
    background-position: -185px 0;
    width: 25px;
    height: 19px;
  }
}
@media screen and (max-width:767px) {
  header.pro-space .logo-container .login-contact #menu-open {
    top: 0;
  }
}
.menu-item-fill {
  width: 100%;
  padding: 0 15px;
}
.menu-item-fill:not(.button-item) {
  border-bottom: 2px solid #FFFFFF;
}
.menu-item-fill.button-item {
  margin-top: 10px;
}
#center_bandeau_cookie {
  width: 100%;
  left: 0;
  background: none;
}
#center_bandeau_cookie #bandeau_cookie {
  min-width: 100px;
  background: #cdcdcd;
  margin: auto;
  position: absolute;
  z-index: 3;
  width: 100%;
}
#center_bandeau_cookie #bandeau_cookie p {
  font-size: 10px;
  color: #333333;
  text-align: center;
  padding: 5px 20px;
  line-height: 22px;
  min-width: 200px;
}
#center_bandeau_cookie #bandeau_cookie #closure {
  position: absolute;
  display: block;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background: #FFFFFF url('/images/ico_close.png') no-repeat 5px 5px;
  border: none;
  text-indent: -99px;
  cursor: pointer;
  bottom: 7px;
  right: 3px;
  margin-top: -10px;
}
a.lien-cookie-doc {
  font-weight: bold;
  color: #333333;
}
a.lien-cookie-doc:hover {
  text-decoration: underline;
}
@media (max-width:767px) {
  header #menu-open {
    position: absolute;
    height: 100%;
  }
  header #menu-open .menu-icon {
    background-image: url('/images/habitatprestopro/sprite-navigation.png');
    background-repeat: no-repeat;
    background-position: -185px 0;
    width: 25px;
    height: 19px;
    position: absolute;
    top: 19px;
    left: 16px;
  }
  header .logo-container {
    background-color: #FFFFFF;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 2;
  }
  header .logo-container.show_header {
    position: static;
  }
  header .logo-container .logo {
    margin: 0 auto;
    background-position: center center;
  }
  header #main-menu .navbar {
    margin-bottom: 0;
  }
  header #main-menu .navbar .navbar-nav {
    width: 100%;
    margin: 0;
  }
  header #main-menu .navbar .navbar-nav li {
    border-bottom: 2px solid #FFFFFF;
    padding: 0 15px;
  }
  header #main-menu .navbar .navbar-nav li.nav-item {
    width: 100%;
    float: none;
  }
  header #main-menu .navbar .navbar-nav li.nav-item.dropdown {
    display: block;
    height: 50px;
  }
  header #main-menu .navbar .navbar-nav li.nav-item.dropdown .dropdown-toggle {
    line-height: 20px;
    padding: 15px 0;
  }
  header #main-menu .navbar .navbar-nav li.nav-item.dropdown .dropdown-toggle img {
    margin-left: 5px;
  }
  header #main-menu .navbar .navbar-nav li.nav-item.dropdown .dropdown-toggle:after {
    display: none;
  }
  header #main-menu .navbar .navbar-nav li.nav-item.dropdown .dropdown-menu {
    box-shadow: none;
    border: none;
    width: 100%;
  }
  header #main-menu .navbar .navbar-nav li.nav-item.dropdown .dropdown-menu .dropdown-item {
    padding: 15px 0 15px 15px;
    font-size: 1.3rem;
  }
  header #main-menu .navbar .navbar-nav li.nav-item.dropdown .dropdown-menu .dropdown-item:hover {
    color: #ff804b;
    background-color: #f0f0f0;
  }
  header #main-menu .navbar .navbar-nav li.nav-item.dropdown.open .dropdown-menu {
    display: block;
  }
  header #main-menu .navbar .navbar-nav li .menu-button a {
    padding: 15px 0;
  }
  header .navigation-container {
    display: none;
  }
  header .navigation-container.opened {
    display: block;
    position: fixed;
    top: 56px;
    width: 100%;
    z-index: 2;
  }
  header .navigation-container.opened.fixed-mobile-menu {
    z-index: auto;
    position: inherit;
  }
  header .navigation-container .container {
    padding: 0;
  }
  header .navigation-container .container #main-menu .menu .menu-item {
    width: 100%;
    padding: 0 15px;
  }
  header .navigation-container .container #main-menu .menu .menu-item:not(.button-item) {
    border-bottom: 2px solid #5b5b5b;
  }
  header .navigation-container .container #main-menu .menu .menu-item:not(.button-item):hover {
    /*background-color: @grey-4;*/
  }
  header .navigation-container .container #main-menu .menu .menu-item.button-item {
    margin-top: 10px;
  }
  header .navigation-container .container #main-menu .menu .menu-item:not(.button-item) {
    border-bottom: 2px solid #FFFFFF;
  }
  header .navigation-container .container #main-menu .menu .menu-item.button-item {
    margin-top: 10px;
  }
  header .navigation-container .container .quickaccess .list {
    height: auto;
  }
  header .navigation-container .container .quickaccess .list .menu-item {
    width: 100%;
    padding: 0 15px;
  }
  header .navigation-container .container .quickaccess .list .menu-item:not(.button-item) {
    border-bottom: 2px solid #5b5b5b;
  }
  header .navigation-container .container .quickaccess .list .menu-item:not(.button-item):hover {
    /*background-color: @grey-4;*/
  }
  header .navigation-container .container .quickaccess .list .menu-item.button-item {
    margin-top: 10px;
  }
  header .navigation-container .container .quickaccess .list .menu-item:not(.button-item) {
    border-bottom: 2px solid #FFFFFF;
  }
  header .navigation-container .container .quickaccess .list .menu-item.button-item {
    margin-top: 10px;
  }
  header .navigation-container .container .quickaccess .list .menu-item .menu-button {
    height: 45px;
  }
}
.image-wrapper {
  display: table;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}
.image-wrapper .wrapped-content {
  display: table-cell;
  vertical-align: middle;
}
.image-wrapper {
  /*
    CONTENT HEADER FORM
  */
  /*
    CONTENT HEADER TEXT
  */
  /*
    CONTENT HEADER FORM SEARCH
  */
  /*
    CONTENT HEADER GUIDING
  */
}
.image-wrapper.size-1 {
  height: 500px;
}
.image-wrapper.size-2 {
  height: 150px;
}
.image-wrapper h1 {
  font-size: 4.8rem;
  line-height: 4.8rem;
  color: white;
  text-align: center;
}
.image-wrapper.form h1 {
  margin-bottom: 60px;
}
.image-wrapper.form h2 {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.8rem;
  line-height: 2.2rem;
  color: white;
  font-weight: bold;
  text-align: center;
  margin-bottom: 40px;
}
.image-wrapper.form form {
  background-color: rgba(7, 26, 33, 0.8);
  padding: 10px;
  width: 100%;
  height: 100%;
  display: table;
}
@media (min-width:768px) {
  .image-wrapper.form form > div {
    display: table-cell;
    vertical-align: middle;
    height: 100%;
    float: none;
  }
}
.image-wrapper.form form #form-select-container {
  padding-left: 0;
  padding-right: 10px;
}
@media (max-width:767px) {
  .image-wrapper.form form #form-select-container {
    padding-right: 0;
  }
}
.image-wrapper.form form #form-select-container select {
  font-size: 1.8rem;
  height: 44px;
  margin-top: 15px;
}
.image-wrapper.form form #form-select-container input {
  font-size: 1.8rem;
  height: 45px;
}
.image-wrapper.form form #form-select-container optgroup {
  font-weight: bold;
}
@media (max-width:767px) {
  .image-wrapper.form form #form-select-container .form-group {
    margin-bottom: 0;
  }
}
.image-wrapper.form form #form-submit-container {
  padding: 0;
  margin-top: 10px;
}
@media (min-width:768px) {
  .image-wrapper.form form #form-submit-container {
    margin: 0;
    padding: 0;
  }
}
.image-wrapper.form form #form-submit-container button {
  font-size: 1.8rem;
  color: white;
  border: 1px solid #ff804b;
  font-weight: bold;
  background-color: #ff804b;
  transition: 0.3s;
  border-radius: 0.8rem 0.8rem 0.8rem 0.8rem;
  padding: 10px 20px;
  width: 100%;
  min-height: 45px;
}
.image-wrapper.form form #form-submit-container button:hover {
  color: white;
  border: 1px solid #e77544;
  font-weight: bold;
  background-color: #e77544;
  transition: 0.3s;
  border-radius: 0.8rem 0.8rem 0.8rem 0.8rem;
}
@media (max-width:767px) {
  .image-wrapper.form form #form-submit-container button {
    margin-bottom: 10px;
  }
}
.image-wrapper.text h1 {
  font-weight: bold;
}
.image-wrapper.text h2 {
  margin-top: 10px;
  font-size: 3.6rem;
  line-height: 3.6rem;
  color: #474747;
  color: white;
  text-align: center;
}
.image-wrapper .sprite-search {
  background-position: -254px -219px;
  width: 25px;
  height: 24px;
  background-image: url('/images/habitatprestopro/sprites.png');
  background-repeat: no-repeat;
}
.legal {
  line-height: 20px;
  margin-bottom: 30px;
}
.legal .contact {
  color: #ff804b;
  font-weight: bold;
}
.section-title {
  margin: 30px 0;
}
.section-title h2 {
  font-size: 3.6rem;
  line-height: 3.6rem;
  text-transform: uppercase;
  color: #474747;
  text-align: center;
}
.section-title .subtitle {
  margin-top: 10px;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: #474747;
  text-align: center;
}
@media (max-width:767px) {
  .section-title .subtitle {
    display: none;
  }
}
.community.list {
  width: 100%;
  display: table;
  text-align: center;
}
.community.list .positioner {
  display: table-cell;
}
.community.list .positioner .block,
.community.list .positioner .facebook,
.community.list .positioner .twitter,
.community.list .positioner .youtube,
.community.list .positioner .google,
.community.list .positioner .pinterest,
#actualites .community .facebook,
#actualites .community .twitter,
#actualites-rubrique .community .facebook,
#actualites-rubrique .community .twitter,
#actualites-ss-rubrique .community .facebook,
#actualites-ss-rubrique .community .twitter,
#actualites-recherche .community .facebook,
#actualites-recherche .community .twitter,
.community .facebook,
.community .twitter {
  margin: 0 10px;
  display: inline-block;
}
.community.list .positioner .facebook {
  background-image: url('/images/habitatprestopro/sprites.png');
  background-repeat: no-repeat;
  background-position: -323px -115px;
  width: 44px;
  height: 42px;
}
.community.list .positioner .twitter {
  background-image: url('/images/habitatprestopro/sprites.png');
  background-repeat: no-repeat;
  background-position: -370px -115px;
  width: 42px;
  height: 42px;
}
.community.list .positioner .youtube {
  background-image: url('/images/habitatprestopro/sprites.png');
  background-repeat: no-repeat;
  background-position: -415px -115px;
  width: 43px;
  height: 42px;
}
.community.list .positioner .google {
  background-image: url('/images/habitatprestopro/sprites.png');
  background-repeat: no-repeat;
  background-position: -507px -115px;
  width: 43px;
  height: 43px;
}
.community.list .positioner .pinterest {
  background-image: url('/images/habitatprestopro/sprites.png');
  background-repeat: no-repeat;
  background-position: -462px -115px;
  width: 42px;
  height: 42px;
}
.menu-tab {
  margin-top: 20px;
  margin-bottom: 20px;
}
.menu-tab .item {
  padding: 0 5px;
  height: 45px;
  margin-bottom: 10px;
}
@media (min-width:768px) {
  .menu-tab .item {
    height: 40px;
  }
}
.menu-tab .item .positioner {
  display: table;
  width: 100%;
  height: 100%;
}
.menu-tab .item .positioner a {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
@media (min-width:768px) {
  .menu-tab {
    display: table;
  }
  .menu-tab .item {
    display: table-cell;
    float: none;
    width: auto;
  }
}
.menu-tab .item .positioner a {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: #474747;
  background-color: #f0f0f0;
}
.menu-tab .item .positioner a:hover {
  background-color: #eaeaea;
}
.menu-tab .item.selected .positioner a {
  color: white;
  background-color: #28a3b2;
}
.menu-tab.press-partners .item,
.menu-tab.presse-onglet .item {
  width: 50%;
}
.menu-tab.about .item {
  width: 33%;
  float: left;
}
@media (max-width:991px) {
  .menu-tab.about .item {
    width: 50%;
  }
}
#applimobile-actualite {
  padding-bottom: 30px;
}
#applimobile-actualite img {
  max-width: 350px;
  margin: 0 auto;
}
@media (max-width:991px) {
  #applimobile-actualite img {
    width: 100%;
  }
}
@media (max-width:767px) {
  #applimobile-actualite img {
    width: 100%;
    padding: 0 30px;
  }
}
#applimobile-actualite a.not-available-btn {
  border: 1px solid #727272;
  border-radius: 1.4rem;
  padding: 15px;
  color: #727272;
  line-height: 24px;
  width: 200px;
  height: 80px;
  display: block;
  cursor: initial;
  opacity: 0.8;
  margin: 0 auto;
}
@media (max-width:767px) {
  #applimobile-actualite a.not-available-btn {
    float: none;
    margin: auto;
  }
}
#applimobile-actualite a.bt-appli,
#applimobile-actualite a.bt-appli:active,
#applimobile-actualite a.bt-appli:visited {
  border: 1px solid #727272;
  border-radius: 1.4rem;
  padding: 15px;
  color: #727272;
  line-height: 24px;
  width: 190px;
  height: 80px;
  display: block;
}
#applimobile-actualite a.bt-appli:hover {
  border: 1px solid #000;
  background-color: #000;
  color: #FFF;
}
#applimobile-actualite .bt-appli p {
  display: inline-block;
}
@media screen and (max-width: 992px) {
  #applimobile-actualite .bt-appli.android {
    margin-top: 20px;
  }
}
#applimobile-actualite .ico-apple {
  background-image: url(/images/habitatprestopro/apple-1.png);
  background-repeat: no-repeat;
  background-position: center center;
  width: 41px;
  height: 50px;
  display: inline-block;
  margin-right: 15px;
  float: left;
}
#applimobile-actualite a.apple:hover .ico-apple {
  background-image: url(/images/habitatprestopro/apple-2.png);
  background-repeat: no-repeat;
  background-position: center center;
}
#applimobile-actualite .ico-android {
  background-image: url(/images/habitatprestopro/play-1.png);
  background-repeat: no-repeat;
  background-position: center center;
  width: 41px;
  height: 50px;
  display: inline-block;
  margin-right: 10px;
  float: left;
}
#applimobile-actualite a.android:hover .ico-android {
  background-image: url(/images/habitatprestopro/play-2.png);
  background-repeat: no-repeat;
  background-position: center center;
}
#applimobile-actualite .store-appli {
  font-size: 20px;
}
@media (max-width:767px) {
  #applimobile-actualite .mt-100 {
    margin-top: 40px;
  }
}
@media (min-width:768px) and (max-width:991px) {
  #applimobile-actualite .pl-40 {
    padding-left: 40px;
  }
}
#applimobile-actualite .pr-40 {
  padding-right: 40px;
}
@media (max-width:991px) {
  #applimobile-actualite .pr-40 {
    padding-right: 0;
  }
}
/****** PAGES ******/
.faq-container {
  margin: 40px 0;
}
.faq-container .mt-40 {
  margin-top: 40px;
}
.faq-container .mt-20 {
  margin-top: 20px;
}
.faq-container .faq-group {
  margin-top: 30px;
}
.faq-container .faq-group .title {
  text-align: center;
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #474747;
  text-transform: uppercase;
  margin-bottom: 20px;
}
.faq-container .faq-group .panel-group.group1 .panel {
  border-radius: 0;
  background-clip: padding-box;
  position: relative;
  cursor: pointer;
  border-color: #ececec;
}
.faq-container .faq-group .panel-group.group1 .panel .panel-heading[aria-expanded=true] {
  background-color: #ff804b;
  border-color: #ff804b;
}
.faq-container .faq-group .panel-group.group1 .panel .panel-heading[aria-expanded=true] .panel-title {
  color: white;
}
.faq-container .faq-group .panel-group.group1 .panel .panel-heading[aria-expanded=true] .panel-caret {
  background-position: -60px 0;
  width: 14px;
  height: 8px;
  transform: rotate(0deg);
}
.faq-container .faq-group .panel-group.group1 .panel .panel-body {
  line-height: 2.2rem;
}
.faq-container .faq-group .panel-group.group1 .panel .panel-body ul {
  list-style-type: disc;
}
.faq-container .faq-group .panel-group.group1 .panel .panel-body ul li {
  margin-left: 20px;
}
.faq-container .faq-group .panel-group.group1 .panel-caret {
  position: absolute;
  top: 21px;
  right: 20px;
  transition: transform 0.7s ease;
  background-image: url('/images/habitatprestopro/sprite-navigation.png');
  background-repeat: no-repeat;
  background-position: -60px -10px;
  width: 14px;
  height: 8px;
  transform: rotate(180deg);
}
.faq-container .faq-group .panel-group.group1 .panel-heading {
  border-radius: 0;
  background-clip: padding-box;
  background-color: #ececec;
}
.faq-container .faq-group .panel-group.group1 .panel-heading:hover {
  background-color: #e9e6e6;
}
.faq-container .faq-group .panel-group.group1 .panel-heading .panel-title {
  font-size: 2.4rem;
  line-height: 3rem;
  color: #474747;
  padding-right: 30px;
}
.wrapper-blue {
  background-color: #11404d;
}
.faq-contact {
  padding-bottom: 40px;
  padding-top: 40px;
}
.faq-contact .title-contact {
  font-size: 3.6rem;
  line-height: 3.6rem;
  color: #474747;
  color: #FFF;
  font-weight: bold;
  text-align: center;
}
.faq-contact .list-contact {
  margin: 40px auto 0;
}
.faq-contact .ss-title-contact {
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #474747;
  font-weight: bold;
  color: #FFF;
  text-transform: uppercase;
}
.faq-contact .tel-contact {
  font-family: 'Open Sans', sans-serif;
  font-size: 2.4rem;
  line-height: 3rem;
  color: #474747;
  margin: 20px auto 10px;
  font-weight: bold;
  color: #FFF;
}
.faq-contact .block-contact {
  margin-bottom: 20px;
}
.faq-contact .txt-xxs {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.2rem;
  line-height: 1.5rem;
  color: #474747;
  color: #FFF;
}
.faq-contact .txt-contact {
  margin: 20px auto 10px;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.8rem;
  line-height: 2.2rem;
  color: #474747;
  color: #FFF;
}
.faq-contact .ss-txt-contact {
  line-height: 18px;
  color: #FFF;
  margin-top: 20px;
}
.faq-contact .icone-telephone {
  background-image: url('/images/habitatprestopro/sprite-profile.png');
  background-repeat: no-repeat;
  background-position: 0 0;
  width: 31px;
  height: 31px;
  display: inline-block;
  margin-right: 15px;
  vertical-align: middle;
}
.faq-contact .icone-chat {
  background-image: url('/images/habitatprestopro/sprite-profile.png');
  background-repeat: no-repeat;
  background-position: -35px 0;
  width: 38px;
  height: 33px;
  display: inline-block;
  margin-right: 15px;
  vertical-align: middle;
}
.faq-contact .icone-mail {
  background-image: url('/images/habitatprestopro/sprite-profile.png');
  background-repeat: no-repeat;
  background-position: -75px 0;
  width: 37px;
  height: 25px;
  display: inline-block;
  margin-right: 15px;
  vertical-align: middle;
}
.faq-contact .lien-contact {
  margin-top: 20px;
  color: #FFF;
  display: inline-block;
  float: right;
}
.faq-contact .lien-contact:hover {
  text-decoration: underline;
}
.faq-contact .fleche-orange {
  background-image: url('/images/habitatprestopro/sprites.png');
  background-repeat: no-repeat;
  background-position: -104px -226px;
  width: 6px;
  height: 9px;
  display: inline-block;
  margin-left: 5px;
  vertical-align: middle;
}
.contact-1 {
  background-position: 0 -101px;
  width: 106px;
  height: 106px;
  background-image: url('/images/habitatprestopro/sprites.png');
  background-repeat: no-repeat;
}
.contact-2 {
  background-position: -106px -103px;
  width: 107px;
  height: 105px;
  background-image: url('/images/habitatprestopro/sprites.png');
  background-repeat: no-repeat;
}
.contact-3 {
  background-position: -214px -102px;
  width: 105px;
  height: 107px;
  background-image: url('/images/habitatprestopro/sprites.png');
  background-repeat: no-repeat;
}
.button.white2 {
  display: inline-block;
  margin-bottom: 20px;
}
.lexique-contact {
  padding: 20px 0 30px;
  text-align: center;
}
@media (max-width:767px) {
  .lexique-contact .row {
    margin: 0;
  }
}
.lexique-contact .title-lexique {
  display: inline-block;
  margin: 20px auto;
  font-size: 3.6rem;
  line-height: 3.6rem;
  color: #474747;
}
.lexique-contact .texte-lexique {
  display: block;
  margin-bottom: 30px;
  line-height: 18px;
}
@media (max-width:767px) {
  .lexique-contact .padd-community {
    padding-left: 0;
    padding-right: 0;
  }
}
#listchantier .wrapper-form {
  background-color: #11404d;
  color: #FFF;
}
#listchantier .wrapper-form p {
  font-size: 18px;
  margin-top: 10px;
  line-height: 24px;
}
#listchantier .wrapper-form .select2-container .select2-choice > .select2-chosen {
  padding: 10px 0 10px 10px !important;
}
#listchantier .wrapper-form .select2-default {
  color: #727272 !important;
}
#listchantier .wrapper-form .select2-container {
  display: block !important;
  font-size: 1.8rem !important;
}
#listchantier .wrapper-form .select2-container .select2-choice {
  height: 44px !important;
  margin-top: 15px;
  width: 100%;
  background: #fff !important;
  background-image: -moz-linear-gradient(center bottom, #fff 0%, #fff 50%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#ffffff', GradientType=0);
  background-image: linear-gradient(to top, #fff 0%, #fff 50%);
}
#listchantier .wrapper-form .select2-container .select2-choice .select2-arrow {
  background-image: -moz-linear-gradient(center bottom, #fff 0%, #fff 50%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#ffffff', GradientType=0);
  background-image: linear-gradient(to top, #fff 0%, #fff 50%);
  border-left: 1px solid #FFF;
}
#listchantier .wrapper-form .select2-container .select2-choice .select2-arrow b {
  background: url('select2.png') no-repeat 0 10px;
}
@media (max-width:767px) {
  #listchantier .wrapper-form .form-group {
    margin-bottom: 0;
  }
}
@media (max-width:767px) {
  #listchantier .wrapper-form button {
    margin-bottom: 10px;
  }
}
#listchantier .txt-no-chantier {
  font-size: 18px;
}
#listchantier .select2-container .select2-choice abbr {
  top: 15px;
}
#listchantier .wrapper-gris {
  background-color: #f0f0f0;
  width: 100%;
  padding-bottom: 50px;
}
#listchantier .wrapper-gris .button-more,
#listchantier .wrapper-gris .button-more:active,
#listchantier .wrapper-gris .button-more:visited,
#listchantier .wrapper-gris .button-more:focus {
  color: #ff804b;
  border: 1px solid #ff804b;
  font-weight: bold;
  background-color: transparent;
  transition: 0.3s;
  border-radius: 0.8rem 0.8rem 0.8rem 0.8rem;
  padding: 10px 20px;
  display: inline-block;
  margin-bottom: 20px;
  display: block;
  margin: 0 auto;
  outline: none;
}
#listchantier .wrapper-gris .button-more:hover,
#listchantier .wrapper-gris .button-more:active:hover,
#listchantier .wrapper-gris .button-more:visited:hover,
#listchantier .wrapper-gris .button-more:focus:hover {
  color: white;
  border: 1px solid #ff804b;
  font-weight: bold;
  background-color: #ff804b;
  transition: 0.3s;
  border-radius: 0.8rem 0.8rem 0.8rem 0.8rem;
}
#listchantier .button-more {
  font-size: 18px !important;
}
#listchantier form {
  width: 100%;
  height: 100%;
  display: table;
}
@media (min-width:768px) {
  #listchantier form > div {
    display: table-cell;
    vertical-align: middle;
    height: 100%;
    float: none;
  }
}
#listchantier form #form-select-container {
  padding-left: 0;
  padding-right: 10px;
}
@media (max-width:767px) {
  #listchantier form #form-select-container {
    padding-right: 0;
  }
}
#listchantier form #form-select-container select {
  font-size: 1.8rem;
  height: 45px;
  color: #727272;
  margin-top: 15px;
}
#listchantier form #form-select-container input {
  font-size: 1.8rem;
  height: 45px;
}
@media (max-width:767px) {
  #listchantier form #form-select-container input {
    margin-top: 10px;
  }
}
#listchantier form #form-select-container optgroup {
  font-weight: bold;
}
#listchantier form #form-submit-container {
  padding: 0;
  margin-top: 10px;
}
@media (min-width:768px) {
  #listchantier form #form-submit-container {
    margin: 0;
    padding: 0;
  }
}
#listchantier form #form-submit-container button {
  font-size: 1.8rem;
  color: white;
  border: 1px solid #ff804b;
  font-weight: bold;
  background-color: #ff804b;
  transition: 0.3s;
  border-radius: 0.8rem 0.8rem 0.8rem 0.8rem;
  padding: 10px 20px;
  width: 100%;
  min-height: 45px;
}
#listchantier form #form-submit-container button:hover {
  color: white;
  border: 1px solid #e77544;
  font-weight: bold;
  background-color: #e77544;
  transition: 0.3s;
  border-radius: 0.8rem 0.8rem 0.8rem 0.8rem;
}
#listchantier .alert-danger {
  margin-top: 10px;
}
#listchantier .alert {
  margin-bottom: 0;
}
.select2-results .select2-no-results {
  font-size: 12px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  background: #d9edf7 !important;
  color: #3a87ad !important;
}
#quisommesnous .object-row-container .object-cell {
  height: 280px;
}
@media (max-width:767px) {
  #quisommesnous .object-row-container .object-cell {
    background-image: url("/images/habitatprestopro/ligne-history.png");
    background-repeat: repeat-y;
    background-position: left top;
  }
}
#quisommesnous .object-row-container .object-cell:last-child {
  display: table;
}
#quisommesnous .object-row-container .object-cell:last-child .date-container {
  display: table-cell;
  vertical-align: middle;
}
#quisommesnous .object-row-container .object-cell:last-child .date-container .padded-cell {
  text-align: left;
  padding-left: 85px;
}
#quisommesnous .object-row-container .object-cell:last-child .date-container .date-row {
  position: relative;
  text-align: left;
  padding-left: 85px;
}
#quisommesnous .object-row-container .object-cell:last-child .date-container .date-row .text {
  font-weight: bold;
  font-size: 2.4rem;
  line-height: 3rem;
  color: #474747;
}
#quisommesnous .object-row-container .object-cell:last-child .date-container .date-row .arrow {
  background-image: url("images/habitatpresto/fleche-history-1.png");
  background-repeat: no-repeat;
  background-position: left center;
  width: 7px;
  height: 12px;
  position: absolute;
  left: 75px;
  top: 9px;
}
#quisommesnous .object-row-container .object-cell:last-child .date-container .date-row .marker {
  background-image: url("/images/habitatprestopro/losange.png");
  background-repeat: no-repeat;
  background-position: left center;
  height: 18px;
  width: 18px;
  position: absolute;
  left: -24px;
  top: 6px;
}
@media (max-width:767px) {
  #quisommesnous .object-row-container .object-cell:last-child .date-container .date-row .marker {
    left: -21px;
  }
}
#quisommesnous .object-row-container .object-cell:last-child .date-container .description {
  text-align: left;
  padding-left: 85px;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.8rem;
  line-height: 2.2rem;
  color: #474747;
  display: block;
}
@media (min-width:768px) {
  #quisommesnous .object-row-container .object-cell-separator {
    margin: 0 -15px;
    width: 30px;
    height: 280px;
    float: left;
    background-image: url("/images/habitatprestopro/ligne-history.png");
    background-repeat: repeat-y;
    background-position: center top;
  }
}
@media (min-width:768px) {
  #quisommesnous .object-row-container:nth-child(4n+1) .object-cell {
    float: right;
  }
  #quisommesnous .object-row-container:nth-child(4n+1) .object-cell:last-child .date-container .padded-cell {
    text-align: right;
    padding-right: 85px;
  }
  #quisommesnous .object-row-container:nth-child(4n+1) .object-cell:last-child .date-container .date-row {
    text-align: right;
    padding-right: 85px;
    padding-left: 0;
  }
  #quisommesnous .object-row-container:nth-child(4n+1) .object-cell:last-child .date-container .date-row .arrow {
    right: 75px;
    left: auto;
    background-position: right center;
    transform: rotate(180deg);
  }
  #quisommesnous .object-row-container:nth-child(4n+1) .object-cell:last-child .date-container .date-row .marker {
    right: -24px;
    left: auto;
    background-position: right center;
  }
  #quisommesnous .object-row-container:nth-child(4n+1) .object-cell:last-child .date-container .description {
    text-align: right;
    padding-right: 85px;
  }
  #quisommesnous .object-row-container:nth-child(4n+1) .object-cell-separator {
    float: right;
  }
}
@media (max-width:767px) {
  #quisommesnous .object-row-container .object-cell {
    height: 225px;
  }
  #quisommesnous .object-row-container .object-cell:last-child .date-container .padded-cell {
    padding-left: 35px;
  }
  #quisommesnous .object-row-container .object-cell:last-child .date-container .date-row {
    padding-left: 35px;
  }
  #quisommesnous .object-row-container .object-cell:last-child .date-container .date-row .arrow {
    left: 25px;
  }
  #quisommesnous .object-row-container .object-cell:last-child .date-container .description {
    padding-left: 35px;
  }
}
#quisommesnous .team {
  width: 100%;
}
#quisommesnous .team img {
  width: 100%;
  display: block;
}
#quisommesnous .entete {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.8rem;
  line-height: 2.2rem;
  color: #474747;
  text-align: justify;
  line-height: 30px;
}
#quisommesnous .entete strong {
  font-weight: bold;
}
#quisommesnous .title-page {
  font-size: 3.6rem;
  line-height: 3.6rem;
  color: #474747;
  font-weight: bold;
}
@media (max-width:767px) {
  #quisommesnous .row {
    margin-left: 0;
    margin-right: 0;
  }
}
#quisommesnous .mt-10 {
  margin-top: 10px;
}
#quisommesnous .mt-30 {
  margin-top: 30px;
}
#quisommesnous .mt-60 {
  margin-top: 60px !important;
}
#quisommesnous .mt-40 {
  margin-top: 40px;
}
#quisommesnous .mb-40 {
  margin-bottom: 40px;
}
#quisommesnous .mb-60 {
  margin-bottom: 60px;
}
#quisommesnous .nb-rea {
  font-size: 4.8rem;
  line-height: 4.8rem;
  color: #474747;
  font-weight: bold;
}
#quisommesnous .txt-rea {
  font-size: 3.6rem;
  line-height: 3.6rem;
  color: #474747;
  word-wrap: break-word;
  -o-hyphens: auto;
  hyphens: auto;
}
@media (max-width:767px) {
  #quisommesnous .rea {
    margin-top: 20px;
  }
}
#quisommesnous a.link {
  color: #474747;
  text-decoration: none;
}
#quisommesnous a.link:hover {
  color: #474747;
  text-decoration: underline;
}
#quisommesnous .align-right {
  text-align: right;
}
#quisommesnous .button.white2 {
  display: inline-block;
}
#quisommesnous .txt-rea-row > div {
  margin-bottom: 10px;
}
#quisommesnous .icone {
  background-image: url('/images/habitatprestopro/sprites.png');
  background-repeat: no-repeat;
  display: inline-block;
  vertical-align: middle;
  margin-right: 15px;
}
#quisommesnous .icone.helmet {
  background-position: -688px -116px;
  width: 53px;
  height: 42px;
}
#quisommesnous .icone.loupe {
  background-position: -744px -116px;
  width: 43px;
  height: 42px;
}
#quisommesnous .icone.trophy {
  background-position: -790px -116px;
  width: 50px;
  height: 42px;
}
#quisommesnous .txt-rea-orange {
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #ff804b;
  text-transform: uppercase;
  vertical-align: middle;
}
#quisommesnous .correction-align {
  margin-top: -4px;
}
#quisommesnous .title-history {
  font-size: 3.6rem;
  line-height: 3.6rem;
  color: #474747;
  font-weight: bold;
}
#quisommesnous .debut-history {
  height: 49px;
  width: 49px;
  color: #28a3b2;
  font-size: 16px;
  font-family: 'Open Sans Condensed', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  padding-top: 15px;
  padding-left: 4px;
}
@media (min-width:768px) {
  #quisommesnous .debut-history {
    background-image: url("/images/habitatprestopro/debut.png");
    color: white;
  }
}
@media (max-width:767px) {
  #quisommesnous .debut-history {
    margin-left: 40px;
  }
}
@media (max-width:767px) {
  #quisommesnous .start {
    background-image: url("/images/habitatprestopro/debut.png");
    background-position: 8px center;
    background-repeat: no-repeat;
    background-size: 20px auto;
    margin-bottom: -18px;
  }
}
#quisommesnous .fin-history {
  background-image: url("/images/habitatprestopro/fleche-history_1.png");
  background-repeat: no-repeat;
  height: 20px;
  width: 24px;
}
@media (max-width:767px) {
  #quisommesnous .fin-history {
    margin-left: -9px;
    margin-top: -1px;
  }
}
#quisommesnous .text-fin-history {
  color: #28a3b2;
  font-size: 16px;
  font-family: 'Open Sans Condensed', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 5px;
}
@media (max-width:767px) {
  #quisommesnous .text-fin-history {
    margin-top: -18px;
    text-align: left;
    margin-left: 40px;
  }
}
#quisommesnous .title-block {
  font-size: 3.6rem;
  line-height: 3.6rem;
  color: #474747;
}
#quisommesnous .txt-contact {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.8rem;
  line-height: 2.2rem;
  color: #474747;
}
@media (max-width:767px) {
  #quisommesnous .padd {
    text-align: center;
  }
  #quisommesnous .padd:nth-child(odd) {
    margin-top: 7px;
  }
}
#quisommesnous .iconLetter {
  width: 50px;
  height: 40px;
  position: absolute;
  background: url("/images/habitatpresto/iconLetter.png") #e6e6e6 no-repeat scroll 10px 10px;
  border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  border: 1px solid #979797;
  left: 15px;
}
#quisommesnous .form-control {
  padding-left: 60px;
  height: 40px;
  border: 1px solid #979797;
  margin-bottom: 20px;
}
#inscription {
  /****** bloc estimation *****/
  /******rea-home*****/
}
#inscription .progress {
  list-style: none;
  margin: 40px 0;
  padding: 0;
  display: table;
  table-layout: fixed;
  width: 100%;
  color: #929292;
  background-color: transparent;
  box-shadow: none;
}
#inscription .progress > li {
  font-size: 3.3rem;
  line-height: 3.6rem;
  position: relative;
  display: table-cell;
  text-align: center;
  font-size: 2rem;
  color: #474747;
}
#inscription .progress > li:before {
  content: attr(data-step);
  display: block;
  margin: 0 auto 0.25em;
  background: #e6e6e6;
  color: #FFFFFF;
  width: 3em;
  height: 3em;
  text-align: center;
  line-height: 3em;
  border-radius: 100%;
  position: relative;
  z-index: 1;
  font-size: 1.9rem;
  font-weight: 600;
}
#inscription .progress > li:after {
  content: '';
  position: absolute;
  display: block;
  background: #e6e6e6;
  width: 100%;
  height: 0.5rem;
  top: 1.25em;
  left: 49.2%;
  margin-left: 1.45em;
  z-index: 1;
}
#inscription .progress > li:last-child:after {
  display: none;
}
#inscription .progress > li.is-complete {
  color: #474747;
}
#inscription .progress > li.is-complete:before,
#inscription .progress > li.is-complete:after {
  color: #FFFFFF;
  background: #28a3b2;
}
#inscription .progress > li.is-active {
  color: #474747;
}
#inscription .progress > li.is-active:before {
  color: #FFFFFF;
  background: #28a3b2;
}
#inscription .basket__details .title {
  display: flex;
  justify-content: space-between;
  padding: 1.6rem;
  border-top: 1px solid black;
}
#inscription .basket__details .jobs,
#inscription .basket__details .departements {
  padding: 1.6rem;
}
#inscription .basket__details .sub__jobs,
#inscription .basket__details .sub__departements {
  padding-left: 1rem;
}
#inscription .basket__details .commisionned__fee {
  display: flex;
  justify-content: space-between;
  padding: 1.6rem;
  border-width: 1px 0 1px 0;
  border-color: black;
  border-style: solid;
}
#inscription .basket__details .first__month {
  display: flex;
  justify-content: end;
}
#inscription .basket__details .first__month > div {
  background-color: #ff7f5021;
  padding: 1.6rem;
}
#inscription .basket__details .first__month .price {
  display: flex;
  flex-direction: column;
}
#inscription .basket__details .first__month > span {
  padding: 1.6rem;
  background-color: #ff7f5021;
}
#inscription .mr-30 {
  margin-right: 3rem;
}
#inscription .my-10 {
  margin: 1rem 0;
}
#inscription .button {
  font-size: 18px;
  padding: 10px 60px;
  color: #FFFFFF;
  border: 1px solid #ff804b;
  background-color: #ff804b;
  border-radius: 3px 3px 3px 3px;
  background-clip: padding-box;
  white-space: nowrap;
  vertical-align: middle;
}
@media screen and (max-width:575px) {
  #inscription .button {
    padding: 10px;
  }
}
#inscription .new-btn-orange {
  margin-left: 1.6rem;
  padding: 10px 20px;
  border: 2px solid #ff844b;
  background-color: #ff844b;
  border-radius: 10px;
  color: #FFF;
  font-weight: bold;
  transition: 0.3s;
}
#inscription .new-btn-orange:hover {
  transform: scale(1.05);
}
#inscription .new-btn-orange-outline {
  margin-right: 1.6rem;
  padding: 10px 20px;
  border: 2px solid #ff844b;
  border-radius: 10px;
  background-color: #fff;
  font-weight: bold;
  color: #ff844b;
  transition: 0.3s;
}
#inscription .new-btn-orange-outline:hover {
  transform: scale(1.05);
}
#inscription label {
  font-size: 1.4rem;
  color: #474747;
  display: block;
  font-weight: normal;
  margin: 20px 0 5px;
  padding: 0;
  line-height: 1.4rem;
  text-align: left;
}
@media (max-width:767px) {
  #inscription div.image-wrapper.size-2.text {
    display: none !important;
  }
}
#inscription .entete {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.8rem;
  line-height: 2.2rem;
  color: #474747;
  line-height: 2.8rem;
}
@media (max-width:767px) and (max-width:991px) {
  #inscription .entete {
    text-align: center;
    margin-top: 60px;
  }
}
#inscription .account {
  font-size: 1.2rem;
}
#inscription .account .orange {
  color: #ff804b;
}
@media (max-width:767px) and (max-width:991px) {
  #inscription .account {
    font-size: 1.4rem;
    line-height: 2.2rem;
    text-align: center;
  }
}
#inscription .count-society {
  background-color: #082b37;
  background-image: url('/images/habitatprestopro/bg-footer.jpg');
  padding: 30px 0;
}
#inscription .count-society .normal_text {
  font-family: 'Open Sans Condensed', sans-serif;
  font-size: 3.3rem;
  color: #FFFFFF;
}
#inscription .count-society .strong_text {
  font-family: 'Open Sans Condensed', sans-serif;
  font-weight: bold;
  font-size: 3.6rem;
  line-height: 4.8rem;
  color: #FFFFFF;
}
#inscription .title-page {
  font-size: 3.6rem;
  line-height: 3.6rem;
  color: #474747;
  font-weight: bold;
}
#inscription .mt-60 {
  margin-top: 60px !important;
}
#inscription .ml-150 {
  margin-left: -150px !important;
}
#inscription .ml-40 {
  margin-left: 40px !important;
}
#inscription .mb-60 {
  margin-bottom: 60px;
}
#inscription .block-login {
  border: 1px solid #cecece;
  border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -webkit-border-radius: 3px 3px 3px 3px;
  padding: 40px 0;
}
#inscription .block-login p {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.8rem;
  line-height: 2.2rem;
  color: #474747;
  margin-top: 10px;
  margin-left: -15px;
}
#inscription .icon-workforce {
  width: 28px;
  height: 28px;
  display: inline-block;
  margin-top: 7px;
  margin-left: 10px;
  background-image: url('/build/images/pro/workforce-icon-xs.png');
  background-repeat: no-repeat;
}
#inscription .icon-workforce-error {
  width: 28px;
  height: 28px;
  display: inline-block;
  margin-top: 7px;
  margin-left: 10px;
  background-image: url('/build/images/pro/workforce-icon-error-xs.png');
  background-repeat: no-repeat;
}
#inscription .sprite-activity {
  background-image: url('/images/habitatprestopro/sprite-profile.png');
  background-repeat: no-repeat;
  background-position: -200px 0;
  width: 27px;
  margin-left: 10px;
  background-position: 0 0;
  width: 34px;
  height: 28px;
  display: inline-block;
  margin-top: 8px;
  margin-left: 7px;
}
#inscription .sprite-activity.has-error {
  background-position: -230px 0;
}
#inscription .sprite-activity.has-error {
  background-position: -38px 0;
}
#inscription .sprite-zipcode {
  background-image: url('/images/habitatprestopro/sprite-profile.png');
  background-repeat: no-repeat;
  background-position: -200px 0;
  width: 27px;
  margin-left: 10px;
  background-position: -72px 0;
  width: 28px;
  height: 28px;
  display: inline-block;
  margin-top: 8px;
  margin-left: 13px;
}
#inscription .sprite-zipcode.has-error {
  background-position: -230px 0;
}
#inscription .sprite-zipcode.has-error {
  background-position: -100px 0;
}
#inscription .sprite-society {
  background-image: url('/images/habitatprestopro/sprite-profile.png');
  background-repeat: no-repeat;
  background-position: -200px 0;
  width: 27px;
  height: 28px;
  margin-left: 10px;
  background-position: -125px 0;
  width: 34px;
  height: 27px;
  display: inline-block;
  margin-top: 8px;
  margin-left: 7px;
}
#inscription .sprite-society.has-error {
  background-position: -230px 0;
}
#inscription .sprite-society.has-error {
  background-position: -162px 0;
}
#inscription .sprite-profile {
  background-image: url('/images/habitatprestopro/sprite-profile.png');
  background-repeat: no-repeat;
  background-position: -200px 0;
  width: 27px;
  height: 28px;
  display: inline-block;
  margin-top: 8px;
  margin-left: 10px;
}
#inscription .sprite-profile.has-error {
  background-position: -230px 0;
}
#inscription .sprite-phone {
  background-image: url('/images/habitatprestopro/sprite-profile.png');
  background-repeat: no-repeat;
  background-position: -200px 0;
  width: 27px;
  margin-left: 10px;
  background-position: -260px 0;
  width: 28px;
  height: 28px;
  display: inline-block;
  margin-top: 8px;
  margin-left: 9px;
}
#inscription .sprite-phone.has-error {
  background-position: -230px 0;
}
#inscription .sprite-phone.has-error {
  background-position: -290px 0;
}
#inscription .sprite-mail {
  background-image: url('/images/habitatprestopro/sprite-profile.png');
  background-repeat: no-repeat;
  background-position: -200px 0;
  width: 27px;
  height: 28px;
  margin-top: 8px;
  background-position: -323px 0;
  width: 28px;
  height: 19px;
  display: inline-block;
  margin-top: 12px;
  margin-left: 10px;
}
#inscription .sprite-mail.has-error {
  background-position: -230px 0;
}
#inscription .sprite-mail.has-error {
  background-position: -354px 0;
}
#inscription .sprite-password {
  background-image: url('/images/habitatprestopro/sprite-profile.png');
  background-repeat: no-repeat;
  background-position: -200px 0;
  width: 27px;
  height: 28px;
  margin-top: 8px;
  margin-left: 10px;
  background-position: -385px 0;
  width: 19px;
  height: 26px;
  display: inline-block;
  margin-top: 9px;
  margin-left: 13px;
}
#inscription .sprite-password.has-error {
  background-position: -230px 0;
}
#inscription .sprite-password.has-error {
  background-position: -407px 0;
}
#inscription .sprite-check {
  background-image: url('/images/habitatprestopro/sprite-profile.png');
  background-repeat: no-repeat;
  background-position: -200px 0;
  width: 27px;
  height: 28px;
  margin-top: 8px;
  margin-left: 10px;
  background-position: -428px 0;
  width: 22px;
  height: 16px;
  display: inline-block;
  margin-top: 1px;
  margin-left: 0;
}
#inscription .sprite-check.has-error {
  background-position: -230px 0;
}
#inscription .sprite-calendar {
  background-image: url('/images/habitatprestopro/sprite-profile.png');
  background-repeat: no-repeat;
  background-position: -200px 0;
  width: 27px;
  height: 28px;
  margin-left: 10px;
  background-position: -454px 0;
  display: inline-block;
  margin-top: 8px;
  margin-left: 11px;
}
#inscription .sprite-calendar.has-error {
  background-position: -230px 0;
}
#inscription .iconLetter {
  width: 50px;
  z-index: 3;
  height: 45px;
  position: absolute;
  background: #e6e6e6;
  border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  border: 1px solid #979797;
  margin-top: 39px;
}
#inscription .iconLetter.block {
  margin-top: 0;
}
#inscription .iconLetter.has-error {
  border: 1px solid #c03634;
  background-color: #fed3d5;
  z-index: 5;
}
@media screen and (max-width:767px) {
  #inscription .iconLetter {
    margin-top: 37px;
  }
}
#inscription .iconLetter.search-company {
  margin-top: 49px;
}
@media screen and (max-width: 546px) {
  #inscription .iconLetter.search-company {
    margin-top: 49px;
  }
}
@media screen and (min-width: 547px) and (max-width:767px) {
  #inscription .iconLetter.search-company {
    margin-top: 37px;
  }
}
@media screen and (min-width:768px) and (max-width:991px) {
  #inscription .iconLetter.search-company {
    margin-top: 39px;
  }
}
@media screen and (min-width:992px) {
  #inscription .iconLetter.search-company {
    margin-top: 53px;
  }
}
#inscription .form-control {
  height: 45px;
  border: 1px solid #979797;
  padding-left: 60px;
}
#inscription .form-control.select2-container {
  padding: 0;
  border-radius: 2px;
  margin-top: 20px;
}
#inscription .form-control.select2-container .select2-choice {
  height: 43px !important;
  width: 100%;
  background-color: #FFFFFF;
  background-image: linear-gradient(to top, #FFFFFF 0, #FFFFFF 50%);
  font-size: 17px;
  border-radius: 0 4px 0;
  border: none;
  padding: 6px 12px;
}
#inscription .form-control.has-error {
  border-color: #a94442;
}
#inscription .form-horizontal {
  margin-top: -40px;
}
#inscription .form-horizontal .has-feedback .form-control-feedback {
  margin-top: -5px;
  margin-right: -10px;
}
#inscription .form-group {
  margin-top: 15px;
}
#inscription .form-group .has-success.has-feedback .form-control-feedback {
  margin-top: 5px;
}
#inscription .checkbox {
  display: inline-flex;
}
#inscription .checkbox label {
  padding-left: 20px;
  margin: 10px 0;
  min-height: 10px;
}
#inscription .checkbox label input {
  margin-top: 0;
}
#inscription .pl-60 {
  padding-left: 60px;
}
#inscription .title-step {
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #474747;
  margin-left: -15px;
}
#inscription .title-step .subtitle {
  font-size: 16px;
}
#inscription .title-step-1 {
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #474747;
  margin-left: -15px;
}
@media (max-width:767px) and (max-width:991px) {
  #inscription .title-step-1 {
    text-align: center;
  }
}
#inscription .button.orange1 {
  font-size: 18px;
}
#inscription .float-r {
  float: right;
}
#inscription .nb-1 {
  background-image: url("/images/habitatprestopro/inscription-nb.png");
  height: 40px;
  width: 40px;
  color: #FFFFFF;
  font-size: 18px;
  font-family: 'Open Sans Condensed', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  padding-top: 11px;
  padding-left: 15px;
}
#inscription .nb-check {
  background-image: url("/images/habitatprestopro/inscription-nb.png");
  height: 40px;
  width: 40px;
  color: #FFFFFF;
  font-size: 18px;
  font-family: 'Open Sans Condensed', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  padding-top: 11px;
  padding-left: 10px;
}
#inscription .ligne-inscription {
  background-image: url("/images/habitatprestopro/ligne-inscription.png");
  background-repeat: repeat-y;
  background-position: center top;
}
#inscription .ligne-height-80 {
  height: 80px;
}
#inscription .ligne-height-95 {
  height: 95px;
}
#inscription .ligne-height-120 {
  height: 120px;
}
#inscription .ligne-height-200 {
  height: 200px;
}
#inscription .ligne-height-250 {
  height: 250px;
}
#inscription .ligne-height-290 {
  height: 290px;
}
#inscription .ligne-height-320 {
  height: 320px;
}
#inscription .ligne-height-350 {
  height: 350px;
}
#inscription .ligne-height-420 {
  height: 420px;
}
#inscription .vert {
  color: #28a3b2;
}
#inscription .password-criteria {
  line-height: 1.8rem;
  margin-bottom: 20px;
}
#inscription .password-criteria ul {
  font-weight: bold;
}
#inscription .pass__btn {
  padding: 10px 20px;
}
#inscription .chp-obligatoire {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.2rem;
  line-height: 1.5rem;
  color: #474747;
  text-align: right;
  margin-right: -5px;
  margin-bottom: 10px;
}
#inscription .pwd-restrictions {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.2rem;
  line-height: 1.5rem;
  color: #474747;
}
#inscription .txt-formulaire {
  margin-left: -15px;
  margin-top: 15px;
  line-height: 18px;
}
#inscription a.case {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: #474747;
  border: 1px solid #979797;
  border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -webkit-border-radius: 3px 3px 3px 3px;
  width: 100px;
  height: 100px;
  float: left;
  margin: 15px 0 25px 0;
  padding-top: 8px;
}
@media (max-width:767px) {
  #inscription a.case {
    width: 90px;
  }
}
#inscription .cgu-txt {
  display: inline;
  margin-left: 7px;
  line-height: 2.2rem;
}
#inscription .marge {
  margin-right: 15px !important;
  margin-left: -15px !important;
}
@media (max-width:767px) {
  #inscription .marge {
    margin-right: 5px !important;
  }
}
#inscription #estimation .bloc-estimation {
  width: 250px;
  height: 225px;
  border: solid 1px #e8e5e5;
  background-color: #f0f0f0;
  text-align: center;
  margin-left: 122px;
}
#inscription #estimation .bloc-estimation .tarifs {
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #474747;
  font-weight: bold;
}
#inscription #estimation .bloc-estimation .visuel-estimation {
  margin-top: 12px;
}
@media (max-width:1199px) {
  #inscription #estimation .bloc-estimation {
    margin-left: 58px;
  }
}
@media (max-width:991px) {
  #inscription #estimation .bloc-estimation {
    margin: 60px auto 0 auto;
  }
}
#inscription a.lien-underline,
#inscription a.lien-underline:hover,
#inscription a.lien-underline:active,
#inscription a.lien-underline:visited {
  text-decoration: underline;
  color: #474747;
}
#inscription .wrapper-bleu {
  width: 100%;
  background-color: #cefbfd;
}
#inscription .wrapper-bleu .no-padding {
  padding: 0;
}
#inscription .wrapper-bleu .bg-bleu {
  background-color: #28a3b2;
}
#inscription .wrapper-bleu .bg-bleu h2 {
  font-weight: bold;
  font-size: 4.8rem;
  line-height: 4.8rem;
  color: white;
  font-size: 36px;
}
#inscription .wrapper-bleu .bg-bleu p {
  color: #FFFFFF;
  line-height: 18px;
  margin-bottom: 40px;
}
#inscription .wrapper-bleu .bg-bleu-clair {
  background-color: #cefbfd;
}
#inscription .wrapper-bleu .bg-bleu-clair h2 {
  font-weight: bold;
  font-size: 4.8rem;
  line-height: 4.8rem;
  color: #474747;
  font-size: 36px;
}
#inscription .wrapper-bleu .bg-bleu-clair p {
  line-height: 18px;
  margin-bottom: 40px;
}
#inscription .wrapper-bleu .sprite-chantier {
  background-image: url('/images/habitatprestopro/sprites.png');
  background-repeat: no-repeat;
  background-position: 0 0;
  width: 101px;
  height: 101px;
  margin-top: 40px;
  margin-bottom: 10px;
}
#inscription .wrapper-bleu .sprite-commercial {
  background-image: url('/images/habitatprestopro/sprites.png');
  background-repeat: no-repeat;
  background-position: -629px 0;
  width: 101px;
  height: 101px;
  margin-top: 40px;
  margin-bottom: 10px;
}
#inscription .wrapper-bleu .sprite-chantiers {
  background-image: url('/images/habitatprestopro/sprites.png');
  background-repeat: no-repeat;
  background-position: -734px 0;
  width: 101px;
  height: 101px;
  margin-top: 40px;
  margin-bottom: 10px;
}
#inscription #inscription_select .iconLetter {
  margin-top: 0!important;
}
#inscription #inscription_select span.glyphicon.glyphicon-remove.form-control-feedback {
  margin-top: 18px;
}
#inscription .company-list {
  display: flex;
  flex-flow: row wrap;
}
#inscription .company-list .company-profile {
  border: 1px solid #c8c8c8;
  margin: 0 0 20px 15px;
  padding: 15px;
  vertical-align: top;
  width: 45%;
}
#inscription .company-list .company-profile h3 {
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #474747;
  color: #28a3b2;
  border-bottom: 2px solid #28a3b2;
}
#inscription .company-list .company-profile h4 {
  margin: 10px 0;
  color: #c8c8c8;
  text-transform: uppercase;
  font-size: 1.4rem;
}
#inscription .company-list .company-profile address {
  font-style: normal;
  font-weight: bold;
  margin: 5px 0;
  text-transform: capitalize;
  font-size: 1.6rem;
}
#inscription .company-list .company-profile .description {
  color: #c8c8c8;
  margin: 20px 0 10px;
}
#inscription .company-list .company-profile a {
  text-align: right;
  color: #ff804b;
  margin-top: 10px;
  font-weight: 500;
}
#inscription .pagination > li:nth-of-type(1) {
  display: none;
}
#inscription .pagination > li > span,
#inscription .pagination > li > a {
  color: #474747;
  text-decoration: none;
  background-color: #FFFFFF;
  border: none;
}
#inscription .pagination > li > span.active,
#inscription .pagination > li > a.active,
#inscription .pagination > li > span:hover,
#inscription .pagination > li > a:hover,
#inscription .pagination > li > span:focus,
#inscription .pagination > li > a:focus {
  color: #ff804b;
  text-decoration: none;
}
#inscription .pagination > li:last-of-type a {
  color: #ff804b;
}
#inscription .pagination > li.active span {
  color: #ff804b;
}
#inscription .iti {
  display: block;
  margin-right: 15px;
  margin-top: -15px;
}
#registration-sites .iti {
  display: block;
}
#registration-sites .image-wrapper {
  background: url('/images/habitatprestopro/background.jpg') no-repeat bottom center;
  display: table;
  background-size: cover;
  width: 100%;
}
@media (max-width:767px) {
  #registration-sites .image-wrapper {
    height: 450px;
  }
}
#registration-sites .image-wrapper .wrapped-content > .container > .row {
  display: flex;
  margin-bottom: 50px;
}
@media (max-width:991px) {
  #registration-sites .image-wrapper .wrapped-content > .container > .row {
    flex-direction: column;
  }
}
#registration-sites .image-wrapper .wrapped-content > .container > .row .h2 {
  font-size: 4.8rem;
  line-height: 4.8rem;
  color: white;
  line-height: 4rem !important;
  text-align: center;
  color: #29a3b2;
  font-weight: bold;
  font-size: 3.6rem;
  margin-top: 20px;
  padding-top: 20px;
}
@media (max-width:767px) {
  #registration-sites .image-wrapper .wrapped-content > .container > .row .h2 {
    font-size: 3.8rem;
  }
}
#registration-sites .image-wrapper .wrapped-content .form-column {
  margin-top: 60px;
  background-color: #d8f7f9;
}
#registration-sites .image-wrapper .wrapped-content .form-column .title {
  display: none;
}
@media (max-width:991px) {
  #registration-sites .image-wrapper .wrapped-content .form-column .title {
    display: block;
    background-color: rgba(255, 255, 255, 0.8);
    margin: -15px -9px;
  }
}
#registration-sites .image-wrapper .wrapped-content .form-column .title h1 {
  padding-top: 30px;
}
#registration-sites .image-wrapper .wrapped-content .form-column .title .sub-title {
  font-family: 'Open Sans Condensed', sans-serif;
  color: #474747;
  font-size: 2.3rem;
  line-height: 3.2rem;
  text-align: center;
  padding: 20px 30px;
  text-transform: none;
}
#registration-sites .image-wrapper .wrapped-content .form-column > p {
  font-size: 1rem;
  margin: 20px 0;
  text-align: right;
}
@media (max-width:991px) {
  #registration-sites .image-wrapper .wrapped-content .form-column > p {
    padding-top: 20px;
  }
}
#registration-sites .image-wrapper .wrapped-content .form-column .form-horizontal {
  padding: 0 30px;
}
#registration-sites .image-wrapper .wrapped-content .form-column .form-horizontal .zipcode-wrapper {
  margin: 0;
}
#registration-sites .image-wrapper .wrapped-content .form-column .form-horizontal span {
  display: block;
  font-weight: normal;
  margin: 7px -12px 0;
  line-height: 2rem;
  white-space: nowrap;
}
#registration-sites .image-wrapper .wrapped-content .form-column .form-horizontal span.activities-select {
  margin: 3px -12px 14px;
}
#registration-sites .image-wrapper .wrapped-content .form-column .form-horizontal .form-control {
  height: 45px;
  border: 1px solid #979797;
}
#registration-sites .image-wrapper .wrapped-content .form-column .form-horizontal .form-control.form-error.has-error {
  border: 1px solid #a94442;
}
#registration-sites .image-wrapper .wrapped-content .form-column .form-horizontal .has-feedback .form-control-feedback {
  margin-top: -5px;
  margin-right: -10px;
}
#registration-sites .image-wrapper .wrapped-content .form-column .cgu-wapper {
  margin-right: -15px;
  margin-left: -15px;
}
#registration-sites .image-wrapper .wrapped-content .form-column .cgu-wapper .cgu {
  font-size: 1.2rem;
  line-height: 2.2rem;
  display: inline;
  margin-left: 7px;
  color: #353535;
  vertical-align: text-bottom;
}
#registration-sites .image-wrapper .wrapped-content .form-column .cgu-wapper .cgu a {
  text-decoration: underline;
  color: #474747;
}
#registration-sites .image-wrapper .wrapped-content .form-column .button.orange1 {
  padding: 10px 40px;
  margin: 20px auto 40px;
}
#registration-sites .image-wrapper .wrapped-content .wording-column {
  margin-top: 60px;
  background-color: rgba(255, 255, 255, 0.8);
  border: 4px solid #d8f7f9;
}
@media (max-width:991px) {
  #registration-sites .image-wrapper .wrapped-content .wording-column {
    margin-top: 0;
  }
}
@media (max-width:991px) {
  #registration-sites .image-wrapper .wrapped-content .wording-column .title {
    display: none;
  }
}
#registration-sites .image-wrapper .wrapped-content .wording-column img {
  margin-top: 5px;
}
#registration-sites .image-wrapper .wrapped-content .wording-column p {
  font-size: 1.6rem;
  line-height: 2.2rem;
}
#registration-sites .image-wrapper .wrapped-content .wording-column p.sub-title {
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #474747;
  font-size: 2.3rem;
  line-height: 3.2rem;
  margin-top: 10px;
  text-align: center;
}
#registration-sites .image-wrapper .wrapped-content .wording-column p.testimony {
  margin: 30px 0 10px;
  text-align: right;
  color: #727272;
}
#registration-sites .image-wrapper .wrapped-content .wording-column h1 {
  font-size: 1.6rem;
  line-height: 2.2rem;
}
#registration-sites .image-wrapper .wrapped-content .wording-column h1.sub-title {
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #474747;
  font-size: 2.3rem;
  line-height: 3.2rem;
  margin-top: 10px;
  text-align: center;
  text-transform: none;
}
#registration-sites .image-wrapper .wrapped-content .reassurances {
  margin: 40px 0;
}
#registration-sites .image-wrapper .wrapped-content .reassurances p {
  font-size: 1.6rem;
  line-height: 2.8rem;
  margin-bottom: 20px;
  color: #FFFFFF;
}
#registration-sites .image-wrapper .wrapped-content .reassurances p .block {
  display: block;
}
#registration-sites .contacts {
  color: #FFFFFF;
  background-color: #11404d;
  padding: 30px 0;
}
#registration-sites .contacts p {
  font-size: 2rem;
  line-height: 2rem;
}
#registration-sites .contacts p:last-of-type {
  font-size: 1.2rem;
}
#registration-sites .contacts a {
  color: #FFFFFF;
  font-size: 2.4rem;
  text-align: center;
  display: block;
  font-weight: bold;
  margin-top: 20px;
}
#registration-sites .contacts a:hover,
#registration-sites .contacts a:focus {
  color: #FFFFFF;
}
#registration-sites .cgu p {
  color: #727272;
  font-size: 1.2rem;
  line-height: 1.2rem;
}
#registration-sites .cgu p a {
  color: #727272;
  text-decoration: none;
}
#registration-sites .cgu p a:hover,
#registration-sites .cgu p a:focus {
  color: #727272;
}
#registration-sites.inscription-landing .image-wrapper {
  display: table;
  width: 100%;
}
#registration-sites.inscription-landing .image-wrapper.painter {
  background: url('/images/habitatprestopro/landing-painter.jpg') no-repeat bottom center;
  background-size: cover;
}
#registration-sites.inscription-landing .image-wrapper.builder {
  background: url('/images/habitatprestopro/landing-builder.jpg') no-repeat bottom center;
  background-size: cover;
}
#registration-sites.inscription-landing .image-wrapper.plumber {
  background: url('/images/habitatprestopro/landing-plumber.jpg') no-repeat bottom center;
  background-size: cover;
}
#registration-sites.inscription-landing .image-wrapper.electrician {
  background: url('/images/habitatprestopro/landing-electrician.jpg') no-repeat bottom center;
  background-size: cover;
}
#registration-sites.inscription-landing .image-wrapper.building-company {
  background: url('/images/habitatprestopro/landing-building-company.jpg') no-repeat bottom center;
  background-size: cover;
}
#registration-sites.inscription-landing .image-wrapper form .iti {
  display: block;
}
#registration-sites.inscription-landing .image-wrapper form .control-label {
  padding: 0;
}
#registration-sites.inscription-landing .image-wrapper form .form-group {
  margin-bottom: 10px;
}
#registration-sites.inscription-landing .image-wrapper form .activities-select {
  margin: 7px -12px 0 !important;
}
#registration-sites.inscription-landing .wording-column.description h2 {
  font-size: 4.8rem;
  line-height: 4.8rem;
  color: white;
  line-height: 4rem !important;
  text-align: center;
  color: #29a3b2;
  font-weight: bold;
  font-size: 3.6rem;
  margin-top: 20px;
  padding-top: 20px;
}
@media (max-width:767px) {
  #registration-sites.inscription-landing .wording-column.description h2 {
    font-size: 3.8rem;
  }
}
#registration-sites.inscription-landing .wording-column.description .subtitle-landing {
  font-family: 'Open Sans Condensed', sans-serif;
  color: #474747;
  font-size: 2.3rem;
  line-height: 3.2rem;
  text-align: center;
  padding: 20px 30px;
  text-transform: none;
}
#registration-sites.inscription-landing .wording-column.description p {
  line-height: 2.3rem;
  margin-bottom: 15px;
}
#registration-sites.inscription-landing .form-column {
  margin-top: 60px;
  background-color: #d8f7f9;
}
#registration-sites.inscription-landing .form-column .title {
  display: none;
}
@media (max-width:991px) {
  #registration-sites.inscription-landing .form-column .title {
    display: block;
    background-color: rgba(255, 255, 255, 0.8);
    margin: -15px -9px;
  }
}
#registration-sites.inscription-landing .form-column .title h2 {
  padding-top: 30px;
}
#registration-sites.inscription-landing .form-column .title .subtitle-landing {
  font-family: 'Open Sans Condensed', sans-serif;
  color: #474747;
  font-size: 2.3rem;
  line-height: 3.2rem;
  text-align: center;
  padding: 20px 30px;
  text-transform: none;
}
.text-orange-hp {
  color: #ff804b;
}
.success-signup {
  font-size: 16px;
  line-height: 1.6rem;
}
.success-signup .bg-orange {
  background-color: #fff6f1;
  padding: 7rem 0;
}
.success-signup .flex {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  gap: 5rem;
}
.success-signup .mb-5 {
  margin-bottom: 5rem;
}
.success-signup .mb-10 {
  margin-bottom: 10rem;
}
.success-signup .mb-3 {
  margin-bottom: 3rem;
}
.success-signup .fs-1 {
  font-size: 2.6rem;
}
.success-signup .fs-2 {
  font-size: 2rem;
}
.success-signup .ld-5 {
  line-height: 3.6rem;
}
.success-signup .header-hp {
  padding: 2rem;
}
.success-signup .thanks-to-signup {
  margin-bottom: 8rem;
  margin-top: 5rem;
}
.success-signup .thanks-to-signup .text {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 5rem;
}
.success-signup .thanks-to-signup .text .title {
  font-size: 2.6rem;
  line-height: 4rem;
  margin-top: 2rem;
  margin-bottom: 3rem;
}
.success-signup .thanks-to-signup .text .subtitle {
  font-size: 1.8rem;
  line-height: 2.6rem;
}
.success-signup .thanks-to-signup .img {
  flex: 1;
  display: flex;
  justify-content: center;
}
.success-signup .thanks-to-signup .img img {
  width: 100%;
  max-width: 320px;
}
.success-signup .connect-block {
  text-align: center;
  margin-bottom: 3rem;
  line-height: 1.6rem;
  background: #fff6f1;
  padding: 4rem 0;
}
.success-signup .connect-block .overlap {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media only screen and (max-width: 760px) {
  .success-signup .connect-block .overlap {
    margin-top: 6rem;
  }
}
@media only screen and (max-width: 760px) {
  .success-signup .connect-block .overlap {
    flex-direction: column;
  }
}
.success-signup .connect-block .overlap .sign-in {
  padding: 3rem;
  border-radius: 1rem;
  border: 1px solid #969696;
  z-index: 2;
  background-color: white;
}
.success-signup .connect-block .overlap .sign-in .title {
  font-size: 2rem;
  margin-bottom: 5rem;
}
.success-signup .connect-block .overlap .sign-in a {
  border-radius: 99rem;
  background-color: #ff804b;
  font-weight: bold;
  margin-top: 2rem;
  color: white;
  border: none;
  padding: 0.6rem 2.2rem;
}
.success-signup .connect-block .overlap .illustration {
  width: 400px;
  height: 400px;
  margin-left: -3rem;
}
@media only screen and (max-width: 760px) {
  .success-signup .connect-block .overlap .illustration {
    margin-left: 0;
  }
}
.success-signup .connect-block .overlap .illustration img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.success-signup .steps {
  margin-bottom: 10rem;
}
.success-signup .steps .step {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  padding: 5rem;
}
.success-signup .steps .step img {
  width: 80px;
  height: auto;
}
.success-signup .steps .step p {
  text-align: center;
  line-height: 1.8rem;
}
.success-signup .about-our-client {
  text-align: center;
  margin-bottom: 5rem;
  line-height: 2.6rem;
}
.success-signup .about-our-client .title {
  font-size: 2rem;
  margin-bottom: 2rem;
}
.success-signup .about-our-client .subtitle {
  width: 90%;
  margin-bottom: 4rem;
}
@media only screen and (max-width: 760px) {
  .success-signup .about-our-client .subtitle {
    width: 100%;
  }
}
.success-signup .about-our-client .see-reviews {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 4rem;
}
.success-signup .about-our-client .section .illustration {
  width: 100%;
  max-width: 450px;
  height: auto;
}
.success-signup .about-our-client .section .slider-container {
  position: relative;
  max-width: 600px;
  overflow: hidden;
}
.success-signup .about-our-client .section .slider-container .slider {
  display: flex;
  transition: transform 0.3s ease-in-out;
}
.success-signup .about-our-client .section .slider-container .slider .review {
  width: 100%;
  flex: 0 0 auto;
  box-sizing: border-box;
  padding: 20px 40px;
  background: #f1f1f1;
  border-radius: 1rem;
  text-align: left;
  line-height: 2.6rem;
}
.success-signup .about-our-client .section .slider-container .slider .review .author {
  font-size: 1.2rem;
}
.success-signup .about-our-client .section .slider-container .slider .review .author span {
  font-weight: bold;
  font-size: 1.8rem;
}
.success-signup .about-our-client .section .slider-container .slider .review .title {
  font-weight: bold;
  font-size: 1.8rem;
  margin-bottom: 2rem;
}
.success-signup .about-our-client .section .slider-container .slider .review .stars {
  color: #00b67a;
  font-size: 2.2rem;
  margin-bottom: 10px;
}
.success-signup .about-our-client .section .slider-container .slider .review .description {
  font-size: 1.6rem;
}
.success-signup .about-our-client .section .slider-container button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 22px;
  color: #000;
  border: none;
  background-color: transparent;
  border-radius: 99rem;
  padding: 5px;
  z-index: 10;
  cursor: pointer;
}
.success-signup .about-our-client .section .slider-container .prev-btn {
  left: 10px;
}
.success-signup .about-our-client .section .slider-container .next-btn {
  right: 10px;
}
.success-signup .want-signup {
  text-align: center;
  margin-bottom: 10rem;
  line-height: 1.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media only screen and (max-width: 760px) {
  .success-signup .want-signup {
    flex-direction: column;
    gap: 3rem;
  }
}
.success-signup .want-signup div {
  flex: 1;
}
.success-signup .want-signup .title {
  margin-bottom: 3rem;
  font-size: 1.8rem;
}
.success-signup .want-signup .subtitle {
  margin-bottom: 2.5rem;
  font-size: 2rem;
}
.success-signup .want-signup .desc {
  text-align: left;
  line-height: 2.2rem;
  margin-bottom: 3rem;
}
@media only screen and (max-width: 760px) {
  .success-signup .want-signup .desc {
    text-align: center;
  }
}
.success-signup .want-signup .cta {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5rem;
}
.success-signup .want-signup .cta img {
  width: 40px;
  height: 40px;
}
.success-signup .want-signup .cta a {
  font-weight: bold;
  color: black;
  text-decoration: none;
}
.success-signup .newsletter-pro {
  line-height: 3.4rem;
}
.success-signup .new-btn-orange {
  display: inline-block;
  padding: 0.5rem 1rem;
  text-align: center;
  border-radius: 0.6rem;
  background-color: #ff844b;
  color: white;
  font-weight: bold;
  transition: 0.3s;
}
.success-signup .new-btn-orange:hover,
.success-signup .new-btn-orange-outline:hover {
  transform: scale(1.03);
  text-decoration: none;
}
.success-signup .new-btn-orange:hover {
  color: white;
}
.success-signup .new-btn-orange-outline:hover {
  color: #ff844b;
}
.success-signup .new-btn-orange-outline {
  display: inline-block;
  padding: 1rem 3rem;
  text-align: center;
  border: 2px solid #ff844b;
  border-radius: 99rem;
  background-color: white;
  color: #ff844b;
  transition: 0.3s;
  font-weight: bold;
}
.success-signup .newsletter-input:focus {
  box-shadow: inset 0 1px 1px rgba(255, 255, 255, 0.08), 0 0 8px rgba(249, 200, 176, 0.6);
}
.success-signup .menu-pro {
  display: flex;
  justify-content: space-evenly;
  margin: 5rem 0;
  flex-flow: row wrap;
  -webkit-user-select: none;
  user-select: none;
  cursor: default;
}
.success-signup .menu-pro a {
  color: #000000;
}
@media only screen and (max-width: 760px) {
  .success-signup .menu-pro {
    flex-direction: column;
    gap: 2.4rem;
    padding: 0;
  }
}
.success-signup .menu-pro .underline-orange {
  border-bottom: 3px solid #ff844b;
}
.success-signup .menu-pro .menu-pro-item {
  border-bottom: 3px solid transparent;
  transition: 0.3s;
}
.success-signup .menu-pro .menu-pro-item.active {
  border-color: #ff844b;
}
.success-signup .menu-pro .menu-pro-item:hover {
  border-color: #ff844b;
  color: #000000;
}
.success-signup .latest-article-item {
  position: relative;
  display: block;
  color: #000000;
}
.success-signup .latest-article-item:hover {
  text-decoration: none;
}
.success-signup .latest-article-item:hover .latest-article-title {
  color: #e35d20;
}
.success-signup .latest-article-item:hover .latest-img-desc {
  transform: scale(1.035);
}
.success-signup .latest-article-item figure {
  position: relative;
  width: 100%;
  height: auto;
  margin-bottom: 1rem;
  margin: 0;
  max-width: 100%;
  max-height: 100%;
  border-radius: 0.6rem;
  overflow: hidden;
}
.success-signup .latest-article-item figure .latest-img-desc {
  object-fit: cover;
  height: 100%;
  width: 100%;
  padding: 0;
  border-radius: 0.6rem;
  filter: brightness(80%);
  transition: transform 0.3s;
  will-change: transform;
}
.success-signup .latest-article-item .latest-article-title {
  margin: 2rem 0;
  color: #000000;
  font-weight: bold;
  transition: color 0.3s;
  will-change: color;
}
.success-signup .social-pro {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5rem;
}
.success-signup .social-pro .social {
  display: block;
  width: 64px;
  height: 64px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
@media only screen and (max-width: 512px) {
  .success-signup .header-hp {
    padding: 2rem;
    text-align: center;
  }
}
.image-cell {
  display: table;
  height: 100%;
  table-layout: fixed;
}
.image-cell:not(.free-width) {
  width: 100%;
}
.image-cell .image-positioner {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  padding: 10px;
}
.image-cell .image-positioner img {
  margin: 0 auto;
  max-width: 100%;
  max-height: 100%;
  height: auto;
}
.image-cell .image-positioner .rounded-full {
  border-radius: 999rem;
}
.object-grid-container.image-content .object-row-container {
  padding: 0;
}
.object-grid-container.image-content .object-row-container .object-cell {
  padding: 0;
  height: 267px;
}
@media (min-width:992px) {
  .object-grid-container.image-content .object-row-container .object-cell {
    height: 243px;
  }
}
@media (min-width:1200px) {
  .object-grid-container.image-content .object-row-container .object-cell {
    height: 267px;
  }
}
@media (min-width:768px) and (max-width:991px) {
  .object-grid-container.image-content .object-row-container:nth-child(4n+3) .object-cell {
    float: right;
  }
}
@media (min-width:992px) {
  .object-grid-container.image-content .object-row-container:nth-child(8n+5) .object-cell,
  .object-grid-container.image-content .object-row-container:nth-child(8n+7) .object-cell {
    float: right;
  }
}
.testimonies-list {
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(2, 1fr);
  margin: 3rem 0;
}
@media (max-width:575px) {
  .testimonies-list {
    grid-template-columns: repeat(1, 1fr);
  }
}
.testimonies-list .testimony {
  background-color: #f0f0f0;
  border-radius: 1rem;
  padding: 1.5rem;
}
.testimonies-list .testimony .testimony-header {
  display: flex;
  justify-content: space-between;
}
.testimonies-list .testimony .testimony-header .star-gold {
  color: #ff8910;
}
.testimonies-list .testimony .testimony-content {
  margin: 1.5rem 0;
  text-align: justify;
}
.testimonies-list .testimony .testimony-content .category {
  font-weight: bold;
  color: #ff804b;
  margin: 1.5rem 0;
}
.testimonies-page-description-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  margin: 5rem 0;
}
.testimonies-page-description-container .image {
  display: flex;
  justify-content: center;
  width: 50%;
  margin-bottom: 5rem;
}
@media (max-width:767px) {
  .testimonies-page-description-container .image {
    width: 100%;
  }
}
.testimonies-page-description-container .image img {
  height: auto;
  max-width: 100%;
  margin: auto;
}
.testimonies-page-description-container .content {
  max-width: 50%;
}
@media (max-width:767px) {
  .testimonies-page-description-container .content {
    max-width: 100%;
  }
}
.testimonies-page-description-container .content h2 {
  display: inline-block;
  border-bottom: 2px solid #05d8c6;
}
.testimonies-page-description-container .content p {
  text-align: justify;
  margin: 2rem 0;
  line-height: 3rem;
  font-size: 1.8rem;
}
#fichechantier {
  font-size: 14px;
  line-height: 18px;
}
#fichechantier .wrapper-gris {
  background-color: #f0f0f0;
  width: 100%;
}
#fichechantier .wrapper-gris .title-fiche-chantier {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.8rem;
  line-height: 2.2rem;
  color: #474747;
}
#fichechantier .wrapper-gris .block-fiche-chantier {
  background-color: #FFF;
  border: 15px solid rgba(40, 163, 178, 0.7);
  border-radius: 10px;
  padding: 20px;
}
#fichechantier .wrapper-gris .block-fiche-chantier h3 {
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #474747;
  padding-bottom: 10px;
  border-bottom: 1px solid #cecece;
}
#fichechantier .wrapper-gris .section-button {
  margin-top: 60px;
  text-align: center;
  padding-bottom: 40px;
}
#fichechantier .wrapper-gris .ico-fiche {
  margin-top: -80px;
}
#fichechantier .wrapper-gris .sprite-fleche {
  background-position: -106px -211px;
  width: 5px;
  height: 8px;
  background-image: url('/images/habitatprestopro/sprites.png');
  background-repeat: no-repeat;
}
.tarifs-container .offer-construction-site {
  background: #FFFFFF;
}
.tarifs-container .offer-construction-site .details {
  margin-top: 3rem;
  border: 4px solid #cefbfd;
  background-color: #FFFFFF;
  padding: 0;
}
.tarifs-container .offer-construction-site .details .banner {
  background: url("/images/habitatprestopro/2017-12-08.jpg") no-repeat top center;
  height: 300px;
  background-size: cover;
}
@media screen and (max-width:575px) {
  .tarifs-container .offer-construction-site .details .banner {
    background-size: inherit;
  }
}
.tarifs-container .offer-construction-site .details .details-offer {
  padding: 2rem 8rem;
  z-index: 3;
}
@media screen and (max-width:767px) {
  .tarifs-container .offer-construction-site .details .details-offer {
    padding: 1rem 2rem;
  }
}
.tarifs-container .offer-construction-site .details .details-offer h1 {
  font-size: 3.6rem;
  line-height: 3.6rem;
  color: #474747;
  color: #28a3b2;
  text-align: center;
  padding: 3rem 0;
  text-transform: uppercase;
  margin-top: 0;
}
@media screen and (max-width:575px) {
  .tarifs-container .offer-construction-site .details .details-offer h1 {
    padding: 0;
  }
}
.tarifs-container .offer-construction-site .details .details-offer h2 {
  font-size: 2.4rem;
  line-height: 3rem;
  color: #474747;
  text-transform: uppercase;
  text-align: left;
  padding: 1rem 0;
  margin-top: 2rem;
}
.tarifs-container .offer-construction-site .details .details-offer h2:last-of-type {
  color: #28a3b2;
  text-transform: uppercase;
}
.tarifs-container .offer-construction-site .details .details-offer:first-child {
  margin-bottom: 3rem;
}
.tarifs-container .offer-construction-site .details .details-offer:last-child {
  padding: 0 8rem 4rem;
  background-color: #cefbfd;
}
@media screen and (max-width:767px) {
  .tarifs-container .offer-construction-site .details .details-offer:last-child {
    padding: 1rem 2rem;
  }
}
.tarifs-container .offer-construction-site .details .details-offer:last-child .bt-orange {
  margin-bottom: 3rem;
}
.tarifs-container .offer-construction-site .details .details-offer p {
  padding: .75rem 0;
}
.tarifs-container .offer-construction-site .details .details-offer p.checked {
  background: url("/images/habitatprestopro/check.png") no-repeat center left;
  padding-left: 2.5rem;
}
.tarifs-container .offer-construction-site .details .details-offer p.references {
  font-size: 1rem;
  line-height: 1;
  color: #727272;
  padding: 0 0 .5rem;
}
.tarifs-container .offer-construction-site .details .details-offer p a,
.tarifs-container .offer-construction-site .details .details-offer p a:hover,
.tarifs-container .offer-construction-site .details .details-offer p a:focus {
  color: #474747;
}
.tarifs-container .offer-construction-site .details .details-offer .small {
  font-size: 1rem;
}
.tarifs-container .contacts-pro {
  background: #11404d;
  padding: 20px 0;
}
.tarifs-container .contacts-pro p {
  padding: 10px 0;
  color: #FFFFFF;
}
.tarifs-container .contacts-pro p a.phone,
.tarifs-container .contacts-pro p a.email {
  font-size: 1.8rem;
  line-height: 2.2rem;
  color: #FFFFFF;
}
.tarifs-container .option-tarif .section-title {
  margin-top: 50px;
}
.tarifs-container .option-tarif .title-option {
  font-size: 2.4rem;
  line-height: 3rem;
  color: #474747;
  text-transform: uppercase;
}
.tarifs-container .option-tarif .picto-1 {
  background-image: url('/images/habitatprestopro/sprites.png');
  background-repeat: no-repeat;
  background-position: 0 -283px;
  width: 83px;
  height: 83px;
}
.tarifs-container .option-tarif .picto-2 {
  background-image: url('/images/habitatprestopro/sprites.png');
  background-repeat: no-repeat;
  background-position: -85px -285px;
  width: 83px;
  height: 83px;
}
.tarifs-container .option-tarif .picto-3 {
  background-image: url('/images/habitatprestopro/sprites.png');
  background-repeat: no-repeat;
  background-position: -171px -287px;
  width: 83px;
  height: 83px;
}
.tarifs-container .option-tarif .picto-4 {
  background-image: url('/images/habitatprestopro/sprites.png');
  background-repeat: no-repeat;
  background-position: -257px -287px;
  width: 83px;
  height: 83px;
}
.tarifs-container .option-tarif .picto-5 {
  background-image: url('/images/habitatprestopro/sprites.png');
  background-repeat: no-repeat;
  background-position: -342px -289px;
  width: 83px;
  height: 83px;
}
.tarifs-container .option-tarif .picto-6 {
  background-image: url('/images/habitatprestopro/sprites.png');
  background-repeat: no-repeat;
  background-position: -426px -289px;
  width: 83px;
  height: 83px;
}
.tarifs-container .option-tarif p {
  text-align: center;
}
.tarifs-container p {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: #474747;
}
#home {
  /*******block chantier***********/
  /******* fin block chantier***********/
}
#home .select2-container .select2-choice > .select2-chosen {
  padding: 10px 0 10px 10px !important;
}
#home .select2-container {
  display: block !important;
  font-size: 1.8rem !important;
}
#home .select2-container .select2-choice {
  height: 44px !important;
  margin-top: 15px;
  width: 100%;
  background-color: #fff;
  background-image: -moz-linear-gradient(center bottom, #fff 0%, #fff 50%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#ffffff', GradientType=0);
  background-image: linear-gradient(to top, #fff 0%, #fff 50%);
}
#home .select2-container .select2-choice .select2-arrow {
  background-image: -moz-linear-gradient(center bottom, #fff 0%, #fff 50%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#ffffff', GradientType=0);
  background-image: linear-gradient(to top, #fff 0%, #fff 50%);
  border-left: 1px solid #FFF;
}
#home .select2-container .select2-choice .select2-arrow b {
  background: url('select2.png') no-repeat 0 10px;
}
#home .select2-results .select2-no-results {
  font-size: 12px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  background: #d9edf7 !important;
  color: #3a87ad !important;
}
#home .image-wrapper.form form {
  padding: 0 10px;
}
#home .select2-container .select2-choice abbr {
  top: 15px;
}
#home .wrapper-gris {
  background-color: #f0f0f0;
  width: 100%;
}
#home .sprite-fleche {
  background-position: -124px -226px;
  width: 25px;
  height: 21px;
  background-image: url('/images/habitatprestopro/sprites.png');
  background-repeat: no-repeat;
}
#home .section-button {
  margin-top: 60px;
  text-align: center;
  padding-bottom: 40px;
}
#home .team img {
  width: 100%;
}
#home .team-bandeau {
  background-color: #28a3b2;
  width: 100%;
  height: 65px;
  color: #FFF;
  font-size: 18px;
  padding-top: 23px;
}
@media (max-width:767px) {
  #home .team-bandeau {
    padding-top: 13px;
  }
}
.select2-results .select2-no-results {
  font-size: 12px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  background: #d9edf7 !important;
  color: #3a87ad !important;
}
.pagination {
  margin: 0;
}
.pagination li.active span,
.pagination li.active a {
  color: #ff804b !important;
  border: none;
  background-color: #fff !important;
}
.pagination li.active:hover span,
.pagination li.active:hover a {
  color: #ff804b;
  border: none;
  background-color: #fff;
}
.pagination li span,
.pagination li a {
  color: #000;
  border: none;
  background-color: #fff;
}
.pagination li span:hover,
.pagination li a:hover,
.pagination li span:focus,
.pagination li a:focus {
  color: #ff804b;
  border: none;
  background-color: #fff;
}
#actualites .big-img-top {
  width: 100%;
  height: 575px;
  background: url('/images/habitatprestopro/edito.jpg') no-repeat center;
  background-size: cover;
  position: relative;
  cursor: pointer;
}
#actualites .little-img-top {
  width: 100%;
  height: 280px;
  background: url('/images/habitatprestopro/edito.jpg') no-repeat center;
  background-size: cover;
  position: relative;
  cursor: pointer;
}
#actualites .block-txt-img {
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  bottom: 10px;
  right: 10px;
  left: 10px;
  padding: 10px;
}
#actualites .block-txt-img h2 {
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #474747;
  color: #FFF;
  margin-top: 5px;
}
#actualites .block-txt-img h2 a,
#actualites .block-txt-img h2 a:hover,
#actualites .block-txt-img h2 a:active,
#actualites .block-txt-img h2 a:visited {
  color: #FFF;
  text-decoration: none;
}
#actualites .block-txt-img p {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: #474747;
  color: #FFF;
  margin-top: 5px;
}
#actualites .block-txt-img p a,
#actualites .block-txt-img p a:hover,
#actualites .block-txt-img p a:active,
#actualites .block-txt-img p a:visited {
  color: #FFF;
  text-decoration: none;
}
@media (max-width:767px) {
  #actualites .no-padding {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
}
@media (max-width:767px) {
  #actualites .pl-0 {
    padding-left: 15px!important;
  }
}
@media (max-width:767px) {
  #actualites .pr-0 {
    padding-right: 15px!important;
  }
}
#actualites .sprite-fleche {
  background-image: url('/images/habitatprestopro/sprites.png');
  background-repeat: no-repeat;
  background-position: -293px -239px;
  width: 6px;
  height: 10px;
  display: inline-block;
  margin-right: 5px;
  margin-left: 5px;
}
#actualites .block-img-article .big-img {
  width: 100%;
  height: 580px;
  background: url('/images/habitatprestopro/edito.jpg') no-repeat center;
  background-size: cover;
  cursor: pointer;
}
#actualites .block-img-article .horizontal-img {
  width: 100%;
  height: 210px;
  background: url('/images/habitatprestopro/edito.jpg') no-repeat center;
  background-size: cover;
  cursor: pointer;
}
#actualites .block-img-article .little-img {
  width: 100%;
  height: 210px;
  background: url('/images/habitatprestopro/edito.jpg') no-repeat center;
  background-size: cover;
  cursor: pointer;
}
#actualites .block-img-article h3 {
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #474747;
  margin-top: 5px;
}
#actualites .block-img-article h3 a,
#actualites .block-img-article h3 a:active,
#actualites .block-img-article h3 a:visited {
  color: #474747;
  text-decoration: none;
}
#actualites .block-img-article h3 a:hover {
  text-decoration: underline;
}
#actualites .block-img-article h3.txt-little-img {
  font-size: 24px;
  line-height: 28px;
}
#actualites .block-img-article p {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: #474747;
  margin-top: 5px;
  line-height: 24px;
  text-align: justify;
}
#actualites .block-img-article p a,
#actualites .block-img-article p a:hover,
#actualites .block-img-article p a:active,
#actualites .block-img-article p a:visited {
  color: #474747;
  text-decoration: none;
}
#actualites .chantier-mois .img-chantier {
  width: 100%;
  height: 300px;
  background: url('/images/habitatprestopro/edito.jpg') no-repeat center;
  background-size: cover;
  position: relative;
  cursor: pointer;
}
#actualites .chantier-mois .txt-img-chantier {
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  bottom: 10px;
  right: 10px;
  left: 10px;
  padding: 10px;
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #474747;
  color: #FFF;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
}
#actualites .chantier-mois .txt-img-chantier a,
#actualites .chantier-mois .txt-img-chantier a:hover,
#actualites .chantier-mois .txt-img-chantier a:visited,
#actualites .chantier-mois .txt-img-chantier a:active {
  color: #FFF;
  text-decoration: none;
}
#actualites .chantier-mois h5 {
  font-size: 2.4rem;
  line-height: 3rem;
  color: #474747;
}
#actualites .chantier-mois a h5,
#actualites .chantier-mois a h5:hover,
#actualites .chantier-mois a h5:active,
#actualites .chantier-mois a h5:visited {
  color: #474747;
  text-decoration: none;
}
#actualites .chantier-mois p {
  margin-top: 10px;
  line-height: 24px;
}
#actualites .block-hotline {
  padding: 15px;
}
#actualites .block-hotline h5 {
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #474747;
}
#actualites .block-hotline .sprite-tel {
  background-position: 0 -101px;
  width: 106px;
  height: 106px;
  background-image: url('/images/habitatprestopro/sprites.png');
  background-repeat: no-repeat;
}
#actualites .block-hotline .tel {
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #474747;
  font-weight: bold;
  color: #28a3b2;
  line-height: 45px;
}
#actualites .reference-quotation {
  background-color: #cefbfd;
  padding: 20px;
  width: 100%;
  height: 300px;
  text-align: justify;
}
@media (max-width:1199px) {
  #actualites .reference-quotation {
    height: 350px;
  }
}
@media (max-width:991px) {
  #actualites .reference-quotation {
    height: auto;
  }
}
#actualites .reference-quotation .qmark {
  margin: 10px auto 0 auto;
  background-image: url('/images/habitatprestopro/sprites.png');
  background-repeat: no-repeat;
  background-position: 0 -170px;
  width: 47px;
  height: 46px;
}
#actualites .reference-quotation .block-text {
  height: 230px;
}
@media (max-width:1199px) {
  #actualites .reference-quotation .block-text {
    height: 300px;
  }
}
@media (max-width:991px) {
  #actualites .reference-quotation .block-text {
    height: auto;
  }
}
#actualites .reference-quotation .text {
  background-image: url('/images/habitatprestopro/guillemet-haut.png'), url('/images/habitatprestopro/guillemet-bas.png');
  background-repeat: no-repeat;
  background-position: left top, right bottom;
  line-height: 24px;
  padding: 15px;
  text-indent: 3em;
}
@media (max-width:767px) {
  #actualites .reference-quotation .text {
    font-size: 1.5rem;
  }
}
#actualites .community h5 {
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #474747;
}
#actualites .community .facebook {
  background-image: url('/images/habitatprestopro/sprites.png');
  background-repeat: no-repeat;
  background-position: -323px -115px;
  width: 44px;
  height: 42px;
}
#actualites .community .twitter {
  background-image: url('/images/habitatprestopro/sprites.png');
  background-repeat: no-repeat;
  background-position: -370px -115px;
  width: 42px;
  height: 42px;
}
#actualites-rubrique .sprite-fleche-grise {
  background-image: url('/images/habitatprestopro/sprites.png');
  background-repeat: no-repeat;
  background-position: -293px -215px;
  width: 7px;
  height: 11px;
  margin-right: 5px;
  margin-left: 5px;
}
#actualites-rubrique .fil-ariane a,
#actualites-rubrique .fil-ariane a:visited {
  color: #474747;
  line-height: 18px;
}
#actualites-rubrique .fil-ariane a:hover {
  text-decoration: underline;
}
#actualites-rubrique .big-img-top {
  width: 100%;
  height: 575px;
  background: url('/images/habitatprestopro/edito.jpg') no-repeat center;
  background-size: cover;
  position: relative;
  cursor: pointer;
}
#actualites-rubrique .little-img-top {
  width: 100%;
  height: 280px;
  background: url('/images/habitatprestopro/edito.jpg') no-repeat center;
  background-size: cover;
  position: relative;
  cursor: pointer;
}
#actualites-rubrique .block-txt-img {
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  bottom: 10px;
  right: 10px;
  left: 10px;
  padding: 10px;
}
#actualites-rubrique .block-txt-img h2 {
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #474747;
  color: #FFF;
  margin-top: 5px;
}
#actualites-rubrique .block-txt-img h2 a,
#actualites-rubrique .block-txt-img h2 a:hover,
#actualites-rubrique .block-txt-img h2 a:active,
#actualites-rubrique .block-txt-img h2 a:visited {
  color: #FFF;
  text-decoration: none;
}
#actualites-rubrique .block-txt-img p {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: #474747;
  color: #FFF;
  margin-top: 5px;
}
#actualites-rubrique .block-txt-img p a,
#actualites-rubrique .block-txt-img p a:hover,
#actualites-rubrique .block-txt-img p a:active,
#actualites-rubrique .block-txt-img p a:visited {
  color: #FFF;
  text-decoration: none;
}
@media (max-width:767px) {
  #actualites-rubrique .no-padding {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
}
@media (max-width:767px) {
  #actualites-rubrique .pl-0 {
    padding-left: 15px !important;
  }
}
@media (max-width:767px) {
  #actualites-rubrique .pr-0 {
    padding-right: 15px !important;
  }
}
#actualites-rubrique .sprite-fleche {
  background-image: url('/images/habitatprestopro/sprites.png');
  background-repeat: no-repeat;
  background-position: -293px -239px;
  width: 6px;
  height: 10px;
  display: inline-block;
  margin-right: 5px;
  margin-left: 5px;
}
#actualites-rubrique .block-img-article .big-img {
  width: 100%;
  height: 580px;
  background: url('/images/habitatprestopro/edito.jpg') no-repeat center;
  background-size: cover;
  cursor: pointer;
}
#actualites-rubrique .block-img-article .horizontal-img {
  width: 100%;
  height: 210px;
  background: url('/images/habitatprestopro/edito.jpg') no-repeat center;
  background-size: cover;
  cursor: pointer;
}
#actualites-rubrique .block-img-article .little-img {
  width: 100%;
  height: 210px;
  background: url('/images/habitatprestopro/edito.jpg') no-repeat center;
  background-size: cover;
  cursor: pointer;
}
#actualites-rubrique .block-img-article .vertical-img {
  width: 100%;
  height: 580px;
  background: url('/images/habitatprestopro/edito.jpg') no-repeat center;
  background-size: cover;
}
#actualites-rubrique .block-img-article .block-txt {
  height: 115px;
}
#actualites-rubrique .block-img-article h3 {
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #474747;
  margin-top: 5px;
  font-size: 24px;
  line-height: 28px;
}
#actualites-rubrique .block-img-article h3 a,
#actualites-rubrique .block-img-article h3 a:active,
#actualites-rubrique .block-img-article h3 a:visited {
  color: #474747;
  text-decoration: none;
}
#actualites-rubrique .block-img-article h3 a:hover {
  text-decoration: underline;
}
#actualites-rubrique .block-img-article p {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: #474747;
  margin-top: 5px;
  line-height: 24px;
  text-align: justify;
}
#actualites-rubrique .block-img-article p a,
#actualites-rubrique .block-img-article p a:hover,
#actualites-rubrique .block-img-article p a:active,
#actualites-rubrique .block-img-article p a:visited {
  color: #474747;
  text-decoration: none;
}
#actualites-rubrique .block-img-article .sprite-fleche-gris {
  background-image: url('/images/habitatprestopro/sprites.png');
  background-repeat: no-repeat;
  background-position: -293px -215px;
  width: 7px;
  height: 11px;
  margin-right: 5px;
}
#actualites-rubrique .block-img-article .bold {
  font-weight: bold;
}
#actualites-rubrique .block-img-article .block-little-img {
  height: 115px;
}
#actualites-rubrique .block-img-article .block-little-img h3 a {
  font-size: 18px;
  line-height: 20px !important;
}
#actualites-rubrique .chantier-mois .img-chantier {
  width: 100%;
  height: 300px;
  background: url('/images/habitatprestopro/edito.jpg') no-repeat center;
  background-size: cover;
  position: relative;
}
#actualites-rubrique .chantier-mois .txt-img-chantier {
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  bottom: 10px;
  right: 10px;
  left: 10px;
  padding: 10px;
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #474747;
  color: #FFF;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
}
#actualites-rubrique .chantier-mois h5 {
  font-size: 2.4rem;
  line-height: 3rem;
  color: #474747;
}
#actualites-rubrique .chantier-mois p {
  margin-top: 10px;
  line-height: 24px;
}
#actualites-rubrique .block-hotline {
  padding: 15px;
}
#actualites-rubrique .block-hotline h5 {
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #474747;
}
#actualites-rubrique .block-hotline .sprite-tel {
  background-position: 0 -101px;
  width: 106px;
  height: 106px;
  background-image: url('/images/habitatprestopro/sprites.png');
  background-repeat: no-repeat;
}
#actualites-rubrique .block-hotline .tel {
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #474747;
  font-weight: bold;
  color: #28a3b2;
  line-height: 45px;
}
#actualites-rubrique .reference-quotation {
  background-color: #cefbfd;
  padding: 20px;
  width: 100%;
  height: 300px;
  text-align: justify;
}
@media (max-width:1199px) {
  #actualites-rubrique .reference-quotation {
    height: 350px;
  }
}
@media (max-width:991px) {
  #actualites-rubrique .reference-quotation {
    height: auto;
  }
}
#actualites-rubrique .reference-quotation .qmark {
  margin: 10px auto 0 auto;
  background-image: url('/images/habitatprestopro/sprites.png');
  background-repeat: no-repeat;
  background-position: 0 -170px;
  width: 47px;
  height: 46px;
}
#actualites-rubrique .reference-quotation .block-text {
  height: 230px;
}
@media (max-width:1199px) {
  #actualites-rubrique .reference-quotation .block-text {
    height: 300px;
  }
}
@media (max-width:991px) {
  #actualites-rubrique .reference-quotation .block-text {
    height: auto;
  }
}
#actualites-rubrique .reference-quotation .text {
  background-image: url('/images/habitatprestopro/guillemet-haut.png'), url('/images/habitatprestopro/guillemet-bas.png');
  background-repeat: no-repeat;
  background-position: left top, right bottom;
  line-height: 24px;
  padding: 15px;
  text-indent: 3em;
}
@media (max-width:767px) {
  #actualites-rubrique .reference-quotation .text {
    font-size: 1.5rem;
  }
}
#actualites-rubrique .community h5 {
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #474747;
}
#actualites-rubrique .community .facebook {
  background-image: url('/images/habitatprestopro/sprites.png');
  background-repeat: no-repeat;
  background-position: -323px -115px;
  width: 44px;
  height: 42px;
}
#actualites-rubrique .community .twitter {
  background-image: url('/images/habitatprestopro/sprites.png');
  background-repeat: no-repeat;
  background-position: -370px -115px;
  width: 42px;
  height: 42px;
}
#actualites-ss-rubrique .sprite-fleche-grise {
  background-image: url('/images/habitatprestopro/sprites.png');
  background-repeat: no-repeat;
  background-position: -293px -215px;
  width: 7px;
  height: 11px;
  margin-right: 5px;
  margin-left: 5px;
}
#actualites-ss-rubrique .fil-ariane a,
#actualites-ss-rubrique .fil-ariane a:visited {
  color: #474747;
  line-height: 18px;
}
#actualites-ss-rubrique .fil-ariane a:hover {
  text-decoration: underline;
}
#actualites-ss-rubrique .big-img-top {
  width: 100%;
  height: 575px;
  background: url('/images/habitatprestopro/edito.jpg') no-repeat center;
  background-size: cover;
  position: relative;
  cursor: pointer;
}
#actualites-ss-rubrique .little-img-top {
  width: 100%;
  height: 280px;
  background: url('/images/habitatprestopro/edito.jpg') no-repeat center;
  background-size: cover;
  position: relative;
  cursor: pointer;
}
#actualites-ss-rubrique .block-txt-img {
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  bottom: 10px;
  right: 10px;
  left: 10px;
  padding: 10px;
}
#actualites-ss-rubrique .block-txt-img h2 {
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #474747;
  color: #FFF;
  margin-top: 5px;
}
#actualites-ss-rubrique .block-txt-img h2 a,
#actualites-ss-rubrique .block-txt-img h2 a:hover,
#actualites-ss-rubrique .block-txt-img h2 a:active,
#actualites-ss-rubrique .block-txt-img h2 a:visited {
  color: #FFF;
  text-decoration: none;
}
#actualites-ss-rubrique .block-txt-img p {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: #474747;
  color: #FFF;
  margin-top: 5px;
}
#actualites-ss-rubrique .block-txt-img p a,
#actualites-ss-rubrique .block-txt-img p a:hover,
#actualites-ss-rubrique .block-txt-img p a:active,
#actualites-ss-rubrique .block-txt-img p a:visited {
  color: #FFF;
  text-decoration: none;
}
#actualites-ss-rubrique .block-txt-img .date {
  color: #FFF;
  font-size: 12px;
}
@media (max-width:767px) {
  #actualites-ss-rubrique .no-padding {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
}
@media (max-width:767px) {
  #actualites-ss-rubrique .pl-0 {
    padding-left: 15px !important;
  }
}
@media (max-width:767px) {
  #actualites-ss-rubrique .pr-0 {
    padding-right: 15px !important;
  }
}
#actualites-ss-rubrique .sprite-fleche {
  background-image: url('/images/habitatprestopro/sprites.png');
  background-repeat: no-repeat;
  background-position: -293px -239px;
  width: 6px;
  height: 10px;
  display: inline-block;
  margin-right: 5px;
  margin-left: 5px;
}
#actualites-ss-rubrique .block-img-article .big-img {
  width: 100%;
  height: 580px;
  background: url('/images/habitatprestopro/edito.jpg') no-repeat center;
  background-size: cover;
  cursor: pointer;
}
#actualites-ss-rubrique .block-img-article .horizontal-img {
  width: 100%;
  height: 210px;
  background: url('/images/habitatprestopro/edito.jpg') no-repeat center;
  background-size: cover;
  cursor: pointer;
}
#actualites-ss-rubrique .block-img-article .little-img {
  width: 100%;
  height: 210px;
  background: url('/images/habitatprestopro/edito.jpg') no-repeat center;
  background-size: cover;
  cursor: pointer;
}
#actualites-ss-rubrique .block-img-article .vertical-img {
  width: 100%;
  height: 580px;
  background: url('/images/habitatprestopro/edito.jpg') no-repeat center;
  background-size: cover;
  cursor: pointer;
}
#actualites-ss-rubrique .block-img-article h3 {
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #474747;
  margin-top: 5px;
  font-size: 24px;
  line-height: 28px;
}
#actualites-ss-rubrique .block-img-article h3 a,
#actualites-ss-rubrique .block-img-article h3 a:active,
#actualites-ss-rubrique .block-img-article h3 a:visited {
  color: #474747;
  text-decoration: none;
}
#actualites-ss-rubrique .block-img-article h3 a:hover {
  text-decoration: underline;
}
#actualites-ss-rubrique .block-img-article p {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: #474747;
  margin-top: 5px;
  line-height: 24px;
  text-align: justify;
}
#actualites-ss-rubrique .block-img-article p a,
#actualites-ss-rubrique .block-img-article p a:hover,
#actualites-ss-rubrique .block-img-article p a:active,
#actualites-ss-rubrique .block-img-article p a:visited {
  color: #474747;
  text-decoration: none;
}
#actualites-ss-rubrique .block-img-article .date {
  color: #727272;
  font-size: 12px;
}
#actualites-ss-rubrique .chantier-mois .img-chantier {
  width: 100%;
  height: 300px;
  background: url('/images/habitatprestopro/edito.jpg') no-repeat center;
  background-size: cover;
  position: relative;
}
#actualites-ss-rubrique .chantier-mois .txt-img-chantier {
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  bottom: 10px;
  right: 10px;
  left: 10px;
  padding: 10px;
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #474747;
  color: #FFF;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
}
#actualites-ss-rubrique .chantier-mois h5 {
  font-size: 2.4rem;
  line-height: 3rem;
  color: #474747;
}
#actualites-ss-rubrique .chantier-mois p {
  margin-top: 10px;
  line-height: 24px;
}
#actualites-ss-rubrique .block-hotline {
  padding: 15px;
}
#actualites-ss-rubrique .block-hotline h5 {
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #474747;
}
#actualites-ss-rubrique .block-hotline .sprite-tel {
  background-position: 0 -101px;
  width: 106px;
  height: 106px;
  background-image: url('/images/habitatprestopro/sprites.png');
  background-repeat: no-repeat;
}
#actualites-ss-rubrique .block-hotline .tel {
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #474747;
  font-weight: bold;
  color: #28a3b2;
  line-height: 45px;
}
#actualites-ss-rubrique .reference-quotation {
  background-color: #cefbfd;
  padding: 20px;
  width: 100%;
  height: 300px;
  text-align: justify;
}
@media (max-width:1199px) {
  #actualites-ss-rubrique .reference-quotation {
    height: 350px;
  }
}
@media (max-width:991px) {
  #actualites-ss-rubrique .reference-quotation {
    height: auto;
  }
}
#actualites-ss-rubrique .reference-quotation .qmark {
  margin: 10px auto 0 auto;
  background-image: url('/images/habitatprestopro/sprites.png');
  background-repeat: no-repeat;
  background-position: 0 -170px;
  width: 47px;
  height: 46px;
}
#actualites-ss-rubrique .reference-quotation .block-text {
  height: 230px;
}
@media (max-width:1199px) {
  #actualites-ss-rubrique .reference-quotation .block-text {
    height: 300px;
  }
}
@media (max-width:991px) {
  #actualites-ss-rubrique .reference-quotation .block-text {
    height: auto;
  }
}
#actualites-ss-rubrique .reference-quotation .text {
  background-image: url('/images/habitatprestopro/guillemet-haut.png'), url('/images/habitatprestopro/guillemet-bas.png');
  background-repeat: no-repeat;
  background-position: left top, right bottom;
  line-height: 24px;
  padding: 15px;
  text-indent: 3em;
}
@media (max-width:767px) {
  #actualites-ss-rubrique .reference-quotation .text {
    font-size: 1.5rem;
  }
}
#actualites-ss-rubrique .community h5 {
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #474747;
}
#actualites-ss-rubrique .community .facebook {
  background-image: url('/images/habitatprestopro/sprites.png');
  background-repeat: no-repeat;
  background-position: -323px -115px;
  width: 44px;
  height: 42px;
}
#actualites-ss-rubrique .community .twitter {
  background-image: url('/images/habitatprestopro/sprites.png');
  background-repeat: no-repeat;
  background-position: -370px -115px;
  width: 42px;
  height: 42px;
}
#actualite-article {
  /****fin form vertical****/
}
#actualite-article h2 {
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #474747;
}
#actualite-article h3 {
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #474747;
  color: #28a3b2;
  margin-top: 15px;
  margin-bottom: 10px;
}
#actualite-article p {
  text-align: justify;
  line-height: 24px;
}
#actualite-article img {
  width: 100%;
}
#actualite-article .bold {
  font-weight: bold;
}
#actualite-article .sprite-fleche-grise {
  background-image: url('/images/habitatprestopro/sprites.png');
  background-repeat: no-repeat;
  background-position: -293px -215px;
  width: 7px;
  height: 11px;
  margin-right: 5px;
  margin-left: 5px;
}
#actualite-article .fil-ariane a,
#actualite-article .fil-ariane a:visited {
  color: #474747;
  line-height: 18px;
}
#actualite-article .fil-ariane a:hover {
  text-decoration: underline;
}
#actualite-article .partage-facebook {
  background-image: url('/images/habitatprestopro/sprites.png');
  background-repeat: no-repeat;
  background-position: -685px -172px;
  width: 51px;
  height: 50px;
  margin-top: 10px;
}
#actualite-article .partage-twitter {
  background-image: url('/images/habitatprestopro/sprites.png');
  background-repeat: no-repeat;
  background-position: -745px -172px;
  width: 50px;
  height: 50px;
  margin-top: 10px;
}
#actualite-article .partage-google {
  background-image: url('/images/habitatprestopro/sprites.png');
  background-repeat: no-repeat;
  background-position: -802px -173px;
  width: 51px;
  height: 50px;
  margin-top: 10px;
}
#actualite-article .bulle {
  background-image: url('/images/habitatprestopro/sprites.png');
  background-repeat: no-repeat;
  background-position: -678px -227px;
  width: 57px;
  height: 51px;
  margin-right: 10px;
  text-align: center;
  margin-top: 10px;
}
#actualite-article .community {
  display: inline-block;
}
#actualite-article .bt-partage {
  border: 1px solid #28a3b2;
  border-radius: 2px;
  display: inline-block;
  vertical-align: middle;
  width: 50px;
  height: 50px;
  margin-top: 10px;
}
#actualite-article .bt-partage:hover {
  background-color: #28a3b2;
}
#actualite-article .sprite-mail {
  background-image: url('/images/habitatprestopro/sprites.png');
  background-repeat: no-repeat;
  background-position: -779px -235px;
  width: 30px;
  height: 22px;
  margin-top: 13px;
  margin-left: 9px;
}
#actualite-article .bt-partage:hover .sprite-mail {
  background-image: url('/images/habitatprestopro/sprites.png');
  background-repeat: no-repeat;
  background-position: -779px -262px;
  width: 30px;
  height: 22px;
}
#actualite-article .sprite-imprim {
  background-image: url('/images/habitatprestopro/sprites.png');
  background-repeat: no-repeat;
  background-position: -815px -230px;
  width: 32px;
  height: 33px;
  margin-top: 8px;
  margin-left: 8px;
}
#actualite-article .bt-partage:hover .sprite-imprim {
  background-image: url('/images/habitatprestopro/sprites.png');
  background-repeat: no-repeat;
  background-position: -816px -266px;
  width: 32px;
  height: 33px;
}
#actualite-article .nb-partage {
  margin-top: 19px;
  display: inline-block;
}
#actualite-article .bt-fleche-article {
  border: 1px solid #28a3b2;
  border-radius: 2px;
  display: inline-block;
  vertical-align: middle;
  width: 50px;
  height: 50px;
  float: left;
  margin-bottom: 20px;
  cursor: pointer;
}
#actualite-article .bt-fleche-article:hover {
  background-color: #28a3b2;
}
#actualite-article .precedent {
  background-image: url('/images/habitatprestopro/sprites.png');
  background-repeat: no-repeat;
  background-position: -307px -236px;
  width: 8px;
  height: 15px;
  margin-top: 16px;
  margin-right: auto;
  margin-left: auto;
}
#actualite-article .bt-fleche-article:hover .precedent {
  background-image: url('/images/habitatprestopro/sprites.png');
  background-repeat: no-repeat;
  background-position: -370px -233px;
  width: 8px;
  height: 15px;
}
#actualite-article .suivant {
  background-image: url('/images/habitatprestopro/sprites.png');
  background-repeat: no-repeat;
  background-position: -319px -236px;
  width: 9px;
  height: 14px;
  margin-top: 16px;
  margin-right: auto;
  margin-left: auto;
}
#actualite-article .bt-fleche-article:hover .suivant {
  background-image: url('/images/habitatprestopro/sprites.png');
  background-repeat: no-repeat;
  background-position: -351px -235px;
  width: 9px;
  height: 14px;
}
#actualite-article .title-article-precedent {
  float: left;
  color: #28a3b2;
  margin-left: 10px;
  margin-top: 10px;
}
#actualite-article .title-article-suivant {
  float: left;
  color: #28a3b2;
  margin-right: 10px;
  text-align: right;
  margin-top: 10px;
}
#actualite-article .auteur {
  margin-top: 45px;
}
#actualite-article .sprite-fleche {
  background-image: url('/images/habitatprestopro/sprites.png');
  background-repeat: no-repeat;
  background-position: -293px -239px;
  width: 6px;
  height: 10px;
  display: inline-block;
  margin-right: 5px;
  margin-left: 5px;
}
#actualites-recherche .barre-recherche {
  background-color: #082b37;
  width: 100%;
}
#actualites-recherche .barre-recherche form {
  padding: 10px;
  width: 100%;
  height: 100%;
  display: table;
}
@media (min-width:768px) {
  #actualites-recherche .barre-recherche form > div {
    display: table-cell;
    vertical-align: middle;
    height: 100%;
    float: none;
  }
}
#actualites-recherche .barre-recherche form #form-select-container {
  padding-left: 0;
  padding-right: 10px;
}
#actualites-recherche .barre-recherche form #form-select-container select {
  font-size: 1.8rem;
  height: 45px;
}
#actualites-recherche .barre-recherche form #form-select-container input {
  font-size: 1.8rem;
  height: 45px;
}
#actualites-recherche .barre-recherche form #form-select-container optgroup {
  font-weight: bold;
}
#actualites-recherche .barre-recherche #form-submit-container {
  padding: 0;
  margin-top: 10px;
}
@media (min-width:768px) {
  #actualites-recherche .barre-recherche #form-submit-container {
    margin: 0;
    padding: 0;
  }
}
#actualites-recherche .barre-recherche #form-submit-container button {
  font-size: 1.8rem;
  color: white;
  border: 1px solid #ff804b;
  font-weight: bold;
  background-color: #ff804b;
  transition: 0.3s;
  border-radius: 0.8rem 0.8rem 0.8rem 0.8rem;
  padding: 10px 20px;
  width: 100%;
  min-height: 45px;
}
#actualites-recherche .barre-recherche #form-submit-container button:hover {
  color: white;
  border: 1px solid #e77544;
  font-weight: bold;
  background-color: #e77544;
  transition: 0.3s;
  border-radius: 0.8rem 0.8rem 0.8rem 0.8rem;
}
#actualites-recherche .barre-recherche .sprite-search {
  background-position: -254px -219px;
  width: 25px;
  height: 24px;
  background-image: url('/images/habitatprestopro/sprites.png');
  background-repeat: no-repeat;
}
@media (max-width:767px) {
  #actualites-recherche .select-recherche .control-label {
    margin-bottom: 5px;
  }
}
#actualites-recherche .bold {
  font-weight: bold;
}
#actualites-recherche .sprite-fleche-grise {
  background-image: url('/images/habitatprestopro/sprites.png');
  background-repeat: no-repeat;
  background-position: -293px -215px;
  width: 7px;
  height: 11px;
  margin-right: 5px;
  margin-left: 5px;
}
#actualites-recherche .fil-ariane a,
#actualites-recherche .fil-ariane a:visited {
  color: #474747;
  line-height: 18px;
}
#actualites-recherche .fil-ariane a:hover {
  text-decoration: underline;
}
#actualites-recherche .big-img-top {
  width: 100%;
  height: 575px;
  background: url('/images/habitatprestopro/edito.jpg') no-repeat center;
  background-size: cover;
  position: relative;
}
#actualites-recherche .little-img-top {
  width: 100%;
  height: 280px;
  background: url('/images/habitatprestopro/edito.jpg') no-repeat center;
  background-size: cover;
  position: relative;
}
#actualites-recherche .block-txt-img {
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  bottom: 10px;
  right: 10px;
  left: 10px;
  padding: 10px;
}
#actualites-recherche .block-txt-img h2 {
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #474747;
  color: #FFF;
  margin-top: 5px;
}
#actualites-recherche .block-txt-img h2 a,
#actualites-recherche .block-txt-img h2 a:hover,
#actualites-recherche .block-txt-img h2 a:active,
#actualites-recherche .block-txt-img h2 a:visited {
  color: #FFF;
  text-decoration: none;
}
#actualites-recherche .block-txt-img p {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: #474747;
  color: #FFF;
  margin-top: 5px;
}
#actualites-recherche .block-txt-img p a,
#actualites-recherche .block-txt-img p a:hover,
#actualites-recherche .block-txt-img p a:active,
#actualites-recherche .block-txt-img p a:visited {
  color: #FFF;
  text-decoration: none;
}
#actualites-recherche .block-txt-img .date {
  color: #FFF;
  font-size: 12px;
}
#actualites-recherche .bt-pagination {
  border: 1px solid #c9c9c9;
  padding: 15px;
  float: left;
  margin-left: 10px;
}
#actualites-recherche .bt-pagination.active {
  border: 1px solid #ff580c;
  color: #ff580c;
}
#actualites-recherche .bt-pagination:hover {
  border: 1px solid #ff580c;
  color: #fff;
  background-color: #ff580c;
}
#actualites-recherche .fleche-suivant {
  background-image: url('/images/habitatprestopro/sprites.png');
  background-repeat: no-repeat;
  background-position: -341px -237px;
  width: 9px;
  height: 14px;
}
#actualites-recherche .fleche-precedent {
  background-image: url('/images/habitatprestopro/sprites.png');
  background-repeat: no-repeat;
  background-position: -332px -236px;
  width: 8px;
  height: 15px;
}
#actualites-recherche .bt-pagination:hover .fleche-suivant {
  background-image: url('/images/habitatprestopro/sprites.png');
  background-repeat: no-repeat;
  background-position: -351px -235px;
  width: 9px;
  height: 14px;
}
#actualites-recherche .bt-pagination:hover .fleche-precedent {
  background-image: url('/images/habitatprestopro/sprites.png');
  background-repeat: no-repeat;
  background-position: -370px -233px;
  width: 8px;
  height: 15px;
}
@media (max-width:767px) {
  #actualites-recherche .no-padding {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
}
@media (max-width:767px) {
  #actualites-recherche .pl-0 {
    padding-left: 15px !important;
  }
}
@media (max-width:767px) {
  #actualites-recherche .pr-0 {
    padding-right: 15px !important;
  }
}
#actualites-recherche .sprite-fleche {
  background-image: url('/images/habitatprestopro/sprites.png');
  background-repeat: no-repeat;
  background-position: -293px -239px;
  width: 6px;
  height: 10px;
  display: inline-block;
  margin-right: 5px;
}
#actualites-recherche .block-img-article .big-img {
  width: 100%;
  height: 580px;
  background: url('/images/habitatprestopro/edito.jpg') no-repeat center;
  background-size: cover;
}
#actualites-recherche .block-img-article .horizontal-img {
  width: 100%;
  height: 210px;
  background: url('/images/habitatprestopro/edito.jpg') no-repeat center;
  background-size: cover;
}
#actualites-recherche .block-img-article .little-img {
  width: 100%;
  height: 210px;
  background: url('/images/habitatprestopro/edito.jpg') no-repeat center;
  background-size: cover;
}
#actualites-recherche .block-img-article .vertical-img {
  width: 100%;
  height: 580px;
  background: url('/images/habitatprestopro/edito.jpg') no-repeat center;
  background-size: cover;
}
#actualites-recherche .block-img-article h3 {
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #474747;
  margin-top: 5px;
  font-size: 24px;
  line-height: 28px;
}
#actualites-recherche .block-img-article h3 a,
#actualites-recherche .block-img-article h3 a:active,
#actualites-recherche .block-img-article h3 a:visited {
  color: #474747;
  text-decoration: none;
}
#actualites-recherche .block-img-article h3 a:hover {
  text-decoration: underline;
}
#actualites-recherche .block-img-article p {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: #474747;
  margin-top: 5px;
  line-height: 24px;
  text-align: justify;
}
#actualites-recherche .block-img-article p a,
#actualites-recherche .block-img-article p a:hover,
#actualites-recherche .block-img-article p a:active,
#actualites-recherche .block-img-article p a:visited {
  color: #474747;
  text-decoration: none;
}
#actualites-recherche .block-img-article .date {
  color: #727272;
  font-size: 12px;
}
#actualites-recherche .chantier-mois .img-chantier {
  width: 100%;
  height: 300px;
  background: url('/images/habitatprestopro/edito.jpg') no-repeat center;
  background-size: cover;
  position: relative;
}
#actualites-recherche .chantier-mois .txt-img-chantier {
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  bottom: 10px;
  right: 10px;
  left: 10px;
  padding: 10px;
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #474747;
  color: #FFF;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
}
#actualites-recherche .chantier-mois h5 {
  font-size: 2.4rem;
  line-height: 3rem;
  color: #474747;
}
#actualites-recherche .chantier-mois p {
  margin-top: 10px;
  line-height: 24px;
}
#actualites-recherche .block-hotline {
  padding: 15px;
}
#actualites-recherche .block-hotline h5 {
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #474747;
}
#actualites-recherche .block-hotline .sprite-tel {
  background-position: 0 -101px;
  width: 106px;
  height: 106px;
  background-image: url('/images/habitatprestopro/sprites.png');
  background-repeat: no-repeat;
}
#actualites-recherche .block-hotline .tel {
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #474747;
  font-weight: bold;
  color: #28a3b2;
  line-height: 45px;
}
#actualites-recherche .reference-quotation {
  background-color: #cefbfd;
  padding: 20px;
  width: 100%;
  height: 300px;
  text-align: justify;
}
@media (max-width:1199px) {
  #actualites-recherche .reference-quotation {
    height: 350px;
  }
}
@media (max-width:991px) {
  #actualites-recherche .reference-quotation {
    height: auto;
  }
}
#actualites-recherche .reference-quotation .qmark {
  margin: 10px auto 0 auto;
  background-image: url('/images/habitatprestopro/sprites.png');
  background-repeat: no-repeat;
  background-position: 0 -170px;
  width: 47px;
  height: 46px;
}
#actualites-recherche .reference-quotation .block-text {
  height: 230px;
}
@media (max-width:1199px) {
  #actualites-recherche .reference-quotation .block-text {
    height: 300px;
  }
}
@media (max-width:991px) {
  #actualites-recherche .reference-quotation .block-text {
    height: auto;
  }
}
#actualites-recherche .reference-quotation .text {
  background-image: url('/images/habitatprestopro/guillemet-haut.png'), url('/images/habitatprestopro/guillemet-bas.png');
  background-repeat: no-repeat;
  background-position: left top, right bottom;
  line-height: 24px;
  padding: 15px;
  text-indent: 3em;
}
@media (max-width:767px) {
  #actualites-recherche .reference-quotation .text {
    font-size: 1.5rem;
  }
}
#actualites-recherche .community h5 {
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #474747;
}
#actualites-recherche .community .facebook {
  background-image: url('/images/habitatprestopro/sprites.png');
  background-repeat: no-repeat;
  background-position: -323px -115px;
  width: 44px;
  height: 42px;
}
#actualites-recherche .community .twitter {
  background-image: url('/images/habitatprestopro/sprites.png');
  background-repeat: no-repeat;
  background-position: -370px -115px;
  width: 42px;
  height: 42px;
}
#error .bg-404 {
  background: url('/images/habitatprestopro/404-1.jpg') no-repeat center;
  /* pour anciens Chrome et Safari */
  background-size: cover;
  /* version standardisée*/
  height: 800px;
}
#error .txt-404 {
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #474747;
  /*text-transform:uppercase;*/
  font-weight: bold;
}
#error .ml-30 {
  margin-left: 30px;
}
@media (max-width:767px) {
  #error .ml-30 {
    margin-top: 180px;
  }
}
#login .object-row-container {
  margin-top: 30px;
}
#login .object-row-container:nth-child(4n+1) > * {
  float: right;
}
#login .object-row-container .image-cell {
  height: 267px;
}
#login .object-row-container .image-cell img {
  display: block;
  margin: 0 auto;
}
#login .object-row-container .html-cell .positioner {
  height: 267px;
  display: table-cell;
  vertical-align: middle;
}
#login .object-row-container .html-cell .positioner .index {
  font-size: 1.8rem;
  line-height: 2.2rem;
  display: inline-block;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  background-color: #05d8c6;
  text-align: center;
  line-height: 33px;
  color: white;
  vertical-align: super;
  font-weight: bold;
  margin-right: 10px;
}
#login .object-row-container .html-cell .positioner .title {
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #474747;
}
#login .object-row-container .html-cell .positioner ul {
  line-height: 30px;
}
#login .object-row-container .html-cell .positioner ul li {
  background-image: url('/images/habitatpresto/fleche-noire.png');
  background-repeat: no-repeat;
  background-position: 0 10px;
  padding: 0 20px;
  margin-left: 13px;
  margin-bottom: 35px;
}
#login .object-row-container .html-cell .positioner ul li ul li {
  background-image: url('/images/habitatpresto/puceCheck.jpg');
  background-repeat: no-repeat;
  background-position: 0 10px;
  padding: 0 0 0 30px;
  margin-bottom: 35px;
}
#login .title-login {
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #474747;
  margin-right: -15px;
}
#login .background-2 {
  background-image: url("/images/habitatpresto/quisommesnous.jpg");
}
#login .background-3 {
  background-image: url("/images/habitatpresto/team.png");
}
#login .wrapper-img-1 {
  display: table;
  background-repeat: no-repeat;
  width: 100%;
  height: 475px;
}
#login .entete {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.8rem;
  line-height: 2.2rem;
  color: #474747;
  line-height: 24px;
}
#login .title-page {
  font-size: 3.6rem;
  line-height: 3.6rem;
  color: #474747;
  font-weight: bold;
}
#login .mt-60 {
  margin-top: 60px !important;
}
#login .ml-150 {
  margin-left: -150px !important;
}
#login .block-login {
  border: 1px solid #979797;
  border-radius: 1rem;
  -moz-border-radius: 1rem;
  -webkit-border-radius: 1rem;
  padding: 40px 20px;
  margin-bottom: 20px;
}
#login .block-login p {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.8rem;
  line-height: 2.2rem;
  color: #474747;
  margin-top: 10px;
  margin-bottom: 20px;
}
#login .block-inscription {
  border: 1px solid #979797;
  border-radius: 1rem;
  -moz-border-radius: 1rem;
  -webkit-border-radius: 1rem;
  padding: 40px 20px;
  margin-bottom: 20px;
}
#login .block-inscription p {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.8rem;
  line-height: 2.2rem;
  color: #474747;
  margin-top: 10px;
  margin-bottom: 20px;
}
#login .block-inscription .form-group {
  margin-top: 50px;
}
@media (max-width:1199px) {
  #login .block-inscription .form-group {
    margin-top: 40px;
  }
}
@media (max-width:767px) {
  #login .block-inscription .form-group {
    margin-top: 20px;
  }
}
#login .block-inscription button {
  margin-top: 80px;
}
@media (max-width:1199px) {
  #login .block-inscription button {
    margin-top: 55px;
  }
}
#login .msg-error {
  background-color: #ffd4d4;
  padding: 10px;
  border: 1px solid #fdc8c8;
  color: #b94a48;
  display: block;
  width: 100%;
  margin-top: 10px;
}
#login .sprite-mail {
  background-image: url('/images/habitatprestopro/sprite-profile.png');
  background-repeat: no-repeat;
  background-position: -323px 0;
  width: 28px;
  height: 19px;
  display: inline-block;
  margin-top: 12px;
  margin-left: 10px;
}
#login .sprite-password {
  background-image: url('/images/habitatprestopro/sprite-profile.png');
  background-repeat: no-repeat;
  background-position: -385px 0;
  width: 19px;
  height: 26px;
  display: inline-block;
  margin-top: 9px;
  margin-left: 13px;
}
#login .iconLetter {
  width: 50px;
  height: 45px;
  position: absolute;
  background: #e6e6e6;
  border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  border: 1px solid #979797;
  left: 0;
}
#login .form-control {
  padding-left: 45px;
  height: 45px;
  border: 1px solid #979797;
}
#login .has-error .form-control {
  padding-left: 60px;
  height: 45px;
  border-color: #a94442;
}
#login .form-control-feedback {
  line-height: 26px;
}
#login .has-error .iconLetter {
  width: 50px;
  height: 45px;
  position: absolute;
  background: #ffd4d4 !important;
  border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  border: 1px solid #a94442 !important;
  left: 0;
  margin-top: 14px;
  margin-left: 0px;
}
@media (max-width:767px) {
  #login .has-error .iconLetter {
    margin-top: 13px;
  }
}
#login .button.orange1 {
  font-size: 18px;
}
#login a.mdp-forgot,
#login a.mdp-active:hover,
#login a.mdp-forgot:visited {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.2rem;
  line-height: 1.5rem;
  color: #474747;
}
#login a.mdp-forgot:hover {
  text-decoration: underline;
}
#login .modal-title {
  font-size: 3.6rem;
  line-height: 3.6rem;
  color: #474747;
}
#login .txt-forgot-password {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.8rem;
  line-height: 2.2rem;
  color: #474747;
}
#login .modal-footer {
  text-align: justify;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.2rem;
  line-height: 1.5rem;
  color: #474747;
}
#login .float-r {
  float: right;
}
#login .mdp-forgot {
  float: right;
  margin-top: -10px;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.2rem;
  line-height: 1.5rem;
  color: #474747;
}
#login a.mdp-forgot,
#login a.mdp-forgot:active,
#login a.mdp-forgot:visited {
  color: #474747;
}
#login a.mdp-forgot:hover,
#login a.mdp-forgot:active:hover,
#login a.mdp-forgot:visited:hover {
  color: #474747;
  text-decoration: underline;
}
#password .wrapper-img-1 {
  display: table;
  background-repeat: no-repeat;
  width: 100%;
  height: 475px;
}
#password .entete {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.8rem;
  line-height: 2.2rem;
  color: #474747;
  line-height: 24px;
}
#password .title-page {
  font-size: 3.6rem;
  line-height: 3.6rem;
  color: #474747;
  font-weight: bold;
}
#password .mt-60 {
  margin-top: 60px !important;
}
#password .ml-150 {
  margin-left: -150px !important;
}
#password .block-login {
  border: 1px solid #979797;
  border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -webkit-border-radius: 3px 3px 3px 3px;
  padding: 40px 20px;
  margin: 40px 0;
}
#password .block-login .title-login {
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #474747;
  margin-right: -15px;
  text-align: center;
  margin-bottom: 20px;
}
#password .block-login .title-login p {
  text-align: left;
  margin-top: 20px;
}
#password .block-login .title-login ul {
  font-weight: bold;
  margin-left: 35%;
  text-align: left;
}
#password .block-login .title-login ul li {
  font-size: 1.6rem;
  font-family: "Open Sans", Sans-Serif;
  line-height: 2.2rem;
  color: #474747;
}
#password .block-login .reset-password-text {
  font-size: 1em;
  text-align: center;
}
#password .block-login .mail-adress {
  color: #28a3b2;
  font-weight: bold;
}
#password .block-login p {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.8rem;
  line-height: 2.2rem;
  color: #474747;
  margin-bottom: 20px;
}
#password .block-login button {
  margin-top: 80px;
}
@media (max-width:1199px) {
  #password .block-login button {
    margin-top: 55px;
  }
}
#password .block-login .sprite-mail {
  background-image: url('/images/habitatprestopro/sprite-profile.png');
  background-repeat: no-repeat;
  background-position: -323px 0;
  width: 28px;
  height: 19px;
  display: inline-block;
  margin-top: 12px;
  margin-left: 10px;
}
#password .block-login .sprite-password {
  background-image: url('/images/habitatprestopro/sprite-profile.png');
  background-repeat: no-repeat;
  background-position: -385px 0;
  width: 19px;
  height: 26px;
  display: inline-block;
  margin-top: 9px;
  margin-left: 13px;
}
#password .block-login .sprite-password.has-error {
  background-position: -407px 0;
}
#password .block-login .iconLetter {
  width: 50px;
  height: 45px;
  position: absolute;
  background: #e6e6e6;
  border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  border: 1px solid #979797;
  left: 0;
}
#password .block-login .iconLetter.has-error {
  border: 1px solid #c03634;
  background-color: #fed3d5;
  margin-top: 0;
  margin-left: 0;
}
#password .block-login .has-feedback .iconLetter {
  margin-left: 0;
}
#password .block-login .form-control {
  padding-left: 60px;
  height: 45px;
  border: 1px solid #979797;
}
#password .block-login .button.orange1 {
  font-size: 18px;
  margin-top: 30px;
  text-align: center;
}
#password .block-login .button-width {
  width: 260px;
}
#password .float-r {
  float: right;
}
.application .green-wrapper {
  padding: 30px 0 40px 0;
  background-color: #28a3b2;
}
.application .green-wrapper h4 {
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #474747;
  color: #FFFFFF;
  text-align: center;
}
.application .green-wrapper .appli-top {
  margin-top: 30px;
}
.application .green-wrapper .appli-top a.not-available-btn {
  background-color: #FFFFFF;
  border-radius: 1.4rem;
  padding: 15px;
  height: 80px;
  color: #28a3b2;
  line-height: 24px;
  width: 200px;
  cursor: none;
  display: block;
  opacity: 0.8;
  margin-left: auto;
}
@media (max-width:767px) {
  .application .green-wrapper .appli-top a.not-available-btn {
    float: none;
    margin: auto;
  }
}
.application .green-wrapper .appli-top img {
  width: 100%;
}
.application .green-wrapper .appli-top a.bt-appli-green,
.application .green-wrapper .appli-top a.bt-appli-green:active,
.application .green-wrapper .appli-top a.bt-appli-green:visited {
  border: 1px solid #28a3b2;
  background-color: #FFFFFF;
  border-radius: 1.4rem;
  padding: 15px;
  height: 80px;
  color: #28a3b2;
  line-height: 24px;
  width: 200px;
}
.application .green-wrapper .appli-top a.bt-appli-green.apple-green {
  float: right;
  margin-right: 20px;
}
@media (max-width:767px) {
  .application .green-wrapper .appli-top a.bt-appli-green.apple-green {
    float: none;
    margin: 20px auto 0 auto;
  }
}
.application .green-wrapper .appli-top a.bt-appli-green.android-green {
  float: left;
  margin-left: 20px;
}
@media (max-width:767px) {
  .application .green-wrapper .appli-top a.bt-appli-green.android-green {
    float: none;
    margin: 40px auto 0 auto;
  }
}
.application .green-wrapper .appli-top a.bt-appli-green:hover {
  border: 1px solid #000;
  background-color: #000;
  color: #FFF;
}
.application .green-wrapper .appli-top .ico-apple-green {
  background-image: url(/images/habitatprestopro/apple-1bis.png);
  background-repeat: no-repeat;
  background-position: center center;
  width: 41px;
  height: 50px;
  display: inline-block;
  float: left;
  margin-right: 15px;
}
.application .green-wrapper .appli-top a.apple-green:hover .ico-apple-green {
  background-image: url(/images/habitatprestopro/apple-2.png);
  background-repeat: no-repeat;
  background-position: center center;
}
.application .green-wrapper .appli-top .ico-android-green {
  background-image: url(/images/habitatprestopro/play-1bis.png);
  background-repeat: no-repeat;
  background-position: center center;
  width: 41px;
  height: 50px;
  display: inline-block;
  float: left;
  margin-right: 15px;
}
.application .green-wrapper .appli-top a.android-green:hover .ico-android-green {
  background-image: url(/images/habitatprestopro/play-2.png);
  background-repeat: no-repeat;
  background-position: center center;
}
.application h1 {
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #474747;
  text-align: center;
  margin-top: 30px;
  text-transform: uppercase;
}
.application .appli-bottom {
  margin-bottom: 40px;
}
.application .appli-bottom img {
  width: 100%;
}
.application .appli-bottom a.not-available-btn {
  border: 1px solid #727272;
  border-radius: 1.4rem;
  padding: 15px;
  height: 80px;
  color: #727272;
  line-height: 24px;
  width: 200px;
  cursor: none;
  display: block;
  opacity: 0.8;
  margin-left: auto;
}
@media (max-width:767px) {
  .application .appli-bottom a.not-available-btn {
    float: none;
    margin: auto;
  }
}
.application .appli-bottom a.bt-appli,
.application .appli-bottom a.bt-appli:active,
.application .appli-bottom a.bt-appli:visited {
  border: 1px solid #727272;
  border-radius: 1.4rem;
  padding: 15px;
  height: 80px;
  color: #727272;
  line-height: 24px;
  width: 200px;
}
.application .appli-bottom a.bt-appli.apple {
  float: right;
  margin-right: 20px;
}
@media (max-width:767px) {
  .application .appli-bottom a.bt-appli.apple {
    float: none;
    margin: 20px auto 0 auto;
  }
}
.application .appli-bottom a.bt-appli.android {
  float: left;
  margin-left: 20px;
}
@media (max-width:767px) {
  .application .appli-bottom a.bt-appli.android {
    float: none;
    margin: 40px auto 0 auto;
  }
}
.application .appli-bottom a.bt-appli:hover {
  border: 1px solid #000;
  background-color: #000;
  color: #FFF;
}
.application .appli-bottom .ico-apple {
  background-image: url(/images/habitatprestopro/apple-1.png);
  background-repeat: no-repeat;
  background-position: center center;
  width: 41px;
  height: 50px;
  display: inline-block;
  float: left;
  margin-right: 15px;
}
.application .appli-bottom a.apple:hover .ico-apple {
  background-image: url(/images/habitatprestopro/apple-2.png);
  background-repeat: no-repeat;
  background-position: center center;
}
.application .appli-bottom .ico-android {
  background-image: url(/images/habitatprestopro/play-1.png);
  background-repeat: no-repeat;
  background-position: center center;
  width: 41px;
  height: 50px;
  display: inline-block;
  float: left;
  margin-right: 15px;
}
.application .appli-bottom a.android:hover .ico-android {
  background-image: url(/images/habitatprestopro/play-2.png);
  background-repeat: no-repeat;
  background-position: center center;
}
.application .store-appli {
  font-size: 20px;
}
/****** INCLUDES ******/
.available-projects-block {
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
  background-color: #FFFFFF;
  margin-bottom: 30px;
  width: 100%;
  padding: 15px 15px 20px;
}
.available-projects-block .filter-container {
  align-self: flex-end;
  margin-bottom: 10px;
}
.available-projects-block .padding-15 {
  padding: 0 15px 15px;
}
.available-projects-block .title {
  font-family: 'Open Sans', sans-serif;
  font-size: 2.4rem;
  line-height: 3rem;
  color: #474747;
  margin: 30px 0 10px;
}
.available-projects-block .title .number {
  color: #ff804b;
  font-weight: bold;
}
.available-projects-block .new-label {
  display: none;
}
.available-projects-block .info-project .details-project .label-exclusivite-parent {
  margin-bottom: 10px;
}
.available-projects-block .info-project .details-project .label-exclusivite {
  color: white;
  background-color: #ff804b;
  border-radius: 5px;
  padding: 1px 6px 1px 6px;
  margin-bottom: 5px;
}
.available-projects-block .info-project .details-project .calendar-icon {
  position: relative;
  width: 50px;
  height: 50px;
}
.available-projects-block .info-project .details-project .calendar-icon img {
  height: 50px;
  width: 50px;
  position: absolute;
}
.available-projects-block .info-project .details-project .calendar-icon .date-calendar {
  position: absolute;
  top: 33%;
  left: 28%;
  text-align: center;
}
.available-projects-block .info-project .details-project .calendar-icon .date-calendar .date {
  font-weight: bold;
  color: #28a3b2;
}
.available-projects-block .info-project .details-project .category {
  color: #28a3b2;
  text-transform: capitalize;
  margin-bottom: 5px;
}
.available-projects-block .info-project .details-project address {
  text-transform: uppercase;
}
.available-projects-block .info-project .details-project address span {
  font-weight: bold;
  text-transform: none;
}
.available-projects-block .info-project .details-project .bt {
  display: block;
  margin-bottom: 12px;
}
.available-projects-block .info-project .details-project .description {
  color: #727272;
  margin-top: 10px;
  margin-bottom: 20px;
  line-height: 18px;
}
.available-projects-block .info-project.new-project .new-label {
  font-family: 'Open Sans', sans-serif;
  font-size: 2.4rem;
  line-height: 3rem;
  color: #474747;
  line-height: 0;
  font-size: 12px;
  color: #FFFFFF;
  display: inline-block;
  background: #ff804b;
  margin-top: 0;
  padding: 12px 6px;
}
.available-projects-block .info-project .complete-label {
  font-family: 'Open Sans', sans-serif;
  font-size: 2.4rem;
  line-height: 3rem;
  color: #474747;
  line-height: 0;
  font-size: 12px;
  color: #FFFFFF;
  display: inline-block;
  background: #727272;
  margin-top: 0;
  padding: 12px 6px;
  border-radius: 0 0 0.8rem 0.8rem;
}
.available-projects-block .info-project .subcontracting-label {
  font-family: 'Open Sans', sans-serif;
  font-size: 2.4rem;
  line-height: 3rem;
  color: #474747;
  line-height: 0;
  font-size: 12px;
  color: #FFFFFF;
  display: inline-block;
  background: #28a3b2;
  margin-top: 0;
  padding: 12px 6px;
}
.available-projects-block .info-project hr.separator {
  margin-bottom: 0;
  border-bottom: 1px solid #e2e2e2;
  border-radius: 1rem;
}
@media screen and (max-width:767px) {
  .available-projects-block .info-project hr.separator {
    margin-top: 30px;
  }
}
.available-projects-block .section-button a {
  display: inline-block;
}
.available-projects-block .section-button .bt-border-orange {
  margin-top: 10px;
}
.available-projects-block > .row:first-of-type .new-project .new-label {
  margin-top: 10px;
}
@media screen and (max-width:991px) {
  .available-projects-block {
    margin-top: 20px;
  }
}
.invoices-section {
  border-radius: 1rem;
  background-color: #FFFFFF;
  margin-bottom: 30px;
  width: 100%;
  padding: 15px;
}
.invoices-section img {
  width: 50px;
  height: 50px;
}
.invoices-section .ui-accordion-content,
.invoices-section .ui-state-active {
  border: 0;
}
.invoices-section .ui-accordion-content {
  padding: 0;
}
@media screen and (max-width:991px) {
  .invoices-section p {
    line-height: 2rem;
  }
}
.invoices-section h2 {
  font-family: 'Open Sans', sans-serif;
  font-size: 2.4rem;
  line-height: 3rem;
  color: #474747;
  margin-bottom: 20px;
}
.invoices-section .contract-number {
  background: #cefbfe;
  padding: 10px 15px;
  font-size: 17px;
  margin-bottom: 20px;
  border-radius: 0.6rem;
}
.invoices-section .contract-number span {
  font-weight: bold;
}
.invoices-section .payment-mode {
  margin-left: 15px;
}
.invoices-section .payment-mode span {
  font-weight: bold;
}
.invoices-section .invoice-btn {
  text-align: right;
}
.invoices-section .invoice-btn .print {
  background: url('/theme/lisboa/membre/picto-planche-sprite.png');
  display: inline-block;
  width: 30px;
  height: 25px;
  background-position: -12px 133px;
  padding: 0;
  border: 0;
  position: absolute;
  left: 43px;
  top: -5px;
}
@media screen and (max-width:991px) {
  .invoices-section .invoice-btn .print {
    display: none;
  }
}
.invoices-section .invoice-btn a:last-child {
  margin-left: 10px;
  color: white;
  border: 1px solid #ff804b;
  font-weight: bold;
  background-color: #ff804b;
  transition: 0.3s;
  border-radius: 0.8rem 0.8rem 0.8rem 0.8rem;
  padding: 10px 20px;
}
.invoices-section .invoice-btn a:last-child:hover {
  color: white;
  border: 1px solid #e77544;
  font-weight: bold;
  background-color: #e77544;
  transition: 0.3s;
  border-radius: 0.8rem 0.8rem 0.8rem 0.8rem;
}
@media screen and (max-width:991px) {
  .invoices-section .invoice-btn a:last-child {
    margin-left: 0;
  }
}
.invoices-section .invoice-progress {
  margin: 2em auto 3em auto;
}
.invoices-section .invoice-progress .row {
  margin-bottom: 45px;
}
.invoices-section .invoice-progress h3 {
  font-family: 'Open Sans Condensed', sans-serif;
  color: #28a3b2;
  text-align: center;
  margin-bottom: 30px;
  font-size: 2.4rem;
}
.invoices-section .invoice-progress p span {
  color: #ff804b;
  font-weight: bold;
}
.invoices-section .invoice-progress .invoice-btn a:last-child {
  padding: 10px 39px;
}
@media screen and (max-width:991px) {
  .invoices-section .invoice-progress .invoice-btn a:last-child {
    padding: 10px 20px;
  }
}
.invoices-section .invoice-list {
  margin: auto auto 2em auto;
}
.invoices-section .invoice-list .row {
  margin-bottom: 35px;
}
.invoices-section .invoice-list h3 {
  font-family: 'Open Sans Condensed', sans-serif;
  color: #28a3b2;
  text-align: center;
  margin-bottom: 30px;
  font-size: 2.4rem;
}
.invoices-section .invoice-list .validate {
  background: url('/theme/lisboa/membre/picto-planche-sprite.png');
  display: inline-block;
  width: 30px;
  height: 32px;
  background-position: -280px 87px;
  margin-top: -9px;
}
.invoices-section .invoice-list p span {
  color: #ff804b;
  font-weight: bold;
}
@media screen and (max-width:991px) {
  .invoices-section {
    margin-top: 20px;
    margin-bottom: 0;
  }
}
.last-accepted-project {
  border-radius: 1rem;
  background-color: #FFFFFF;
  margin-bottom: 30px;
  padding: 15px 15px 20px;
  width: 100%;
}
.last-accepted-project .title {
  font-family: 'Open Sans', sans-serif;
  font-size: 2.4rem;
  line-height: 3rem;
  color: #474747;
  margin-bottom: 20px;
}
.last-accepted-project .title .number {
  color: #ff804b;
  font-weight: bold;
}
.last-accepted-project .info-project .separator {
  border-bottom: 1px solid #e2e2e2;
  border-radius: 1rem;
  margin-bottom: 30px;
}
.last-accepted-project .row:last-child .separator {
  display: none;
}
@media screen and (max-width:575px) {
  .last-accepted-project .row:last-child .separator {
    display: block;
    margin-bottom: 20px;
  }
}
.last-accepted-project .header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.last-accepted-project .see-more-project {
  color: #ff804b;
  text-align: right;
  margin-top: -10px;
}
.last-accepted-project .see-more-project .arrow-link {
  background-image: url('/images/habitatprestopro/sprite-navigation.png');
  background-repeat: no-repeat;
  background-position: -50px -20px;
  width: 5px;
  height: 8px;
  display: inline-block;
  vertical-align: middle;
}
.last-accepted-project .info-project .calendar-icon {
  position: relative;
  width: 50px;
  height: 50px;
}
.last-accepted-project .info-project .calendar-icon img {
  height: 50px;
  width: 50px;
  position: absolute;
}
.last-accepted-project .info-project .calendar-icon .date-calendar {
  position: absolute;
  top: 33%;
  left: 28%;
  text-align: center;
}
.last-accepted-project .info-project .calendar-icon .date-calendar .date {
  font-weight: bold;
  color: #ff804b;
}
.last-accepted-project .info-project .pro-info {
  color: #474747;
}
.last-accepted-project .info-project .pro-info a {
  color: #28a3b2;
}
.last-accepted-project .info-project .pro-info .last-name {
  text-transform: uppercase;
}
.last-accepted-project .info-project .pro-info address {
  font-style: normal;
  text-transform: uppercase;
  display: inline-block;
}
.last-accepted-project .info-project .pro-info a.ask-review {
  display: block;
  margin-top: 5px;
  padding: 0;
  color: #ff580c;
  font-weight: bold;
  cursor: pointer;
}
.last-accepted-project .info-project .pro-info a.ask-review:hover {
  text-decoration: underline;
}
.last-accepted-project .info-project ul {
  display: inline-block;
  float: left;
}
.last-accepted-project .info-project ul > li {
  list-style-type: none;
  font-size: 1.4rem;
  margin-bottom: 10px;
}
.last-accepted-project .info-project ul > li.category {
  color: #28a3b2;
  text-transform: capitalize;
}
.last-accepted-project .info-project .advancement {
  align-self: flex-end;
}
@media screen and (max-width:575px) {
  .last-accepted-project .info-project .advancement {
    margin-top: 15px;
  }
}
.last-accepted-project .info-project form {
  text-align: center;
  display: inline-block;
}
@media screen and (max-width:575px) {
  .last-accepted-project .info-project form label {
    display: none;
  }
}
.last-accepted-project .info-project form select {
  height: 37px !important;
  font-size: 14px;
  margin-left: 5px;
  border-radius: 0.8rem;
  cursor: pointer;
  border-radius: 0.6rem;
}
.last-accepted-project .info-project form textarea {
  border-radius: 0.6rem;
}
.last-accepted-project .info-project form button {
  color: white;
  border: 1px solid #ff804b;
  font-weight: bold;
  background-color: #ff804b;
  transition: 0.3s;
  border-radius: 0.8rem 0.8rem 0.8rem 0.8rem;
  margin: 0 auto 0 15px;
  display: inline-block;
  padding: 10px 15px;
  cursor: pointer;
}
@media screen and (max-width:575px) {
  .last-accepted-project .info-project form button {
    margin: 15px auto 0 auto;
  }
}
@media screen and (max-width:991px) {
  .last-accepted-project {
    margin-top: 20px;
  }
}
.desc-app-mobile {
  border-radius: 1rem;
  background-color: #FFFFFF;
  margin-bottom: 30px;
  width: 100%;
  padding: 15px 15px 20px;
}
.desc-app-mobile img {
  padding: 3rem;
}
.desc-app-mobile div.mt-50 {
  font-size: 1.6rem;
}
.desc-app-mobile div.mt-50 ul {
  list-style: disc;
  padding: 2rem;
  line-height: 3rem;
}
.desc-app-mobile .main-dl {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.desc-app-mobile .main-dl .desc {
  margin-bottom: 3rem;
  line-height: 2.4rem;
  font-size: 1.6rem;
}
.desc-app-mobile .main-dl .dl-btn-logo {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  gap: 8px;
}
.desc-app-mobile .main-dl .dl-btn-logo a.not-available-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #727272;
  border-radius: 1.4rem;
  padding: 15px;
  color: #727272;
  line-height: 24px;
  width: 190px;
  height: 80px;
  cursor: initial;
  opacity: 0.8;
}
@media (max-width:767px) {
  .desc-app-mobile .main-dl .dl-btn-logo a.not-available-btn {
    float: none;
    margin: auto;
  }
}
.desc-app-mobile .main-dl .dl-btn-logo a.bt-appli,
.desc-app-mobile .main-dl .dl-btn-logo a.bt-appli:active,
.desc-app-mobile .main-dl .dl-btn-logo a.bt-appli:visited {
  display: flex;
  border: 1px solid #727272;
  border-radius: 1.4rem;
  padding: 15px;
  color: #727272;
  line-height: 24px;
  width: 190px;
  height: 80px;
}
.desc-app-mobile .main-dl .dl-btn-logo a.bt-appli:hover {
  border: 1px solid #000;
  background-color: #000;
  color: #FFF;
}
.desc-app-mobile .main-dl .dl-btn-logo .ico-apple {
  background-image: url(/images/habitatprestopro/apple-1.png);
  background-repeat: no-repeat;
  background-position: center center;
  width: 70px;
  height: 50px;
  display: inline-block;
  margin-right: 15px;
}
.desc-app-mobile .main-dl .dl-btn-logo a.apple:hover .ico-apple {
  background-image: url(/images/habitatprestopro/apple-2.png);
  background-repeat: no-repeat;
  background-position: center center;
}
.desc-app-mobile .main-dl .dl-btn-logo .ico-android {
  background-image: url(/images/habitatprestopro/play-1.png);
  background-repeat: no-repeat;
  background-position: center center;
  width: 70px;
  height: 50px;
  display: inline-block;
  margin-right: 10px;
}
.desc-app-mobile .main-dl .dl-btn-logo a.android:hover .ico-android {
  background-image: url(/images/habitatprestopro/play-2.png);
  background-repeat: no-repeat;
  background-position: center center;
}
.desc-app-mobile .main-dl .guide_pdf {
  display: flex;
  justify-content: space-around;
}
.desc-app-mobile .main-dl .show-guide {
  display: block;
  margin-top: 2rem;
  color: #727272;
  text-decoration: underline;
  text-align: right;
}
.project-page .label-exclusivite-parent {
  margin-bottom: 10px;
}
.project-page .label-exclusivite {
  color: white;
  background-color: #ff804b;
  border-radius: 5px;
  padding: 1px 6px 1px 6px;
  margin-bottom: 5px;
}
.project-page .demand-card {
  background-color: #FFFFFF;
  width: 100%;
  border-radius: 1rem;
  margin-bottom: 30px;
  padding: 15px;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.project-page .demand-card h2 {
  font-size: 2.4rem;
  line-height: 3rem;
  color: #474747;
  margin-bottom: 15px;
  float: left;
}
.project-page .demand-card .print {
  float: right;
  background: url('/theme/lisboa/membre/picto-planche-sprite.png');
  display: inline-block;
  width: 30px;
  height: 25px;
  background-position: -12px 133px;
  padding: 0;
  border: 0;
}
.project-page .demand-card .date {
  margin-bottom: 15px;
}
.project-page .demand-card .date span {
  font-weight: bold;
}
.project-page .demand-card .desc {
  padding: 15px;
  border: #ff804b 1px solid;
  border-radius: 0.4rem;
  line-height: 2rem;
}
.project-page .demand-card .consumer-pictures {
  margin-bottom: 15px;
}
.project-page .demand-card .consumer-pictures .flex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 1.2rem;
}
.project-page .demand-card .consumer-pictures .flex .img-thumbnail {
  width: 200px;
  height: 200px;
  object-fit: cover;
  border: 2px solid #b6b5b6;
  border-radius: 0.4rem;
}
.project-page .demand-card .ctas {
  display: flex;
  flex-direction: row;
  gap: 1.2rem;
  justify-content: center;
}
.project-page .contact-infos,
.project-page .useful-infos,
.project-page .geography-infos {
  background-color: #FFFFFF;
  width: 100%;
  border-radius: 1rem;
  margin-bottom: 30px;
  padding: 20px 15px;
}
.project-page .contact-infos .mail,
.project-page .useful-infos .mail,
.project-page .geography-infos .mail {
  word-break: break-all;
}
.project-page .contact-infos h3,
.project-page .useful-infos h3,
.project-page .geography-infos h3 {
  font-size: 2.4rem;
  line-height: 3rem;
  color: #474747;
  margin-bottom: 15px;
  text-align: center;
}
.project-page .contact-infos .col-6:first-child,
.project-page .useful-infos .col-6:first-child,
.project-page .geography-infos .col-6:first-child {
  font-weight: bold;
  text-align: right;
}
.project-page .contact-infos .col-6:first-child p,
.project-page .useful-infos .col-6:first-child p,
.project-page .geography-infos .col-6:first-child p {
  padding-bottom: 15px;
}
.project-page .contact-infos .col-6:last-child,
.project-page .useful-infos .col-6:last-child,
.project-page .geography-infos .col-6:last-child {
  text-align: left;
}
.project-page .contact-infos .col-6:last-child p,
.project-page .useful-infos .col-6:last-child p,
.project-page .geography-infos .col-6:last-child p {
  padding-bottom: 15px;
}
.project-page .contact-infos .pro-callback-unreachable-cta,
.project-page .useful-infos .pro-callback-unreachable-cta,
.project-page .geography-infos .pro-callback-unreachable-cta {
  padding: 2rem 0;
  text-align: center;
  background-color: #eef1f1;
}
.project-page .contact-infos .pro-callback-unreachable-cta .desc,
.project-page .useful-infos .pro-callback-unreachable-cta .desc,
.project-page .geography-infos .pro-callback-unreachable-cta .desc {
  margin-bottom: 4rem;
}
.project-page .contact-infos .pro-callback-unreachable-cta .cta .pro-callback-unreachable,
.project-page .useful-infos .pro-callback-unreachable-cta .cta .pro-callback-unreachable,
.project-page .geography-infos .pro-callback-unreachable-cta .cta .pro-callback-unreachable {
  margin: 0 1rem;
  padding: 1rem 3rem;
  background: #ff914d;
  border-radius: 999rem;
  font-weight: bold;
  color: white;
}
.project-page .obat-cta {
  background-color: #FFFFFF;
  width: 100%;
  border-radius: 1rem;
  margin-bottom: 30px;
  padding: 20px 15px;
  text-align: center;
}
.project-page .obat-cta h3 {
  font-size: 2.4rem;
  line-height: 3rem;
  color: #474747;
  margin-bottom: 15px;
}
.project-page .obat-cta p {
  margin-bottom: 2rem;
}
.project-page .obat-cta .cta-container {
  margin: 3rem 0 1rem 0;
}
.project-page .obat-cta .cta-container a {
  margin: 0 1rem;
  padding: 1rem 3rem;
  background: #39A2F2;
  border-radius: 1rem;
  font-weight: bold;
  color: white;
}
.project-page .geography-infos {
  padding: 20px 0 0 0;
}
.project-page .geography-infos .project-map {
  height: 350px;
  width: 100%;
}
.project-page .quality,
.project-page .advancement,
.project-page .project-pictures {
  background-color: #FFFFFF;
  border-radius: 1rem;
  margin-bottom: 30px;
  padding: 20px 15px;
}
.project-page .quality h3,
.project-page .advancement h3,
.project-page .project-pictures h3 {
  font-size: 2.4rem;
  line-height: 3rem;
  color: #474747;
  margin-bottom: 15px;
  text-align: center;
}
.project-page .quality .size-80,
.project-page .advancement .size-80,
.project-page .project-pictures .size-80 {
  width: 80%;
  margin: auto;
  text-align: center;
}
.project-page .quality .size-80 p,
.project-page .advancement .size-80 p,
.project-page .project-pictures .size-80 p {
  margin-bottom: 20px;
}
.project-page .quality .size-80 .blue-banner,
.project-page .advancement .size-80 .blue-banner,
.project-page .project-pictures .size-80 .blue-banner {
  background: #cefbfe;
  padding: 15px 0;
}
.project-page .quality .size-80 .blue-banner span,
.project-page .advancement .size-80 .blue-banner span,
.project-page .project-pictures .size-80 .blue-banner span {
  font-weight: bold;
}
.project-page .quality .size-80 .bt-orange,
.project-page .advancement .size-80 .bt-orange,
.project-page .project-pictures .size-80 .bt-orange {
  text-transform: uppercase;
  font-size: 17px;
  margin-bottom: 15px;
}
.project-page .quality .size-80 a:last-child,
.project-page .advancement .size-80 a:last-child,
.project-page .project-pictures .size-80 a:last-child {
  text-decoration: underline;
  color: #ff804b;
}
.project-page .quality .size-80 textarea,
.project-page .advancement .size-80 textarea,
.project-page .project-pictures .size-80 textarea {
  width: 100%;
  margin-top: 20px;
}
.project-page .quality .size-80 form select,
.project-page .advancement .size-80 form select,
.project-page .project-pictures .size-80 form select {
  font-size: 1.3rem;
  width: 163px;
  margin: 10px auto;
  border-radius: 0.6rem;
  cursor: pointer;
}
.project-page .quality .size-80 form textarea,
.project-page .advancement .size-80 form textarea,
.project-page .project-pictures .size-80 form textarea {
  font-size: 1.3rem;
  border-radius: 0.6rem;
  cursor: pointer;
}
.project-page .quality .size-80 button,
.project-page .advancement .size-80 button,
.project-page .project-pictures .size-80 button {
  background-color: #ff804b;
  color: #FFF;
  font-weight: bold;
  border-radius: 0.8rem;
  border: 0;
  padding: 10px 20px;
  text-align: center;
  display: inline-block;
  margin-top: 20px;
}
.project-page .quality .size-80 button:hover,
.project-page .advancement .size-80 button:hover,
.project-page .project-pictures .size-80 button:hover {
  background-color: #e77544;
}
.project-page .project-pictures {
  text-align: center;
}
.project-page .project-pictures p span {
  font-weight: bold;
}
.project-page .project-pictures a {
  color: white;
  border: 1px solid #ff804b;
  font-weight: bold;
  background-color: #ff804b;
  transition: 0.3s;
  border-radius: 0.8rem 0.8rem 0.8rem 0.8rem;
  margin: 0 auto 0 15px;
  display: inline-block;
  padding: 10px 15px;
  cursor: pointer;
}
@media screen and (max-width:991px) {
  .project-page {
    margin-top: 20px;
  }
}
.reviews-section {
  border-radius: 1rem;
  background-color: #FFFFFF;
  margin-bottom: 30px;
  width: 100%;
  padding: 15px;
}
.reviews-section h2 {
  font-family: 'Open Sans', sans-serif;
  font-size: 2.4rem;
  line-height: 3rem;
  color: #474747;
}
.reviews-section h3 {
  color: #15dacb;
}
.reviews-section a {
  color: #FF580C;
}
.reviews-section .rating-stars .rating-symbol {
  display: inline;
  position: relative;
}
.reviews-section .rating-stars .rating-symbol .custom-star {
  color: #ffe066;
  font-size: 1.2em;
}
.reviews-section .rating-stars .rating-symbol .rating-symbol-background {
  visibility: hidden;
}
.reviews-section .rating-stars .rating-symbol .rating-symbol-foreground {
  display: inline-block;
  position: absolute;
  overflow: hidden;
  left: 0;
  right: 0;
  top: 0;
  width: auto;
}
.reviews-section .global {
  margin-top: 15px;
  margin-bottom: 20px;
}
.reviews-section .global span {
  font-weight: bold;
}
.reviews-section .global span:last-child {
  font-weight: normal;
}
.reviews-section .review {
  border-bottom: 1px solid #ddd;
  margin-bottom: 20px;
}
.reviews-section .review:last-child {
  border-bottom: none;
}
.reviews-section .review .form-control {
  font-size: 14px;
}
.reviews-section .review .btn {
  font-size: 14px;
}
.reviews-section .review span {
  font-weight: bold;
}
.reviews-section .review .rating-stars {
  float: left;
}
.reviews-section .review .author {
  float: left;
  margin-left: 10px;
  margin-top: 3px;
}
@media screen and (max-width:991px) {
  .reviews-section .review .author {
    margin-left: 0;
    margin-top: 10px;
  }
}
.reviews-section .review .description {
  margin-top: 15px;
  margin-bottom: 15px;
}
.reviews-section .review button {
  color: #ff804b;
  float: right;
  background: none;
  padding: 0;
  border: none;
  box-shadow: none;
  outline: none;
}
.reviews-section .review button .arrow-link {
  background-image: url('/images/habitatprestopro/sprite-navigation.png');
  background-repeat: no-repeat;
  background-position: -50px -20px;
  width: 5px;
  height: 8px;
  display: inline-block;
  vertical-align: middle;
}
.reviews-section .review .btn-response-xs {
  text-align: center;
  margin-bottom: 15px;
}
.reviews-section .review .btn-response-xs button {
  float: none;
}
.reviews-section .review .response {
  background-color: #f8f8f8;
  padding: 20px 30px;
  border-top: 1px solid #e1e1e1;
  display: block;
}
@media screen and (max-width:1199px) {
  .reviews-section .review .response {
    padding: 20px 15px;
  }
}
.reviews-section .review .response p:last-child {
  margin-top: 15px;
}
.reviews-section .review .response .checkbox {
  font-size: 12px;
  margin-top: 10px;
}
.reviews-section .review .response .checkbox input {
  margin-right: 10px;
}
.reviews-section .review .response textarea {
  border-radius: 5px;
  width: 70%;
  height: 80px;
  padding: 10px;
}
@media screen and (max-width:1199px) {
  .reviews-section .review .response textarea {
    width: 100%;
  }
}
.reviews-section .review .response .btn-success {
  color: white;
  border: 1px solid #ff804b;
  font-weight: bold;
  background-color: #ff804b;
  transition: 0.3s;
  border-radius: 0.8rem 0.8rem 0.8rem 0.8rem;
  padding: 10px 20px;
  margin-left: 25px;
}
.reviews-section .review .response .btn-success:hover {
  color: white;
  border: 1px solid #e77544;
  font-weight: bold;
  background-color: #e77544;
  transition: 0.3s;
  border-radius: 0.8rem 0.8rem 0.8rem 0.8rem;
}
@media screen and (max-width:1199px) {
  .reviews-section .review .response .btn-success {
    margin: 15px auto 0 auto;
  }
}
@media screen and (max-width:991px) {
  .reviews-section {
    margin-top: 20px;
    margin-bottom: 0;
  }
}
.reviews-section .review-card {
  text-align: center;
  padding: 20px;
}
.reviews-section .divider {
  border-left: 1px solid #ddd;
  height: auto;
}
@media screen and (max-width:991px) {
  .reviews-section .divider {
    width: 80%;
    border-bottom: solid 1px #ddd;
    height: 1px;
    margin: auto;
    margin-bottom: 20px;
    margin-top: 20px;
  }
}
.reviews-section .review-badge {
  width: 80px;
  height: 80px;
}
.profile-information {
  margin-bottom: 30px;
  width: 100%;
  line-height: 2rem;
}
.profile-information h2 {
  font-family: 'Open Sans', sans-serif;
  font-size: 2.4rem;
  line-height: 3rem;
  color: #474747;
}
.profile-information span {
  font-weight: bold;
}
.profile-information a .arrow-link {
  background-image: url('/images/habitatprestopro/sprite-navigation.png');
  background-repeat: no-repeat;
  background-position: -50px -20px;
  width: 5px;
  height: 8px;
  display: inline-block;
  vertical-align: middle;
}
.profile-information .attention-picto,
.profile-information .checked-picto {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  padding: 5px;
  margin-right: 1rem;
  border-radius: 100%;
  font-size: 2.4rem;
  font-weight: bold;
  background: transparent;
  -webkit-user-select: none;
  user-select: none;
}
.profile-information .attention-picto {
  color: #f91617;
  border: 3px solid #f91617;
}
.profile-information .checked-picto {
  color: #56b54b;
  border: 3px solid #56b54b;
}
.profile-information .help-import-banner {
  background: #f0f0f0;
  border-radius: 1rem;
  padding: 10px 15px;
  font-size: 0.9em;
  line-height: 2em;
  margin-top: 10px;
}
@media screen and ((max-width:991px)) {
  .profile-information {
    margin-top: 20px;
    margin-bottom: 0;
  }
}
.profile-progression {
  padding: 15px;
  border-radius: 1rem;
  background-color: white;
  margin-bottom: 2rem;
}
.profile-progression .badge-progress {
  position: relative;
  width: 100%;
  height: 0.8rem;
  margin: 5rem 0;
  border-radius: 1rem;
  background-color: #c4c4c4;
  color: black;
  margin-bottom: 8rem;
}
.profile-progression .badge-progress.bw-25::before {
  content: '';
  width: 25%;
  display: block;
  height: 0.8rem;
  background-color: #60ba5f;
}
.profile-progression .badge-progress.bw-50::before {
  content: '';
  width: 50%;
  display: block;
  height: 0.8rem;
  background-color: #60ba5f;
}
.profile-progression .badge-progress.bw-75::before {
  content: '';
  width: 75%;
  display: block;
  height: 0.8rem;
  background-color: #60ba5f;
}
.profile-progression .badge-progress.bw-100::before {
  content: '';
  width: 100%;
  display: block;
  height: 0.8rem;
  background-color: #60ba5f;
}
.profile-progression .badge-progress .lock {
  background-image: url('images/habitatprestopro/lock.png');
  position: absolute;
  height: 2rem;
  width: 2rem;
  border-radius: 5rem;
  background-size: cover;
  top: 30%;
  left: 30%;
}
.profile-progression .badge-progress .bronze,
.profile-progression .badge-progress .silver,
.profile-progression .badge-progress .gold {
  position: absolute;
  height: 5rem;
  width: 5rem;
  top: -22px;
  border-radius: 5rem;
}
.profile-progression .badge-progress .bronze.completed,
.profile-progression .badge-progress .silver.completed,
.profile-progression .badge-progress .gold.completed {
  border: 2px solid #60ba5f;
  background-color: #60ba5f;
}
.profile-progression .badge-progress .bronze > .label,
.profile-progression .badge-progress .silver > .label,
.profile-progression .badge-progress .gold > .label {
  position: absolute;
  bottom: -25px;
  font-weight: bold;
}
.profile-progression .badge-progress .bronze {
  background-image: url('images/habitatprestopro/badges/badge_bronze.png');
  background-size: contain;
  left: -10px;
}
.profile-progression .badge-progress .bronze > .label {
  left: 1px;
}
.profile-progression .badge-progress .silver {
  background-image: url('images/habitatprestopro/badges/badge_argent.png');
  background-size: contain;
  left: 45%;
}
.profile-progression .badge-progress .silver > .label {
  left: 1px;
}
.profile-progression .badge-progress .gold {
  background-image: url('images/habitatprestopro/badges/badge_or.png');
  background-size: contain;
  right: -10px;
}
.profile-progression .badge-progress .gold > .label {
  left: 17px;
}
.profile-progression .text-orange {
  color: #ff804b;
}
.profile-progression .step-section {
  padding: 2rem;
  border: 1px solid #e5e5e5;
  border-radius: 1rem;
}
.profile-progression .step-section .step {
  display: flex;
  align-items: center;
  background-color: #daebff;
  padding: 1rem;
  border-radius: 1rem;
}
@media (max-width:767px) and (max-width:991px) {
  .profile-progression .step-section .step {
    position: relative;
  }
}
.profile-progression .step-section .step img {
  width: 50px;
  height: 50px;
  margin-right: 2rem;
}
@media (max-width:767px) and (max-width:991px) {
  .profile-progression .step-section .step img {
    position: absolute;
    top: 10px;
    width: 16px;
    height: 16px;
  }
}
@media (max-width:767px) and (max-width:991px) {
  .profile-progression .step-section .step .title {
    padding-left: 25px;
    margin-bottom: 5px;
  }
}
.profile-progression .step-section .step .description {
  color: #717171;
}
.profile-progression .step-section .actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
}
.profile-progression .step-section .actions .prev_next {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.profile-progression .step-section .actions .prev_next .no_styled_btn {
  border: none;
  background: none;
  cursor: pointer;
}
.profile-progression .step-section .actions .plain_btn {
  border: none;
  background-color: #ff804b;
  color: #FFFFFF;
  font-weight: bold;
  padding: 0.5rem 1rem;
  border-radius: 0.6rem;
  cursor: pointer;
  font-size: inherit;
}
.services-website {
  width: 100%;
  background: #fff;
  margin-bottom: 30px;
  border-radius: 1rem;
  padding: 15px 15px 20px;
}
.services-website img {
  padding: 3rem;
}
.services-website .luabs,
.services-website .insify {
  padding: 0 3rem 1.5rem 0;
}
.services-website .april,
.services-website .portail {
  width: 100%;
}
.services-website .portail {
  padding: 0;
}
.services-website h4 {
  font-family: 'Open Sans Condensed', sans-serif;
  font-size: 1.8rem;
  font-weight: bold;
  line-height: 3rem;
  color: #28a3b2;
  margin-bottom: 15px;
}
.services-website ul {
  line-height: 3rem;
  margin-bottom: 15px;
}
.services-website ul li {
  font-size: 1.5rem;
}
.services-accounting {
  width: 100%;
  background: #fff;
  margin-bottom: 30px;
}
.services-accounting h3 {
  font-size: 20px;
  color: #4e4e4e;
  text-align: center;
  margin: 20px auto 0 auto;
}
.services-accounting hr {
  border-bottom: 2px dotted #cecece;
  margin-top: 10px;
}
.services-accounting p {
  font-size: 14px;
  color: #4e4e4e;
  line-height: 18px;
}
.services-accounting .header {
  margin-bottom: 20px;
}
.services-accounting .header h1 {
  margin-top: 30px;
  font-size: 40px;
}
.services-accounting .header .row {
  margin-top: -50px;
}
@media screen and (max-width:991px) {
  .services-accounting .header .row {
    margin-top: 20px;
  }
}
.services-accounting .header p {
  padding: 0 15px;
}
@media screen and (max-width:991px) {
  .services-accounting .header p {
    padding: 0;
    margin-bottom: 15px;
  }
}
.services-accounting .header .sprite-reassu {
  background-image: url('/images/habitatprestopro/sprite-site-internet.png');
  background-repeat: no-repeat;
  display: inline-block;
}
.services-accounting .header .bilan {
  background-position: -1076px 0;
  width: 101px;
  height: 101px;
}
.services-accounting .header .euro {
  background-position: -1296px 0;
  width: 101px;
  height: 101px;
}
.services-accounting .header .declaration {
  background-position: -749px 0;
  width: 101px;
  height: 101px;
}
.services-accounting .presentation {
  padding: 0 15px;
}
.services-accounting .presentation h3 {
  margin-bottom: 15px;
  font-family: 'Open Sans', sans-serif;
  font-size: 2.4rem;
  line-height: 3rem;
  color: #474747;
}
.services-accounting .presentation span {
  font-weight: bold;
}
.services-accounting .presentation img {
  width: 100%;
}
.services-accounting .presentation a {
  margin: 20px 0;
}
.services-accounting .presentation .price-block {
  background-color: #cefbfe;
}
.services-accounting .presentation .price-block .bg-white {
  background-color: #fff;
  border: 3px solid #cefbfe;
  padding: 5px;
}
.services-accounting .presentation .price-block .bg-white p {
  font-size: 18px;
  margin: 5px;
}
.services-accounting .presentation .price-block .bg-white span {
  font-weight: bold;
  color: #ff804b;
  text-transform: uppercase;
}
.services-accounting .presentation .price-block .bg-blue p {
  margin-top: 15px;
  font-size: 18px;
}
.services-accounting .presentation .price-block .bg-blue .price {
  padding: 20px 60px;
}
@media screen and (max-width:991px) {
  .services-accounting .presentation .price-block .bg-blue .price {
    padding: 20px 45px;
  }
}
.services-accounting .presentation .price-block .bg-blue .price small {
  text-align: left;
  display: block;
}
.services-accounting .presentation .price-block .bg-blue .price small:last-child {
  text-align: right;
  font-size: 10px;
}
.services-accounting .presentation .price-block .bg-blue .price span {
  color: #ff804b;
  font-size: 75px;
  text-align: left;
  display: block;
  margin-top: 27px;
}
.services-accounting .trust {
  padding: 0 15px;
  width: 75%;
  margin: auto;
}
@media screen and (max-width:991px) {
  .services-accounting .trust {
    width: 100%;
  }
}
.services-accounting .trust h3 {
  margin-bottom: 15px;
  font-family: 'Open Sans', sans-serif;
  font-size: 2.4rem;
  line-height: 3rem;
  color: #474747;
}
.services-accounting .trust span {
  font-weight: bold;
}
.services-accounting .trust a {
  margin: 20px 0 30px;
}
.services-accounting ul.orange-list {
  text-align: left;
}
.services-accounting ul.orange-list li {
  background-image: url('/images/habitatpresto/orange-check.png');
  background-repeat: no-repeat;
  background-position: 0;
  padding-left: 30px;
  line-height: 20px;
  margin-top: 10px;
}
@media screen and (max-width:991px) {
  .services-accounting {
    margin-top: 20px;
  }
}
.completed-projects-block {
  border-radius: 1rem;
  background-color: #FFFFFF;
  margin-bottom: 30px;
  padding: 15px 15px 30px;
  width: 100%;
}
.completed-projects-block .title {
  font-family: 'Open Sans', sans-serif;
  font-size: 2.4rem;
  line-height: 3rem;
  color: #474747;
  margin-bottom: 20px;
}
.completed-projects-block .number {
  color: #ff804b;
  font-weight: bold;
}
.completed-projects-block .mb-10 {
  padding: 10px 0;
}
.completed-projects-block .info-project .last-name {
  text-transform: uppercase;
}
.completed-projects-block .info-project .separator {
  border-bottom: 1px solid #e2e2e2;
  border-radius: 1rem;
}
.completed-projects-block .info-project .category {
  color: #28a3b2;
  margin-top: 10px;
}
@media screen and (max-width:575px) {
  .completed-projects-block .info-project .category {
    margin-bottom: 15px;
  }
}
.completed-projects-block .info-project .date a.ask-review {
  display: block;
  margin-top: 3px;
  padding: 0;
  color: #ff580c;
  font-weight: bold;
  cursor: pointer;
}
.completed-projects-block .info-project .date a.ask-review:hover {
  text-decoration: underline;
}
.completed-projects-block .row:last-child .separator {
  display: none;
}
.completed-projects-block .bt-orange,
.completed-projects-block .bt-orange:hover {
  padding: 10px 15px;
  background-color: #ff804b;
  color: #FFFFFF;
  border-radius: 3px;
}
.proposal_download_modal {
  display: none;
  position: fixed;
  bottom: 1rem;
}
.proposal_download_modal .content {
  position: relative;
  display: flex;
  align-items: center;
  margin: 0 1.8rem;
  padding: 2rem;
  background-color: white;
  border: 1px solid #e4e4e4;
  border-radius: 0.8rem;
}
.proposal_download_modal .content .close_btn {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background-color: transparent;
  border: none;
  font-size: medium;
  cursor: pointer;
}
.proposal_download_modal .content img {
  width: 50px;
  height: 50px;
  margin-right: 3rem;
}
.proposal_download_modal .content .info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 14px;
}
.proposal_download_modal .content .info p {
  font-weight: bold;
  margin-bottom: 3rem;
  line-height: 2rem;
}
.proposal_download_modal .content .info button {
  border-radius: 0.4rem;
  border: none;
  background-color: #ff804b;
  padding: 0.8rem 0.4rem;
  color: white;
}
.card-projects-list .project-details:first-of-type {
  margin-top: 0px;
}
.card-projects-list .project-details {
  background-color: #FFFFFF;
  margin-top: 20px;
  margin-bottom: 20px;
  border-style: solid;
  border-radius: 1rem;
}
.card-projects-list .project-details .project-img {
  width: 100%;
}
@media (min-width:768px) {
  .card-projects-list .project-details .project-img {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }
}
@media (max-width:767px) {
  .card-projects-list .project-details .project-infos {
    padding: 15px;
  }
}
.card-projects-list .project-details .project-infos .heading {
  font-weight: bold;
  font-size: 1.5rem;
}
.card-projects-list .project-details .project-infos h2 {
  font-weight: bold;
  margin: 15px 0;
}
.card-projects-list .project-details .project-infos span {
  font-weight: bold;
}
.card-projects-list .project-details .project-infos p {
  line-height: 2.2rem;
}
.card-projects-list .project-details .project-infos .link {
  display: block;
  color: #ff804b;
  text-align: right;
  padding-right: 15px;
  width: 100%;
  margin-top: 15px;
}
@media (max-width:1199px) {
  .card-projects-list .project-details .project-infos .link {
    margin-top: 5px;
  }
}
.card-projects-list .project-details .project-infos .link .arrow-orange {
  background-image: url('/images/habitatprestopro/sprite-navigation.png');
  background-repeat: no-repeat;
  background-position: -50px -20px;
  width: 5px;
  height: 8px;
  display: inline-block;
  vertical-align: middle;
  margin-top: -1px;
}
.pro-card {
  background-color: #ff804b;
  width: 100%;
  padding: 15px;
  margin-bottom: 30px;
  min-height: 165px;
  color: #FFFFFF;
  border-radius: 1rem;
}
@media screen and (max-width:991px) {
  .pro-card {
    min-height: auto;
    margin-top: 30px;
  }
}
.pro-card .contract-info .year {
  margin-bottom: 10px;
}
.pro-card .contract-info .year > span {
  font-weight: bold;
}
.pro-card .contract-info .current-contract {
  font-size: 1rem;
}
.pro-card .contract-info .suspension {
  background-color: #d15b47;
  color: #FFFFFF;
  padding: 0.5em;
}
.pro-card .company-img {
  padding: 0;
  margin-left: 15px;
}
.pro-card .company-img .photo-profil {
  width: 100px;
  height: 100px;
  display: block;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50%;
}
@media screen and (min-width: 320px) and (max-width: 400px) {
  .pro-card .company-img .photo-profil {
    height: 80px;
    width: 80px;
    display: block;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 50%;
  }
}
@media screen and (min-width:992px) and (max-width: 1200px) {
  .pro-card .company-img .photo-profil {
    height: 90px;
    width: 90px;
    display: block;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 50%;
  }
}
.pro-card .pro-details {
  margin-left: -15px;
}
.pro-card .pro-details .pro-card-badge {
  width: 40px;
  height: 40px;
  margin-left: 5px;
}
.pro-card .pro-details ul {
  text-align: left;
}
.pro-card .pro-details ul .company-name {
  color: #FFFFFF;
  font-weight: bold;
  font-size: 2.4rem;
}
.pro-card .pro-details ul .pro-name {
  font-size: 1.8rem;
  line-height: 2rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-transform: capitalize;
}
.pro-card .pro-details ul .company-evaluation {
  margin-bottom: 10px;
  font-size: 1.4rem;
}
.pro-card .pro-details ul .company-evaluation .fa-2x {
  font-size: 1.2em;
  margin: 10px 0 0;
}
.pro-card .pro-details ul .company-evaluation .rating-stars .rating-symbol {
  display: inline;
  position: relative;
}
.pro-card .pro-details ul .company-evaluation .rating-stars .rating-symbol .custom-star {
  color: #ffe066;
  font-size: 1em;
}
.pro-card .pro-details ul .company-evaluation .rating-stars .rating-symbol .rating-symbol-background {
  visibility: hidden;
}
.pro-card .pro-details ul .company-evaluation .rating-stars .rating-symbol .rating-symbol-foreground {
  display: inline-block;
  position: absolute;
  overflow: hidden;
  left: 0;
  right: 0;
  top: 0;
  width: auto;
}
.pro-card .pro-details ul .company-evaluation > a {
  font-size: 1.4rem;
  text-decoration: underline;
  color: #FFFFFF;
}
.pro-card .horizontal-line {
  border: 1px solid #FFFFFF;
  margin: 10px -15px;
}
.pro-card .more-data .projects {
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
}
.pro-card .more-data .projects .details {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.pro-card .more-data .get-label {
  width: 100;
}
.pro-card .more-data .get-label > a {
  display: block;
  width: 100%;
  padding: 1rem 2rem;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  text-align: center;
  color: white;
  background-color: #65df57;
  font-weight: bold;
  transition: 0.2s;
}
.pro-card .more-data .get-label > a:hover {
  background-color: #5fd652;
}
.pro-card .star-green {
  color: #009600;
}
.progress-bar {
  position: relative;
  width: 100%;
  height: 0.8rem;
  margin: 5rem 0;
  border-radius: 1rem;
  background-color: #c4c4c4;
  color: black;
}
.progress-bar.bw-30::before {
  content: '';
  width: 30%;
  display: block;
  height: 0.8rem;
  background-color: #60ba5f;
}
.progress-bar.bw-50::before {
  content: '';
  width: 50%;
  display: block;
  height: 0.8rem;
  background-color: #60ba5f;
}
.progress-bar.bw-75::before {
  content: '';
  width: 75%;
  display: block;
  height: 0.8rem;
  background-color: #60ba5f;
}
.progress-bar.bw-100::before {
  content: '';
  width: 100%;
  display: block;
  height: 0.8rem;
  background-color: #60ba5f;
}
.progress-bar .lock {
  background-image: url('images/habitatprestopro/lock.png');
  position: absolute;
  height: 2rem;
  width: 2rem;
  border-radius: 5rem;
  background-size: cover;
  top: 20%;
  left: 21%;
}
.progress-bar .bronze,
.progress-bar .silver,
.progress-bar .gold {
  position: absolute;
  height: 3.4rem;
  width: 3.4rem;
  top: -15px;
  border-radius: 5rem;
}
.progress-bar .bronze.completed,
.progress-bar .silver.completed,
.progress-bar .gold.completed {
  border: 2px solid #60ba5f;
  background-color: #60ba5f;
}
.progress-bar .bronze > .label,
.progress-bar .silver > .label,
.progress-bar .gold > .label {
  position: absolute;
  bottom: -18px;
  font-weight: bold;
}
.progress-bar .bronze > .label.white,
.progress-bar .silver > .label.white,
.progress-bar .gold > .label.white {
  color: #FFFFFF;
}
.progress-bar .bronze {
  background-image: url('images/habitatprestopro/badges/badge_bronze.png');
  background-size: contain;
  left: -10px;
}
.progress-bar .bronze > .label {
  left: -7px;
}
.progress-bar .silver {
  background-image: url('images/habitatprestopro/badges/badge_argent.png');
  background-size: contain;
  left: 45%;
}
.progress-bar .silver > .label {
  left: -7px;
}
.progress-bar .gold {
  background-image: url('images/habitatprestopro/badges/badge_or.png');
  background-size: contain;
  right: -10px;
}
.progress-bar .gold > .label {
  left: 9px;
}
.sponsorship-card {
  background-color: #FFFFFF;
  width: 100%;
  border-radius: 1rem;
  padding: 15px;
  margin-bottom: 30px;
  min-height: 165px;
}
@media (max-width:767px) {
  .sponsorship-card {
    min-height: auto;
    margin-top: 30px;
  }
}
.sponsorship-card .title {
  font-size: 2.4rem;
  line-height: 3rem;
  color: #474747;
  margin-bottom: 15px;
  float: left;
}
.sponsorship-card .sponsor-code {
  font-weight: bold;
  color: #28a3b2;
  font-size: 25px;
  padding: 10px;
}
.sponsorship-card .sponsoring-details {
  text-decoration: underline;
  font-size: 80%;
}
.sponsorship-card .sponsoring-details a {
  color: black;
}
.sponsorship-card .form-group label {
  padding: 5px 0;
}
.quality-charter-card {
  background-color: #FFFFFF;
  width: 100%;
  border-radius: 1rem;
  padding: 15px;
  margin-bottom: 30px;
  min-height: 165px;
}
@media (max-width:767px) {
  .quality-charter-card {
    min-height: auto;
    margin-top: 30px;
  }
}
.quality-charter-card .title {
  font-size: 2.4rem;
  line-height: 3rem;
  color: #474747;
  margin-bottom: 15px;
  float: left;
}
.quality-charter-card .description {
  text-align: center;
}
.quality-charter-card .description p {
  text-align: left;
  margin-bottom: 3rem;
}
.pro-selection {
  background-color: #FFFFFF;
  width: 100%;
  border-radius: 1rem;
  padding: 15px;
  margin-bottom: 30px;
  min-height: 165px;
}
.pro-selection h2 {
  font-family: 'Open Sans', sans-serif;
  font-size: 2.4rem;
  line-height: 3rem;
  color: #474747;
  margin-bottom: 15px;
}
.pro-selection h3 {
  font-family: 'Open Sans', sans-serif;
  font-size: 2.4rem;
  line-height: 3rem;
  color: #474747;
  color: #28a3b2;
  margin-bottom: 15px;
}
.pro-selection .selection-skills {
  margin-bottom: 10px;
}
.pro-selection .selection-skills p {
  background: #e8e5e5;
  padding: 10px 5px;
  font-size: 2rem;
}
.pro-selection .selection-skills ul {
  padding: 10px 15px;
}
.pro-selection .selection-skills ul li {
  padding: 5px;
}
.pro-selection .selection-departments {
  margin-bottom: 10px;
}
.pro-selection .selection-departments ul {
  padding: 0 15px 10px;
}
.pro-selection .selection-departments ul li {
  padding: 5px;
}
.pro-selection .separator {
  border-bottom: 1px solid #e2e2e2;
  border-radius: 1rem;
  margin-top: 0;
}
.pro-selection .section-button {
  margin-top: 30px;
  margin-bottom: 20px;
}
.pro-selection .form-kilometer-zone {
  display: none;
}
.pro-selection .selection-maps {
  line-height: 2rem;
}
.pro-selection .selection-maps .maps {
  height: 350px;
  width: 100%;
  margin-top: 15px;
}
@media screen and (max-width:575px) {
  .pro-selection .selection-maps .maps {
    display: none;
  }
}
.profile-page .selection-maps p {
  line-height: 2rem;
  margin-bottom: 10px;
}
.profile-page .selection-maps .form-kilometer-zone {
  display: block;
}
.profile-page .selection-maps .form-kilometer-zone .form-group {
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
}
.profile-page .selection-maps .form-kilometer-zone .zipcode {
  margin-bottom: 10px;
  width: 100%;
}
.profile-page .selection-maps .form-kilometer-zone .zipcode .select2-container {
  padding-left: 45px;
  max-width: 319px;
}
.profile-page .selection-maps .form-kilometer-zone .zipcode .select2-selection {
  height: 45px;
  max-width: 274px;
  border-radius: 0 4px 4px 0;
  font-size: 1.5rem;
}
.profile-page .selection-maps .form-kilometer-zone .zipcode .select2-selection__rendered {
  line-height: 40px;
}
.profile-page .selection-maps .form-kilometer-zone .zipcode .select2-selection__arrow {
  margin-top: 5px;
}
.profile-page .selection-maps .form-kilometer-zone .zipcode #working_zone_btn {
  cursor: pointer;
}
.profile-page .selection-maps .form-kilometer-zone .zipcode .iconLetter {
  width: 46px;
  height: 45px;
  position: absolute;
  background: #e6e6e6;
  border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  border: 1px solid #979797;
}
@media screen and (max-width:575px) {
  .profile-page .selection-maps .form-kilometer-zone .zipcode .iconLetter {
    top: 110px;
  }
}
.profile-page .selection-maps .form-kilometer-zone .zipcode .iconLetter .sprite-zipcode {
  background-image: url('/images/habitatprestopro/sprite-profile.png');
  background-position: -72px 0;
  width: 28px;
  height: 28px;
  margin-top: 8px;
  margin-left: 9px;
  display: inline-block;
  background-repeat: no-repeat;
}
.profile-page .selection-maps .form-kilometer-zone .zipcode .form-control {
  height: 45px;
  padding-left: 58px;
  border: 1px solid #979797;
  width: 168px;
  border-radius: 4px 0 0 4px;
}
.profile-page .selection-maps .form-kilometer-zone .km .iconLetter {
  width: 46px;
  height: 45px;
  position: absolute;
  background: #e6e6e6;
  border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  border: 1px solid #979797;
}
@media screen and (max-width:575px) {
  .profile-page .selection-maps .form-kilometer-zone .km .iconLetter {
    top: 177px;
  }
}
.profile-page .selection-maps .form-kilometer-zone .km .iconLetter .sprite-km {
  background-image: url('/images/habitatprestopro/sprite-profile.png');
  background-position: -512px 0;
  width: 29px;
  height: 29px;
  margin-top: 7px;
  margin-left: 8px;
  display: inline-block;
  background-repeat: no-repeat;
}
.profile-page .selection-maps .form-kilometer-zone .km .form-control {
  height: 45px;
  padding-left: 54px;
  border: 1px solid #979797;
  width: 278px;
  border-right: none;
  border-radius: 4px 0 0 4px;
  font-size: 1.5rem;
}
@media screen and (max-width:1199px) {
  .profile-page .selection-maps .form-kilometer-zone .km .form-control {
    width: 216px;
  }
}
.profile-page .selection-maps .form-kilometer-zone .form-group-km {
  float: left;
}
.profile-page .selection-maps .form-kilometer-zone button {
  float: left;
  height: 45px;
  width: 40px;
  background: #e6e6e6;
  border: 1px solid #28a3b2;
  background: #28a3b2;
  color: #fff;
  text-transform: uppercase;
  border-radius: 0 4px 4px 0;
}
@media screen and (max-width:575px) {
  .profile-page .selection-maps .form-kilometer-zone button {
    margin-top: 12px;
  }
}
.more-project-card {
  background-color: #cefbfe;
  width: 100%;
  padding: 15px;
  margin-bottom: 30px;
  min-height: 165px;
  text-align: center;
}
.more-project-card h2 {
  font-family: 'Open Sans', sans-serif;
  font-size: 2.4rem;
  line-height: 3rem;
  color: #474747;
  margin-bottom: 15px;
  text-align: left;
}
.more-project-card p {
  text-align: left;
  line-height: 20px;
  margin-bottom: 10px;
}
.advisor-card {
  background-color: #FFFFFF;
  width: 100%;
  border-radius: 1rem;
  padding: 15px;
  margin-bottom: 30px;
  min-height: 165px;
}
.advisor-card h2 {
  font-family: 'Open Sans', sans-serif;
  font-size: 2.4rem;
  line-height: 3rem;
  color: #474747;
  margin-bottom: 15px;
  float: left;
}
.advisor-card .be-called {
  text-align: right;
  float: right;
  margin-top: 10px;
}
.advisor-card .be-called .arrow-link {
  background-image: url('/images/habitatprestopro/sprite-navigation.png');
  background-repeat: no-repeat;
  background-position: -50px -20px;
  width: 5px;
  height: 8px;
  display: inline-block;
  vertical-align: middle;
  margin-left: 5px;
}
.advisor-card .name {
  font-size: 25px;
}
.advisor-card .number {
  font-weight: bold;
  color: #28a3b2;
  font-size: 25px;
  padding: 10px;
}
.available-projects-card {
  background-color: #FFFFFF;
  width: 100%;
  border-radius: 1rem;
  padding: 15px;
  margin-bottom: 30px;
}
.available-projects-card h2 {
  font-family: 'Open Sans', sans-serif;
  font-size: 2.4rem;
  line-height: 3rem;
  color: #474747;
  margin-bottom: 15px;
  text-align: left;
}
.available-projects-card .separator {
  border-bottom: 1px solid #e2e2e2;
  border-radius: 1rem;
  margin-bottom: 15px;
}
.available-projects-card .new-label {
  display: none;
}
.available-projects-card .info-project {
  display: contents;
  color: inherit;
  cursor: pointer;
}
.available-projects-card .info-project img {
  float: left;
  height: 50px;
  width: 50px;
}
.available-projects-card .info-project .description {
  margin-bottom: 15px;
  line-height: 18px;
}
.available-projects-card .info-project .description .category {
  color: #28a3b2;
}
.available-projects-card .info-project .calendar-icon {
  position: relative;
  width: 50px;
  height: 50px;
}
.available-projects-card .info-project .calendar-icon img {
  height: 50px;
  width: 50px;
  position: absolute;
}
.available-projects-card .info-project .calendar-icon .date-calendar {
  position: absolute;
  top: 33%;
  left: 28%;
  text-align: center;
}
.available-projects-card .info-project .calendar-icon .date-calendar .date {
  font-weight: bold;
  color: #28a3b2;
}
.available-projects-card .info-project.new-project .new-label {
  font-family: 'Open Sans', sans-serif;
  font-size: 2.4rem;
  line-height: 3rem;
  color: #474747;
  line-height: 0;
  font-size: 12px;
  color: #FFFFFF;
  display: inline-block;
  background: #ff804b;
  margin-top: -15px;
  margin-bottom: 15px;
  padding: 12px 6px;
}
.available-projects-card .info-project.new-project:first-child .new-label {
  margin-top: 0;
}
.available-projects-card .info-project .subcontracting-label {
  font-family: 'Open Sans', sans-serif;
  font-size: 2.4rem;
  line-height: 3rem;
  color: #474747;
  line-height: 0;
  font-size: 12px;
  color: #FFFFFF;
  display: inline-block;
  background: #28a3b2;
  margin-top: 0;
  padding: 12px 6px;
}
.available-projects-card .info-project:last-child .separator {
  display: none;
}
.available-projects-card a.go {
  float: right;
  margin-top: 10px;
  font-size: 25px;
  color: #7b7b7b;
}
.available-projects-card a.go:hover {
  color: #ff804b;
}
.services-menu-card {
  padding: 0 15px 0 15px;
  background-color: #FFFFFF;
  width: 100%;
  border: none;
  border-radius: 1rem;
  margin-bottom: 30px;
}
.services-menu-card li {
  border-bottom: 1px solid #e8e7e7;
  font-family: 'Open Sans', sans-serif;
  font-size: 2.4rem;
  line-height: 3rem;
  color: #474747;
  padding: 15px 0;
}
.services-menu-card li:last-child {
  border-bottom: none;
}
.services-menu-card li a {
  color: #000;
}
.services-menu-card li a i {
  float: right;
  margin-top: 4px;
  color: #737373;
  border-radius: 100%;
  width: 26px;
  height: 26px;
  position: relative;
}
.services-menu-card li a i:before {
  position: absolute;
  right: 7px;
}
.services-menu-card li a:hover i,
.services-menu-card li a.active i {
  color: #fff;
  background-color: #ff804b;
}
@media screen and (max-width:991px) {
  .services-menu-card {
    display: none;
  }
}
.customer-review {
  padding: 20px 15px;
  margin-bottom: 30px;
  background-color: #FFFFFF;
  border-radius: 1rem;
}
.customer-review h3 {
  margin-bottom: 15px;
  text-align: center;
  font-family: 'Open Sans Condensed', sans-serif;
  font-size: 2.4rem;
  line-height: 3rem;
  color: #474747;
}
.customer-review div .content {
  text-align: center;
  line-height: 18px;
  margin-bottom: 2rem;
}
.customer-review div div {
  margin: 3rem 0 1rem;
  text-align: center;
}
.customer-review div div a.ask-review {
  color: white;
  border: 1px solid #ff804b;
  font-weight: bold;
  background-color: #ff804b;
  transition: 0.3s;
  border-radius: 0.8rem 0.8rem 0.8rem 0.8rem;
  margin: 0 auto 0 15px;
  display: inline-block;
  padding: 10px 15px;
  cursor: pointer;
}
.customer-review div div a.ask-review:hover {
  background-color: #e77544;
}
.project-finish {
  padding: 15px;
  border-radius: 1rem;
  background-color: white;
  margin-bottom: 2rem;
}
.project-finish .panel-title a {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.project-finish .panel-title a i {
  color: black;
}
.project-finish .desc {
  color: #717171;
  margin-bottom: 1rem;
}
.project-finish .galery_chantier .bg-cover {
  display: block;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 220px;
}
.project-finish .block_img_chantier .bt-supprimer-photo {
  float: right;
  border-radius: 0.6rem;
  border: 3px solid #ff580c;
}
.project-finish .block_img_chantier p {
  margin-bottom: 15px;
}
.project-finish .add-photo {
  margin: 1rem 0;
  font-size: 1.4rem;
}
.official-documents {
  padding: 15px;
  border-radius: 1rem;
  background-color: white;
  margin-bottom: 2rem;
}
.official-documents .panel-title a {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.official-documents .panel-title a i {
  color: black;
}
.official-documents p.desc {
  color: #717171;
}
.official-documents a.btn {
  font-size: 1.4rem;
}
.official-documents .check-download,
.official-documents .add-download {
  position: absolute;
  top: 75%;
  right: 69%;
}
.official-documents .delete-label {
  position: absolute;
  right: 4px;
  top: 4px;
  font-size: 15px;
  color: #ff804b;
}
.official-documents .doc,
.official-documents .label-quality {
  position: relative;
  float: left;
  margin: 10px 10px 15px 0;
  text-align: center;
}
.official-documents .doc-name,
.official-documents .label-name {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #dcdcdc;
  font-size: 13px;
  font-weight: normal;
}
.official-documents .check-download,
.official-documents .add-download {
  position: absolute;
  top: 75%;
  right: 69%;
}
.official-documents .delete-label {
  position: absolute;
  right: 4px;
  top: 4px;
  font-size: 15px;
  color: #ff804b;
}
.doc-card {
  margin-bottom: 1rem;
}
.doc-card .doc-title {
  margin-bottom: 1rem;
  font-weight: bold;
}
.doc-card .doc-section-completed,
.doc-card .doc-section-empty {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
  border-radius: 0.6rem;
  color: #717171;
}
.doc-card .doc-section-completed .doc-statue,
.doc-card .doc-section-empty .doc-statue {
  flex: 1;
}
.doc-card .doc-section-completed i,
.doc-card .doc-section-empty i {
  cursor: pointer;
  color: #a6a6a6;
}
.doc-card .doc-section-completed .add-picto,
.doc-card .doc-section-empty .add-picto {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  padding: 5px;
  border-radius: 100%;
  font-size: 2.4rem;
  font-weight: bold;
  background-color: #ff7131;
  color: white;
  -webkit-user-select: none;
  user-select: none;
}
.doc-card .doc-section-completed {
  border: 2px solid #56b54b;
  background-color: #e4ffdb;
}
.doc-card .doc-section-empty {
  border: 2px solid #ff0000;
  background-color: #ffdada;
}
.company-description {
  padding: 15px;
  border-radius: 1rem;
  background-color: white;
  margin-bottom: 2rem;
}
.company-description .panel-title a {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.company-description .panel-title a i {
  color: black;
}
.company-description p {
  margin-bottom: 2rem;
}
.company-description a {
  font-size: 1.4rem;
}
.contact-info {
  padding: 15px;
  border-radius: 1rem;
  background-color: white;
  margin-bottom: 2rem;
}
.contact-info .panel-title a {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.contact-info .panel-title a i {
  color: black;
}
@media screen and ((max-width:991px)) {
  .contact-info h2 {
    margin-bottom: 10px;
  }
}
.contact-info a {
  color: #ff804b;
}
.contact-info p {
  margin-bottom: 15px;
}
.contact-info .cta {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 2rem;
  margin-top: 2rem;
}
@media screen and ((max-width:991px)) {
  .contact-info .cta {
    flex-direction: column;
  }
}
.add-profile-picture {
  padding: 15px;
  border-radius: 1rem;
  background-color: white;
  margin-bottom: 2rem;
}
.add-profile-picture .panel-title a {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.add-profile-picture .panel-title a i {
  color: black;
}
.add-profile-picture p.desc {
  color: #717171;
}
.add-profile-picture a.btn {
  font-size: 1.4rem;
}
.add-profile-picture .display-img {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin: 3rem 0;
}
.add-profile-picture .display-img .outside {
  width: 70px;
  height: auto;
}
@media screen and ((max-width:991px)) {
  .add-profile-picture .display-img .outside {
    display: none;
  }
}
.add-profile-picture .display-img .medium {
  width: 90px;
  height: auto;
}
@media screen and ((max-width:991px)) {
  .add-profile-picture .display-img .medium {
    width: 70px;
  }
}
.add-profile-picture .display-img .center {
  width: 110px;
  height: auto;
}
@media screen and ((max-width:991px)) {
  .add-profile-picture .display-img .center {
    width: 90px;
  }
}
.subscription-section {
  border-radius: 1rem;
  background-color: #FFFFFF;
  margin-bottom: 30px;
  width: 100%;
  padding: 15px;
}
.subscription-section h2 {
  font-family: 'Open Sans', sans-serif;
  font-size: 2.4rem;
  line-height: 3rem;
  color: #474747;
  margin-bottom: 20px;
}
.subscription-section a.plan-btn {
  background-color: #e8e5e5;
  font-weight: bold;
  border-radius: 0.8rem;
  padding: 10px 20px;
  color: #000000;
  display: inline-block;
  width: 50%;
  margin: 10px;
  text-align: center;
}
.subscription-section a.plan-btn:hover,
.subscription-section a.plan-btn:focus,
.subscription-section a.plan-btn:active {
  background-color: #ff804b;
  color: #FFFFFF;
  font-weight: bold;
  border-radius: 0.8rem;
  padding: 10px 20px;
}
.subscription-section .userplan-actions {
  margin-top: 1rem;
  display: flex;
  justify-content: space-around;
}
.subscription-section .userplan-actions .btn-danger {
  background-color: #f8d7da;
  color: #cd0606;
}
.subscription-section .panel-title a {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.subscription-section .panel-title a i {
  color: black;
}
@media screen and (max-width:991px) {
  .subscription-section {
    margin-top: 20px;
    margin-bottom: 0;
  }
}
.subcontracting-card {
  background-color: #FFFFFF;
  width: 100%;
  border-radius: 1rem;
  padding: 15px;
  margin-bottom: 30px;
  min-height: 165px;
}
@media (max-width:767px) {
  .subcontracting-card {
    min-height: auto;
    margin-top: 30px;
  }
}
.subcontracting-card .title {
  font-size: 2.4rem;
  line-height: 3rem;
  color: #474747;
  margin-bottom: 15px;
  float: left;
}
.modal-header-primary {
  color: #fff;
  padding: 9px 15px;
  border-bottom: 1px solid #eee;
  background-color: #428bca;
  -webkit-border-top-left-radius: 5px;
  -webkit-border-top-right-radius: 5px;
  -moz-border-radius-topleft: 5px;
  -moz-border-radius-topright: 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.subcontracting-modal-h3 {
  color: #28a3b2;
  width: 100%;
}
#subcontracting-modal-body .form-group {
  font-size: 1.25em;
}
#subcontracting-modal-body .form-group .form-control {
  font-size: 1em;
  margin-bottom: 2rem;
}
#subcontracting-modal-body select.form-control {
  height: auto;
}
#subcontracting-modal-body button[type=submit] {
  font-size: 1em;
}
#subcontracting-modal-body .control-label {
  margin-bottom: 0.5rem;
}
.card-project-infos {
  background-color: #FFFFFF;
  margin-top: 20px;
  margin-bottom: 20px;
  border-style: solid;
  border-radius: 1rem;
}
.card-project-infos.grey {
  background-color: #efefef;
}
.card-project-infos.grey .edit-project {
  display: none;
}
.card-project-infos h3 {
  margin-bottom: 10px;
  font-weight: bold;
}
.card-project-infos .project-img {
  width: 100%;
  border-style: solid;
  border-top-left-radius: 1rem;
}
@media (max-width:767px) {
  .card-project-infos .project-infos {
    padding: 0 15px;
  }
}
.card-project-infos .project-infos .edit-project {
  display: inline-block;
  padding: 10px;
  margin: 0 10px;
  color: #05d8c6;
  border: 1px solid #05d8c6;
  border-radius: 3px;
}
.card-project-infos .project-infos .edit-project i {
  font-size: 1.7rem;
}
.card-project-infos .project-infos .edit-project:hover {
  color: #FFFFFF;
  background: #05d8c6;
}
.card-project-infos .heading {
  font-weight: bold;
  font-size: 1.5rem;
}
.card-project-infos h2 {
  font-weight: bold;
  margin: 15px 0;
}
.card-project-infos span {
  font-weight: bold;
}
.card-project-infos p {
  line-height: 2.2rem;
}
.card-project-infos hr {
  width: 98%;
  margin-left: 0;
}
.card-project-infos .project-description {
  padding: 15px;
}
.card-project-infos .project-description .heading {
  margin-bottom: 15px;
}
.card-project-infos .project-details {
  padding: 0 15px 15px;
}
.card-project-infos .project-details .heading {
  margin-top: 10px;
}
.card-project-infos .project-details .col-6:first-child {
  border-right: 2px dotted #cecece;
}
.card-project-infos .cancel-project {
  float: right;
  color: #05d8c6;
  border: 1px solid #05d8c6;
  margin: 10px;
  padding: 10px;
  border-radius: 3px;
}
.card-project-infos .cancel-project:hover {
  color: #FFFFFF;
  background: #05d8c6;
}
.card-subcontracting-cancel {
  background-color: #FFFFFF;
  margin-top: 20px;
  margin-bottom: 20px;
  border-style: solid;
  border-radius: 1rem;
  padding: 1em;
  text-align: center;
}
.card-subcontracting-cancel .btn {
  font-size: initial;
}
.subcontracting-pro-positioned {
  background-color: #FFFFFF;
  margin-top: 20px;
  margin-bottom: 20px;
  border-style: solid;
  border-radius: 1rem;
  padding: 1em;
}
.card-pro-positioned {
  margin-top: 20px;
  margin-bottom: 40px;
  border: 1px solid #8d8d8d;
  border-radius: 2px;
}
.card-pro-positioned .card-pro-positioned-badge {
  width: 70px;
  height: 70px;
  margin-top: 10px;
}
.card-pro-positioned .heading {
  font-weight: bold;
}
.card-pro-positioned p {
  line-height: 2.2rem;
}
.card-pro-positioned hr {
  width: 97%;
}
.card-pro-positioned .mail {
  word-break: break-all;
}
.card-pro-positioned .link {
  margin-top: 20px;
  color: #ff804b;
  display: block;
}
.card-pro-positioned .link .arrow-orange {
  background-image: url('/images/habitatprestopro/sprite-navigation.png');
  background-repeat: no-repeat;
  background-position: -50px -20px;
  width: 5px;
  height: 8px;
  display: inline-block;
  vertical-align: middle;
  margin-top: -2px;
}
.card-pro-positioned .arrow-blue {
  background-image: url('/images/habitatprestopro/sprite-navigation.png');
  background-repeat: no-repeat;
  background-position: -50px -10px;
  width: 5px;
  height: 8px;
  display: inline-block;
  vertical-align: middle;
  margin-top: -2px;
}
.card-pro-positioned .rating-stars .rating-symbol {
  display: inline;
  position: relative;
}
.card-pro-positioned .rating-stars .rating-symbol .fa-2x {
  font-size: 1.2em;
  margin: 10px 0 0;
}
.card-pro-positioned .rating-stars .rating-symbol .custom-star {
  color: #ffe066;
  font-size: 1.2em;
}
.card-pro-positioned .rating-stars .rating-symbol .rating-symbol-background {
  visibility: hidden;
}
.card-pro-positioned .rating-stars .rating-symbol .rating-symbol-foreground {
  display: inline-block;
  position: absolute;
  overflow: hidden;
  left: 0;
  right: 0;
  top: 0;
  width: auto;
}
.card-pro-positioned .informations {
  padding: 15px;
}
.card-pro-positioned .informations .company h3 {
  text-transform: uppercase;
  margin-top: 7px;
  margin-bottom: 10px;
}
.card-pro-positioned .informations .company span {
  font-weight: bold;
}
.card-pro-positioned .informations .contact-info {
  margin-bottom: 0;
}
.card-pro-positioned .informations .contact-info p {
  margin-bottom: 0;
}
.card-pro-positioned .informations .contact-info a {
  color: #000;
  text-decoration: underline;
}
@media (max-width:767px) {
  .card-pro-positioned .informations .contact-info {
    margin-top: 15px;
  }
}
@media (max-width:991px) {
  .card-pro-positioned .informations .contact-info p {
    word-break: break-all;
  }
}
.card-pro-positioned .informations .contact-info .labels-block {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
}
.card-pro-positioned .informations .contact-info .labels-block .rge-logo img {
  width: 70px;
  height: auto;
}
.card-pro-positioned .informations .advice {
  text-align: right;
}
.card-pro-positioned .informations .advice a {
  color: #05d8c6;
}
.card-pro-positioned .informations .advice .bt-orange {
  font-size: 1.5rem;
  color: #FFFFFF;
  margin-top: 15px;
  cursor: pointer;
  display: inline-block;
}
.card-pro-positioned .details {
  padding: 0 15px 20px;
}
.card-pro-positioned .details .info {
  color: #3a87ad;
  font-size: 1rem;
  font-weight: bold;
}
@media (max-width:767px) {
  .card-pro-positioned .details .info {
    margin-bottom: 5px;
    margin-top: -10px;
  }
}
.card-pro-positioned .details span {
  font-weight: bold;
  margin-left: 5px;
}
.card-pro-positioned .details .devis-check img {
  margin-left: 10px;
}
.card-pro-positioned .details .devis-check span:last-child {
  font-weight: normal;
  color: #ff804b;
}
.card-pro-positioned .details .devis-upload {
  margin-top: 10px;
}
.card-pro-positioned .details .devis-upload a span {
  font-weight: normal;
  color: #05d8c6;
}
.card-pro-positioned .details .devis-upload img {
  margin-left: 10px;
  margin-top: -10px;
}
.card-pro-positioned .details .heading {
  margin-bottom: 10px;
}
@media (max-width:767px) {
  .card-pro-positioned .details input {
    margin-bottom: 10px;
  }
}
.card-pro-empty {
  margin-top: 20px;
  margin-bottom: 40px;
  border: 1px solid #8d8d8d;
  border-radius: 2px;
  padding: 15px;
  text-align: center;
}
.card-pro-empty h3 {
  font-weight: bold;
  margin-bottom: 15px;
}
.card-pro-empty .bt-orange {
  font-size: 1.5rem;
  margin-top: 15px;
  cursor: pointer;
  display: inline-block;
}
.card-pro-empty .bt-orange:hover {
  color: #FFFFFF;
}
.star-green {
  color: #009600;
}
.projects-menu {
  margin: 20px 0 0 0;
  width: 100%;
  overflow: hidden;
  position: relative;
}
.projects-menu ul li {
  margin-bottom: 1em;
}
.projects-menu ul li .tabs-project {
  background-color: #FFFFFF;
  color: #474747;
  border-style: solid;
  border-radius: 1rem;
}
.projects-menu ul li .tabs-project .project-type {
  padding: 2px 20px 15px 15px;
}
@media (min-width:992px) and (max-width:1199px) {
  .projects-menu ul li .tabs-project .project-type {
    font-size: 1.2rem;
  }
}
.projects-menu ul li .tabs-project:hover {
  background-color: #05d8c6;
  color: #FFFFFF;
}
.projects-menu ul li .tabs-project .date {
  font-size: 1rem;
  padding: 10px 0 5px 15px;
}
.projects-menu ul li:last-child .tabs-project {
  border-bottom: 0;
}
.projects-menu .link {
  background-color: #ff804b;
  color: #FFFFFF;
  padding: 15px 20px 15px 15px;
  display: block;
  border-style: solid;
  border-radius: 1rem;
}
.projects-menu .chevron {
  float: right;
}
.projects-menu .chevron::before {
  border-style: solid;
  border-width: 0.25em 0.25em 0 0;
  content: '';
  display: inline-block;
  height: 0.45em;
  position: relative;
  top: 0.15em;
  vertical-align: top;
  width: 0.45em;
  left: 0;
  transform: rotate(45deg);
  color: #28a3b2;
}
#benefits h1 {
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #474747;
  text-align: center;
  margin-top: 30px;
  text-transform: uppercase;
}
#benefits .grid-top {
  margin-top: -40px;
}
#benefits .object-grid-container {
  padding-bottom: 40px;
}
#benefits .object-grid-container .object-row-container {
  margin-top: 30px;
}
@media (max-width:991px) {
  #benefits .object-grid-container .object-row-container {
    margin-top: 0;
  }
}
#benefits .object-grid-container .object-row-container:nth-child(4n+1) > * {
  float: right;
}
#benefits .object-grid-container .object-row-container .object-cell {
  display: table;
  margin-top: 80px;
}
@media (max-width:991px) {
  #benefits .object-grid-container .object-row-container .object-cell {
    margin-top: 40px;
  }
}
#benefits .object-grid-container .object-row-container .object-cell .image-cell {
  height: 267px;
}
#benefits .object-grid-container .object-row-container .object-cell .image-cell img {
  display: block;
  margin: 0 auto;
}
#benefits .object-grid-container .object-row-container .object-cell .indexed-cell {
  height: 267px;
  display: table-cell;
  vertical-align: middle;
}
#benefits .object-grid-container .object-row-container .object-cell .indexed-cell .positioner {
  position: relative;
}
#benefits .object-grid-container .object-row-container .object-cell .indexed-cell .positioner .index {
  display: inline-block;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-clip: padding-box;
  background-color: #28a3b2;
  text-align: center;
  font-size: 1.8rem;
  line-height: 2.2rem;
  color: white;
  line-height: 35px;
  position: absolute;
  font-weight: bold;
}
#benefits .object-grid-container .object-row-container .object-cell .indexed-cell .positioner .text {
  margin-left: 40px;
}
#benefits .object-grid-container .object-row-container .object-cell .indexed-cell .positioner .text .title h3 {
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #474747;
  margin-left: 15px;
}
#benefits .object-grid-container .object-row-container .object-cell .indexed-cell .positioner .text ul {
  line-height: 30px;
}
#benefits .object-grid-container .object-row-container .object-cell .indexed-cell .positioner .text ul li {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.8rem;
  line-height: 2.2rem;
  color: #474747;
  background-image: url('/images/habitatpresto/fleche-noire.png');
  background-repeat: no-repeat;
  background-position: 0 10px;
  padding: 0 20px;
  margin-left: 13px;
  line-height: 30px;
  margin-top: 15px;
}
#benefits .object-grid-container .object-row-container .object-cell .indexed-cell .positioner .text ul li strong {
  font-weight: bold;
}
#benefits .section-title h2 {
  font-weight: bold !important;
  margin-top: 60px;
}
#benefits .appli {
  background-image: url('/images/habitatprestopro/sprite-apps.png');
  background-repeat: no-repeat;
  display: inline-block;
  margin: 10px;
}
#benefits .apple {
  background-position: 0 0;
  width: 145px;
  height: 49px;
}
#benefits .apple:hover {
  background-position: 0 -53px;
  width: 145px;
  height: 49px;
}
#benefits .google {
  background-position: 0 -106px;
  width: 145px;
  height: 49px;
}
#benefits .google:hover {
  background-position: 0 -159px;
  width: 145px;
  height: 49px;
}
#benefits .object-row-separator {
  clear: both;
}
.reassurances .object-repeater-container {
  width: 100%;
  float: left;
}
.reassurances .object-repeater-container .positioner {
  text-align: center;
}
.reassurances .section-button {
  text-align: center;
  padding-bottom: 60px;
}
.reassurances .section-button .button {
  color: white;
  border: 1px solid #ff804b;
  background-color: #ff804b;
  border-radius: 10px;
  padding: 10px 20px;
  font-size: 18px;
  background-clip: padding-box;
  transition: 0.3s;
}
.reassurances .section-button .button:hover {
  border: 1px solid #ff976c;
  background-color: #ff976c;
}
#inscription-entreprise {
  /****** bloc estimation *****/
  /******rea-home*****/
}
#inscription-entreprise .top-content a {
  color: #727272;
}
#inscription-entreprise .progress {
  list-style: none;
  margin-top: 40px;
  padding: 0;
  display: table;
  table-layout: fixed;
  width: 100%;
  color: #929292;
  background-color: transparent;
  box-shadow: none;
}
#inscription-entreprise .progress > li {
  font-size: 3.3rem;
  line-height: 3.6rem;
  position: relative;
  display: table-cell;
  text-align: center;
  font-size: 2rem;
  color: #474747;
}
#inscription-entreprise .progress > li:before {
  content: attr(data-step);
  display: block;
  margin: 0 auto 0.25em;
  background: #e6e6e6;
  color: #FFFFFF;
  width: 3em;
  height: 3em;
  text-align: center;
  line-height: 3em;
  border-radius: 100%;
  position: relative;
  z-index: 1;
  font-size: 1.9rem;
  font-weight: 600;
}
#inscription-entreprise .progress > li:after {
  content: '';
  position: absolute;
  display: block;
  background: #e6e6e6;
  width: 100%;
  height: 0.5rem;
  top: 1.25em;
  left: 49.2%;
  margin-left: 1.45em;
  z-index: 1;
}
#inscription-entreprise .progress > li:last-child:after {
  display: none;
}
#inscription-entreprise .progress > li.is-complete {
  color: #474747;
}
#inscription-entreprise .progress > li.is-complete:before,
#inscription-entreprise .progress > li.is-complete:after {
  color: #FFFFFF;
  background: #28a3b2;
}
#inscription-entreprise .progress > li.is-active {
  color: #474747;
}
#inscription-entreprise .progress > li.is-active:before {
  color: #FFFFFF;
  background: #28a3b2;
}
#inscription-entreprise .button {
  font-size: 18px;
  padding: 10px 60px;
  color: #FFFFFF;
  border: 1px solid #ff804b;
  background-color: #ff804b;
  border-radius: 3px 3px 3px 3px;
  background-clip: padding-box;
  white-space: nowrap;
  vertical-align: middle;
}
#inscription-entreprise label {
  font-size: 1.4rem;
  color: #474747;
  display: block;
  font-weight: normal;
  margin: 20px 0 10px;
  padding: 0;
  line-height: 2.2rem;
  text-align: left;
}
@media (max-width:767px) {
  #inscription-entreprise div.image-wrapper.size-2.text {
    display: none !important;
  }
}
#inscription-entreprise .entete {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.8rem;
  line-height: 2.2rem;
  color: #474747;
  line-height: 2.8rem;
}
@media (max-width:767px) and (max-width:991px) {
  #inscription-entreprise .entete {
    text-align: center;
  }
}
#inscription-entreprise .account {
  font-size: 1.2rem;
}
#inscription-entreprise .account .orange {
  color: #ff804b;
}
@media (max-width:767px) and (max-width:991px) {
  #inscription-entreprise .account {
    font-size: 1.4rem;
    line-height: 2.2rem;
    text-align: center;
  }
}
#inscription-entreprise .count-society {
  background-color: #082b37;
  background-image: url('/images/habitatprestopro/bg-footer.jpg');
  padding: 30px 0;
}
#inscription-entreprise .count-society .normal_text {
  font-family: 'Open Sans Condensed', sans-serif;
  font-size: 3.3rem;
  color: #FFFFFF;
}
#inscription-entreprise .count-society .strong_text {
  font-family: 'Open Sans Condensed', sans-serif;
  font-weight: bold;
  font-size: 3.6rem;
  line-height: 4.8rem;
  color: #FFFFFF;
}
#inscription-entreprise .title-page {
  font-size: 3.6rem;
  line-height: 3.6rem;
  color: #474747;
  font-weight: bold;
}
#inscription-entreprise .mt-60 {
  margin-top: 60px !important;
}
#inscription-entreprise .ml-150 {
  margin-left: -150px !important;
}
#inscription-entreprise .mb-60 {
  margin-bottom: 60px;
}
#inscription-entreprise .block-login {
  border: 1px solid #cecece;
  border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -webkit-border-radius: 3px 3px 3px 3px;
  padding: 40px 0;
}
#inscription-entreprise .block-login p {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.8rem;
  line-height: 2.2rem;
  color: #474747;
  margin-top: 10px;
  margin-left: -15px;
}
#inscription-entreprise .sprite-activity {
  background-image: url('/images/habitatprestopro/sprite-profile.png');
  background-repeat: no-repeat;
  background-position: -200px 0;
  width: 27px;
  margin-left: 10px;
  background-position: 0 0;
  width: 34px;
  height: 28px;
  display: inline-block;
  margin-top: 8px;
  margin-left: 7px;
}
#inscription-entreprise .sprite-activity.has-error {
  background-position: -230px 0;
}
#inscription-entreprise .sprite-activity.has-error {
  background-position: -38px 0;
}
#inscription-entreprise .sprite-zipcode {
  background-image: url('/images/habitatprestopro/sprite-profile.png');
  background-repeat: no-repeat;
  background-position: -200px 0;
  width: 27px;
  margin-left: 10px;
  background-position: -72px 0;
  width: 28px;
  height: 28px;
  display: inline-block;
  margin-top: 8px;
  margin-left: 13px;
}
#inscription-entreprise .sprite-zipcode.has-error {
  background-position: -230px 0;
}
#inscription-entreprise .sprite-zipcode.has-error {
  background-position: -100px 0;
}
#inscription-entreprise .sprite-society {
  background-image: url('/images/habitatprestopro/sprite-profile.png');
  background-repeat: no-repeat;
  background-position: -200px 0;
  width: 27px;
  height: 28px;
  margin-left: 10px;
  background-position: -125px 0;
  width: 34px;
  height: 27px;
  display: inline-block;
  margin-top: 8px;
  margin-left: 7px;
}
#inscription-entreprise .sprite-society.has-error {
  background-position: -230px 0;
}
#inscription-entreprise .sprite-society.has-error {
  background-position: -162px 0;
}
#inscription-entreprise .sprite-profile {
  background-image: url('/images/habitatprestopro/sprite-profile.png');
  background-repeat: no-repeat;
  background-position: -200px 0;
  width: 27px;
  height: 28px;
  display: inline-block;
  margin-top: 8px;
  margin-left: 10px;
}
#inscription-entreprise .sprite-profile.has-error {
  background-position: -230px 0;
}
#inscription-entreprise .sprite-phone {
  background-image: url('/images/habitatprestopro/sprite-profile.png');
  background-repeat: no-repeat;
  background-position: -200px 0;
  width: 27px;
  margin-left: 10px;
  background-position: -260px 0;
  width: 28px;
  height: 28px;
  display: inline-block;
  margin-top: 8px;
  margin-left: 9px;
}
#inscription-entreprise .sprite-phone.has-error {
  background-position: -230px 0;
}
#inscription-entreprise .sprite-phone.has-error {
  background-position: -290px 0;
}
#inscription-entreprise .sprite-mail {
  background-image: url('/images/habitatprestopro/sprite-profile.png');
  background-repeat: no-repeat;
  background-position: -200px 0;
  width: 27px;
  height: 28px;
  margin-top: 8px;
  background-position: -323px 0;
  width: 28px;
  height: 19px;
  display: inline-block;
  margin-top: 12px;
  margin-left: 10px;
}
#inscription-entreprise .sprite-mail.has-error {
  background-position: -230px 0;
}
#inscription-entreprise .sprite-mail.has-error {
  background-position: -354px 0;
}
#inscription-entreprise .sprite-password {
  background-image: url('/images/habitatprestopro/sprite-profile.png');
  background-repeat: no-repeat;
  background-position: -200px 0;
  width: 27px;
  height: 28px;
  margin-top: 8px;
  margin-left: 10px;
  background-position: -385px 0;
  width: 19px;
  height: 26px;
  display: inline-block;
  margin-top: 9px;
  margin-left: 13px;
}
#inscription-entreprise .sprite-password.has-error {
  background-position: -230px 0;
}
#inscription-entreprise .sprite-password.has-error {
  background-position: -407px 0;
}
#inscription-entreprise .sprite-check {
  background-image: url('/images/habitatprestopro/sprite-profile.png');
  background-repeat: no-repeat;
  background-position: -200px 0;
  width: 27px;
  height: 28px;
  margin-top: 8px;
  margin-left: 10px;
  background-position: -428px 0;
  width: 22px;
  height: 16px;
  display: inline-block;
  margin-top: 1px;
  margin-left: 0;
}
#inscription-entreprise .sprite-check.has-error {
  background-position: -230px 0;
}
#inscription-entreprise .sprite-calendar {
  background-image: url('/images/habitatprestopro/sprite-profile.png');
  background-repeat: no-repeat;
  background-position: -200px 0;
  width: 27px;
  height: 28px;
  margin-left: 10px;
  background-position: -454px 0;
  display: inline-block;
  margin-top: 8px;
  margin-left: 11px;
}
#inscription-entreprise .sprite-calendar.has-error {
  background-position: -230px 0;
}
#inscription-entreprise .iconLetter {
  width: 50px;
  z-index: 3;
  height: 45px;
  position: absolute;
  background: #e6e6e6;
  border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  border: 1px solid #979797;
  margin-top: 52px;
}
#inscription-entreprise .iconLetter.block {
  margin-top: 0;
}
#inscription-entreprise .iconLetter.has-error {
  border: 1px solid #c03634;
  background-color: #fed3d5;
  z-index: 5;
}
@media screen and (max-width:767px) {
  #inscription-entreprise .iconLetter {
    margin-top: 49px;
  }
}
@media screen and (min-width:992px) and (max-width:1199px) {
  #inscription-entreprise .iconLetter.search-company {
    margin-top: 74px;
  }
}
@media screen and (min-width:768px) and (max-width:991px) {
  #inscription-entreprise .iconLetter.search-company {
    margin-top: 52px;
  }
}
@media screen and (max-width: 548px) {
  #inscription-entreprise .iconLetter.search-company {
    margin-top: 68px;
  }
}
#inscription-entreprise .form-control {
  height: 45px;
  border: 1px solid #979797;
  padding-left: 60px;
}
#inscription-entreprise .form-control.select2-container {
  padding: 0;
  border-radius: 2px;
  margin-top: 20px;
}
#inscription-entreprise .form-control.select2-container .select2-choice {
  height: 43px !important;
  width: 100%;
  background-color: #FFFFFF;
  background-image: linear-gradient(to top, #FFFFFF 0, #FFFFFF 50%);
  font-size: 17px;
  border-radius: 0 4px 0;
  border: none;
  padding: 6px 12px;
}
#inscription-entreprise .form-control.has-error {
  border-color: #a94442;
}
#inscription-entreprise .form-horizontal {
  margin-top: -40px;
}
#inscription-entreprise .form-horizontal .has-feedback .form-control-feedback {
  margin-top: -5px;
  margin-right: -10px;
}
#inscription-entreprise .form-group {
  margin: 15px 0 0 0;
}
#inscription-entreprise .form-group .has-success.has-feedback .form-control-feedback {
  margin-top: 5px;
}
#inscription-entreprise .checkbox {
  display: inline-flex;
}
#inscription-entreprise .checkbox label {
  padding-left: 20px;
  margin: 10px 0;
  min-height: 10px;
}
#inscription-entreprise .checkbox label input {
  margin-top: 0;
}
#inscription-entreprise .pl-60 {
  padding-left: 60px;
}
#inscription-entreprise .title-step {
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #474747;
  margin-left: -15px;
}
#inscription-entreprise .button.orange1 {
  font-size: 18px;
}
#inscription-entreprise .float-r {
  float: right;
}
#inscription-entreprise .nb-1 {
  background-image: url("/images/habitatprestopro/inscription-nb.png");
  height: 40px;
  width: 40px;
  color: #FFFFFF;
  font-size: 18px;
  font-family: 'Open Sans Condensed', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  padding-top: 11px;
  padding-left: 15px;
}
#inscription-entreprise .nb-check {
  background-image: url("/images/habitatprestopro/inscription-nb.png");
  height: 40px;
  width: 40px;
  color: #FFFFFF;
  font-size: 18px;
  font-family: 'Open Sans Condensed', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  padding-top: 11px;
  padding-left: 10px;
}
#inscription-entreprise .ligne-inscription {
  background-image: url("/images/habitatprestopro/ligne-inscription.png");
  background-repeat: repeat-y;
  background-position: center top;
}
#inscription-entreprise .ligne-height-250 {
  height: 250px;
}
#inscription-entreprise .ligne-height-290 {
  height: 290px;
}
#inscription-entreprise .ligne-height-337 {
  height: 337px;
}
#inscription-entreprise .ligne-height-95 {
  height: 95px;
}
#inscription-entreprise .vert {
  color: #28a3b2;
}
#inscription-entreprise .password-criteria {
  line-height: 1.8rem;
  margin-bottom: 20px;
}
#inscription-entreprise .password-criteria ul {
  font-weight: bold;
}
#inscription-entreprise .chp-obligatoire {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.2rem;
  line-height: 1.5rem;
  color: #474747;
  text-align: right;
  margin-right: -5px;
}
#inscription-entreprise .pwd-restrictions {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.2rem;
  line-height: 1.5rem;
  color: #474747;
}
#inscription-entreprise .txt-formulaire {
  margin-left: -15px;
  margin-top: 15px;
  line-height: 18px;
}
#inscription-entreprise a.case {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: #474747;
  border: 1px solid #979797;
  border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -webkit-border-radius: 3px 3px 3px 3px;
  width: 100px;
  height: 100px;
  float: left;
  margin: 15px 0 25px 0;
  padding-top: 8px;
}
@media (max-width:767px) {
  #inscription-entreprise a.case {
    width: 90px;
  }
}
#inscription-entreprise .cgu-txt {
  display: inline;
  margin-left: 7px;
  line-height: 2.2rem;
}
#inscription-entreprise .marge {
  margin-right: 15px !important;
  margin-left: -15px !important;
}
@media (max-width:767px) {
  #inscription-entreprise .marge {
    margin-right: 5px !important;
  }
}
#inscription-entreprise #estimation .bloc-estimation {
  width: 250px;
  height: 225px;
  border: solid 1px #e8e5e5;
  background-color: #f0f0f0;
  text-align: center;
  margin-left: 122px;
}
#inscription-entreprise #estimation .bloc-estimation .tarifs {
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #474747;
  font-weight: bold;
}
#inscription-entreprise #estimation .bloc-estimation .visuel-estimation {
  margin-top: 12px;
}
@media (max-width:1199px) {
  #inscription-entreprise #estimation .bloc-estimation {
    margin-left: 58px;
  }
}
@media (max-width:991px) {
  #inscription-entreprise #estimation .bloc-estimation {
    margin: 60px auto 0 auto;
  }
}
#inscription-entreprise a.lien-underline,
#inscription-entreprise a.lien-underline:hover,
#inscription-entreprise a.lien-underline:active,
#inscription-entreprise a.lien-underline:visited {
  text-decoration: underline;
  color: #474747;
}
#inscription-entreprise .wrapper-bleu {
  width: 100%;
  background-color: #cefbfd;
}
#inscription-entreprise .wrapper-bleu .no-padding {
  padding: 0;
}
#inscription-entreprise .wrapper-bleu .bg-bleu {
  background-color: #28a3b2;
}
#inscription-entreprise .wrapper-bleu .bg-bleu h2 {
  font-weight: bold;
  font-size: 4.8rem;
  line-height: 4.8rem;
  color: white;
  font-size: 36px;
}
#inscription-entreprise .wrapper-bleu .bg-bleu p {
  color: #FFFFFF;
  line-height: 18px;
  margin-bottom: 40px;
}
#inscription-entreprise .wrapper-bleu .bg-bleu-clair {
  background-color: #cefbfd;
}
#inscription-entreprise .wrapper-bleu .bg-bleu-clair h2 {
  font-weight: bold;
  font-size: 4.8rem;
  line-height: 4.8rem;
  color: #474747;
  font-size: 36px;
}
#inscription-entreprise .wrapper-bleu .bg-bleu-clair p {
  line-height: 18px;
  margin-bottom: 40px;
}
#inscription-entreprise .wrapper-bleu .sprite-chantier {
  background-image: url('/images/habitatprestopro/sprites.png');
  background-repeat: no-repeat;
  background-position: 0 0;
  width: 101px;
  height: 101px;
  margin-top: 40px;
  margin-bottom: 10px;
}
#inscription-entreprise .wrapper-bleu .sprite-commercial {
  background-image: url('/images/habitatprestopro/sprites.png');
  background-repeat: no-repeat;
  background-position: -629px 0;
  width: 101px;
  height: 101px;
  margin-top: 40px;
  margin-bottom: 10px;
}
#inscription-entreprise .wrapper-bleu .sprite-chantiers {
  background-image: url('/images/habitatprestopro/sprites.png');
  background-repeat: no-repeat;
  background-position: -734px 0;
  width: 101px;
  height: 101px;
  margin-top: 40px;
  margin-bottom: 10px;
}
#inscription-entreprise #inscription_select .iconLetter {
  margin-top: 0!important;
}
#inscription-entreprise #inscription_select span.glyphicon.glyphicon-remove.form-control-feedback {
  margin-top: 18px;
}
#inscription-entreprise .company-list {
  display: flex;
  flex-flow: row wrap;
}
@media screen and (max-width: 546px) {
  #inscription-entreprise .company-list {
    flex-direction: column;
  }
}
#inscription-entreprise .company-list .company-profile {
  border: 1px solid #c8c8c8;
  margin: 10px;
  padding: 15px;
  vertical-align: top;
}
@media screen and (min-width: 546px) {
  #inscription-entreprise .company-list .company-profile {
    width: 45%;
  }
}
#inscription-entreprise .company-list .company-profile h3 {
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #474747;
  color: #28a3b2;
  border-bottom: 2px solid #28a3b2;
}
#inscription-entreprise .company-list .company-profile h4 {
  margin: 10px 0;
  color: #c8c8c8;
  text-transform: uppercase;
  font-size: 1.4rem;
}
#inscription-entreprise .company-list .company-profile address {
  font-style: normal;
  font-weight: bold;
  margin: 5px 0;
  text-transform: capitalize;
  font-size: 1.6rem;
}
#inscription-entreprise .company-list .company-profile .description {
  color: #c8c8c8;
  margin: 20px 0 10px;
}
#inscription-entreprise .company-list .company-profile a {
  text-align: right;
  color: #ff804b;
  margin-top: 10px;
  font-weight: 500;
}
#inscription-entreprise .pagination > li:nth-of-type(1) {
  display: none;
}
#inscription-entreprise .pagination > li > span,
#inscription-entreprise .pagination > li > a {
  color: #474747;
  text-decoration: none;
  background-color: #FFFFFF;
  border: none;
}
#inscription-entreprise .pagination > li > span.active,
#inscription-entreprise .pagination > li > a.active,
#inscription-entreprise .pagination > li > span:hover,
#inscription-entreprise .pagination > li > a:hover,
#inscription-entreprise .pagination > li > span:focus,
#inscription-entreprise .pagination > li > a:focus {
  color: #ff804b;
  text-decoration: none;
}
#inscription-entreprise .pagination > li:last-of-type a {
  color: #ff804b;
}
#inscription-entreprise .pagination > li.active span {
  color: #ff804b;
}
#inscription-entreprise .button-no-entreprise {
  margin: 20px auto 50px auto;
}
#inscription-chantiers-entreprise .image-wrapper {
  background-image: url('/images/habitatprestopro/background.jpg');
  background-position: bottom center;
  background-repeat: no-repeat;
  display: table;
  background-size: cover;
  width: 100%;
}
@media (max-width:767px) {
  #inscription-chantiers-entreprise .image-wrapper {
    height: 450px;
  }
}
#inscription-chantiers-entreprise .image-wrapper .wrapped-content > .container > .row {
  display: flex;
  margin-bottom: 50px;
}
@media (max-width:991px) {
  #inscription-chantiers-entreprise .image-wrapper .wrapped-content > .container > .row {
    flex-direction: column;
  }
}
#inscription-chantiers-entreprise .image-wrapper .wrapped-content > .container > .row h2,
#inscription-chantiers-entreprise .image-wrapper .wrapped-content > .container > .row .h2 {
  font-size: 4.8rem;
  line-height: 4.8rem;
  color: white;
  line-height: 4rem !important;
  text-align: center;
  color: #29a3b2;
  font-weight: bold;
  font-size: 3.6rem;
  margin-top: 20px;
  padding-top: 20px;
}
@media (max-width:767px) {
  #inscription-chantiers-entreprise .image-wrapper .wrapped-content > .container > .row h2,
  #inscription-chantiers-entreprise .image-wrapper .wrapped-content > .container > .row .h2 {
    font-size: 3.8rem;
  }
}
#inscription-chantiers-entreprise .image-wrapper .wrapped-content .form-column {
  margin-top: 60px;
  background-color: #d8f7f9;
}
#inscription-chantiers-entreprise .image-wrapper .wrapped-content .form-column .title {
  display: none;
}
@media (max-width:991px) {
  #inscription-chantiers-entreprise .image-wrapper .wrapped-content .form-column .title {
    display: block;
    background-color: rgba(255, 255, 255, 0.8);
    margin: -15px -9px;
  }
}
#inscription-chantiers-entreprise .image-wrapper .wrapped-content .form-column .title h1 {
  padding-top: 30px;
}
#inscription-chantiers-entreprise .image-wrapper .wrapped-content .form-column .title .sub-title {
  font-family: 'Open Sans Condensed', sans-serif;
  color: #474747;
  font-size: 2.3rem;
  line-height: 3.2rem;
  text-align: center;
  padding: 20px 30px;
  text-transform: none;
}
#inscription-chantiers-entreprise .image-wrapper .wrapped-content .form-column .progress {
  list-style: none;
  margin: 40px 0;
  padding: 0;
  display: table;
  table-layout: fixed;
  width: 100%;
  color: #929292;
  background-color: transparent;
  box-shadow: none;
}
#inscription-chantiers-entreprise .image-wrapper .wrapped-content .form-column .progress > li {
  font-size: 3.3rem;
  line-height: 3.6rem;
  position: relative;
  display: table-cell;
  text-align: center;
  font-size: 2rem;
  color: #474747;
}
#inscription-chantiers-entreprise .image-wrapper .wrapped-content .form-column .progress > li:before {
  content: attr(data-step);
  display: block;
  margin: 0 auto 0.25em;
  background: #e6e6e6;
  color: #FFFFFF;
  width: 3em;
  height: 3em;
  text-align: center;
  line-height: 3em;
  border-radius: 100%;
  position: relative;
  z-index: 1;
  font-size: 1.8rem;
  font-weight: 600;
}
#inscription-chantiers-entreprise .image-wrapper .wrapped-content .form-column .progress > li:after {
  content: '';
  position: absolute;
  display: block;
  background: #e6e6e6;
  width: 100%;
  height: 0.5rem;
  top: 1.25em;
  left: 49%;
  margin-left: 1.45em;
  z-index: 1;
}
#inscription-chantiers-entreprise .image-wrapper .wrapped-content .form-column .progress > li:last-child:after {
  display: none;
}
#inscription-chantiers-entreprise .image-wrapper .wrapped-content .form-column .progress > li.is-complete {
  color: #474747;
}
#inscription-chantiers-entreprise .image-wrapper .wrapped-content .form-column .progress > li.is-complete:before,
#inscription-chantiers-entreprise .image-wrapper .wrapped-content .form-column .progress > li.is-complete:after {
  color: #FFFFFF;
  background: #28a3b2;
}
#inscription-chantiers-entreprise .image-wrapper .wrapped-content .form-column .progress > li.is-active {
  color: #474747;
}
#inscription-chantiers-entreprise .image-wrapper .wrapped-content .form-column .progress > li.is-active:before {
  color: #FFFFFF;
  background: #28a3b2;
}
#inscription-chantiers-entreprise .image-wrapper .wrapped-content .form-column > p {
  font-size: 1rem;
  margin: 20px 0;
  text-align: right;
}
@media (max-width:991px) {
  #inscription-chantiers-entreprise .image-wrapper .wrapped-content .form-column > p {
    padding-top: 20px;
  }
}
#inscription-chantiers-entreprise .image-wrapper .wrapped-content .form-column .form-horizontal {
  padding: 0 30px;
}
#inscription-chantiers-entreprise .image-wrapper .wrapped-content .form-column .form-horizontal #inscription_select .form-control {
  padding-left: 15px;
}
#inscription-chantiers-entreprise .image-wrapper .wrapped-content .form-column .form-horizontal .button {
  font-size: 18px;
  padding: 10px 30px;
  color: #FFFFFF;
  border: 1px solid #ff804b;
  background-color: #ff804b;
  border-radius: 3px 3px 3px 3px;
  background-clip: padding-box;
}
@media screen and (min-width:768px) {
  #inscription-chantiers-entreprise .image-wrapper .wrapped-content .form-column .form-horizontal .mr-0 .form-group {
    margin-right: 0;
  }
}
#inscription-chantiers-entreprise .image-wrapper .wrapped-content .form-column .form-horizontal .profile .iconLetter {
  top: 53px;
}
#inscription-chantiers-entreprise .image-wrapper .wrapped-content .form-column .form-horizontal .without-icon .form-control {
  padding-left: 15px;
}
#inscription-chantiers-entreprise .image-wrapper .wrapped-content .form-column .form-horizontal .form-group {
  margin-right: 0!important;
  margin-left: 0!important;
}
#inscription-chantiers-entreprise .image-wrapper .wrapped-content .form-column .form-horizontal .form-group .iconLetter {
  width: 50px;
  height: 45px;
  position: absolute;
  background: #e6e6e6;
  border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  border: 1px solid #979797;
  left: 15px;
  top: 35px;
  z-index: 1;
}
#inscription-chantiers-entreprise .image-wrapper .wrapped-content .form-column .form-horizontal .form-group .iconLetter.search-activity {
  top: 0;
}
@media screen and (max-width:767px) {
  #inscription-chantiers-entreprise .image-wrapper .wrapped-content .form-column .form-horizontal .form-group .iconLetter.contact {
    top: 32px;
  }
}
#inscription-chantiers-entreprise .image-wrapper .wrapped-content .form-column .form-horizontal .form-group .iconLetter .sprite-activity {
  background-image: url('/images/habitatprestopro/sprite-profile.png');
  background-repeat: no-repeat;
  background-position: -200px 0;
  width: 27px;
  margin-left: 10px;
  background-position: 0 0;
  width: 34px;
  height: 28px;
  display: inline-block;
  margin-top: 8px;
  margin-left: 7px;
}
#inscription-chantiers-entreprise .image-wrapper .wrapped-content .form-column .form-horizontal .form-group .iconLetter .sprite-zipcode {
  background-image: url('/images/habitatprestopro/sprite-profile.png');
  background-repeat: no-repeat;
  background-position: -200px 0;
  width: 27px;
  margin-left: 10px;
  background-position: -72px 0;
  width: 28px;
  height: 28px;
  display: inline-block;
  margin-top: 8px;
  margin-left: 13px;
}
#inscription-chantiers-entreprise .image-wrapper .wrapped-content .form-column .form-horizontal .form-group .iconLetter .sprite-society {
  background-image: url('/images/habitatprestopro/sprite-profile.png');
  background-repeat: no-repeat;
  background-position: -200px 0;
  width: 27px;
  height: 28px;
  margin-left: 10px;
  background-position: -125px 0;
  width: 34px;
  height: 27px;
  display: inline-block;
  margin-top: 8px;
  margin-left: 7px;
}
#inscription-chantiers-entreprise .image-wrapper .wrapped-content .form-column .form-horizontal .form-group .iconLetter .sprite-profile {
  background-image: url('/images/habitatprestopro/sprite-profile.png');
  background-repeat: no-repeat;
  background-position: -200px 0;
  width: 27px;
  height: 28px;
  display: inline-block;
  margin-top: 8px;
  margin-left: 10px;
}
#inscription-chantiers-entreprise .image-wrapper .wrapped-content .form-column .form-horizontal .form-group .iconLetter .sprite-phone {
  background-image: url('/images/habitatprestopro/sprite-profile.png');
  background-repeat: no-repeat;
  background-position: -200px 0;
  width: 27px;
  margin-left: 10px;
  background-position: -260px 0;
  width: 28px;
  height: 28px;
  display: inline-block;
  margin-top: 8px;
  margin-left: 9px;
}
#inscription-chantiers-entreprise .image-wrapper .wrapped-content .form-column .form-horizontal .form-group .iconLetter .sprite-mail {
  background-image: url('/images/habitatprestopro/sprite-profile.png');
  background-repeat: no-repeat;
  background-position: -200px 0;
  width: 27px;
  height: 28px;
  margin-top: 8px;
  background-position: -323px 0;
  width: 28px;
  height: 19px;
  display: inline-block;
  margin-top: 12px;
  margin-left: 10px;
}
#inscription-chantiers-entreprise .image-wrapper .wrapped-content .form-column .form-horizontal .form-group .iconLetter .sprite-password {
  background-image: url('/images/habitatprestopro/sprite-profile.png');
  background-repeat: no-repeat;
  background-position: -200px 0;
  width: 27px;
  height: 28px;
  margin-top: 8px;
  margin-left: 10px;
  background-position: -385px 0;
  width: 19px;
  height: 26px;
  display: inline-block;
  margin-top: 9px;
  margin-left: 13px;
}
#inscription-chantiers-entreprise .image-wrapper .wrapped-content .form-column .form-horizontal .form-group .iconLetter .sprite-check {
  background-image: url('/images/habitatprestopro/sprite-profile.png');
  background-repeat: no-repeat;
  background-position: -200px 0;
  width: 27px;
  height: 28px;
  margin-top: 8px;
  margin-left: 10px;
  background-position: -428px 0;
  width: 22px;
  height: 16px;
  display: inline-block;
  margin-top: 1px;
  margin-left: 0;
}
#inscription-chantiers-entreprise .image-wrapper .wrapped-content .form-column .form-horizontal .form-group .iconLetter .sprite-calendar {
  background-image: url('/images/habitatprestopro/sprite-profile.png');
  background-repeat: no-repeat;
  background-position: -200px 0;
  width: 27px;
  height: 28px;
  margin-left: 10px;
  background-position: -454px 0;
  display: inline-block;
  margin-top: 8px;
  margin-left: 11px;
}
#inscription-chantiers-entreprise .image-wrapper .wrapped-content .form-column .form-horizontal .form-group .control-label {
  text-align: left;
  margin-bottom: 10px;
  line-height: 1.8rem;
  font-weight: normal;
}
#inscription-chantiers-entreprise .image-wrapper .wrapped-content .form-column .form-horizontal .form-group.siret-inscription .col-xs-12.col-md-8 {
  padding-right: 0;
}
#inscription-chantiers-entreprise .image-wrapper .wrapped-content .form-column .form-horizontal .form-group.siret-inscription .col-xs-12.col-md-2 {
  padding-left: 0;
}
#inscription-chantiers-entreprise .image-wrapper .wrapped-content .form-column .form-horizontal .form-group.siret-inscription .col-xs-12.col-md-2 a {
  position: relative;
  display: inline-block;
  zoom: 1;
  vertical-align: middle;
  height: 45px;
}
@media screen and (max-width:991px) {
  #inscription-chantiers-entreprise .image-wrapper .wrapped-content .form-column .form-horizontal .form-group.siret-inscription .col-xs-12.col-md-2 a {
    margin-top: 20px;
  }
}
#inscription-chantiers-entreprise .image-wrapper .wrapped-content .form-column .form-horizontal .label {
  font-size: 1.4rem;
  color: #474747;
  display: block;
  font-weight: normal;
  margin: 20px 0 0;
  padding: 0;
  line-height: 0;
  white-space: nowrap;
  text-align: left;
}
#inscription-chantiers-entreprise .image-wrapper .wrapped-content .form-column .form-horizontal .label.activities-select {
  margin: 3px -12px 14px;
}
#inscription-chantiers-entreprise .image-wrapper .wrapped-content .form-column .form-horizontal .form-control {
  height: 45px;
  border: 1px solid #979797;
  padding-left: 55px;
}
#inscription-chantiers-entreprise .image-wrapper .wrapped-content .form-column .form-horizontal .form-control.form-error.has-error {
  border: 1px solid #a94442;
}
#inscription-chantiers-entreprise .image-wrapper .wrapped-content .form-column .form-horizontal .form-control.select2-container {
  padding: 0;
  border-radius: 2px;
}
#inscription-chantiers-entreprise .image-wrapper .wrapped-content .form-column .form-horizontal .form-control.select2-container .select2-choice {
  height: 43px !important;
  width: 100%;
  background-color: #FFFFFF;
  background-image: linear-gradient(to top, #FFFFFF 0, #FFFFFF 50%);
  font-size: 17px;
  border-radius: 0 4px 0;
  border: none;
  padding: 6px 12px;
}
#inscription-chantiers-entreprise .image-wrapper .wrapped-content .form-column .form-horizontal .form-control.select2-container .select2-arrow {
  margin: 0;
}
#inscription-chantiers-entreprise .image-wrapper .wrapped-content .form-column .form-horizontal .has-feedback .form-control-feedback {
  margin-top: -5px;
  margin-right: -10px;
}
@media screen and (max-width: 1023px) {
  #inscription-chantiers-entreprise .image-wrapper .wrapped-content .form-column .company-list {
    display: flex;
    flex-direction: column;
  }
}
@media screen and (min-width: 1024px) {
  #inscription-chantiers-entreprise .image-wrapper .wrapped-content .form-column .company-list {
    column-count: 2;
  }
}
#inscription-chantiers-entreprise .image-wrapper .wrapped-content .form-column .company-list .company-profile {
  background-color: #FFFFFF;
  border: 1px solid #c8c8c8;
  margin: 0 0 10px 5px;
  padding: 15px;
  break-inside: avoid;
}
#inscription-chantiers-entreprise .image-wrapper .wrapped-content .form-column .company-list .company-profile h3 {
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #474747;
  color: #28a3b2;
  border-bottom: 2px solid #28a3b2;
}
#inscription-chantiers-entreprise .image-wrapper .wrapped-content .form-column .company-list .company-profile h4 {
  margin: 10px 0;
  color: #c8c8c8;
  text-transform: uppercase;
  font-size: 1.4rem;
}
#inscription-chantiers-entreprise .image-wrapper .wrapped-content .form-column .company-list .company-profile address {
  font-style: normal;
  font-weight: bold;
  margin: 5px 0;
  text-transform: capitalize;
  font-size: 1.6rem;
}
#inscription-chantiers-entreprise .image-wrapper .wrapped-content .form-column .company-list .company-profile .description {
  color: #c8c8c8;
  margin: 20px 0 10px;
}
#inscription-chantiers-entreprise .image-wrapper .wrapped-content .form-column .company-list .company-profile a {
  text-align: right;
  color: #ff804b;
  margin-top: 10px;
  font-weight: 500;
}
#inscription-chantiers-entreprise .image-wrapper .wrapped-content .form-column .pagination > li:nth-of-type(1) {
  display: none;
}
#inscription-chantiers-entreprise .image-wrapper .wrapped-content .form-column .pagination > li > span,
#inscription-chantiers-entreprise .image-wrapper .wrapped-content .form-column .pagination > li > a {
  color: #474747;
  text-decoration: none;
  background-color: #d8f7f9;
  border: none;
}
#inscription-chantiers-entreprise .image-wrapper .wrapped-content .form-column .pagination > li > span.active,
#inscription-chantiers-entreprise .image-wrapper .wrapped-content .form-column .pagination > li > a.active,
#inscription-chantiers-entreprise .image-wrapper .wrapped-content .form-column .pagination > li > span:hover,
#inscription-chantiers-entreprise .image-wrapper .wrapped-content .form-column .pagination > li > a:hover,
#inscription-chantiers-entreprise .image-wrapper .wrapped-content .form-column .pagination > li > span:focus,
#inscription-chantiers-entreprise .image-wrapper .wrapped-content .form-column .pagination > li > a:focus {
  color: #ff804b;
  text-decoration: none;
}
#inscription-chantiers-entreprise .image-wrapper .wrapped-content .form-column .pagination > li:last-of-type a {
  color: #ff804b;
}
#inscription-chantiers-entreprise .image-wrapper .wrapped-content .form-column .pagination > li.active span {
  color: #ff804b;
}
#inscription-chantiers-entreprise .image-wrapper .wrapped-content .form-column .cgu-wapper {
  margin-right: -15px;
  margin-left: -15px;
  padding-left: 15px;
}
#inscription-chantiers-entreprise .image-wrapper .wrapped-content .form-column .cgu-wapper .checkbox {
  display: inline-block;
}
#inscription-chantiers-entreprise .image-wrapper .wrapped-content .form-column .cgu-wapper .checkbox label {
  min-height: 17px;
}
#inscription-chantiers-entreprise .image-wrapper .wrapped-content .form-column .cgu-wapper .cgu {
  font-size: 1.2rem;
  line-height: 2.2rem;
  display: inline;
  margin-left: 7px;
  color: #353535;
  vertical-align: text-bottom;
}
#inscription-chantiers-entreprise .image-wrapper .wrapped-content .form-column .cgu-wapper .cgu a {
  text-decoration: underline;
  color: #474747;
}
#inscription-chantiers-entreprise .image-wrapper .wrapped-content .form-column .button.orange1 {
  padding: 10px 40px;
  margin: 20px auto 40px;
}
#inscription-chantiers-entreprise .image-wrapper .wrapped-content .wording-column {
  margin-top: 60px;
  background-color: rgba(255, 255, 255, 0.8);
  border: 4px solid #d8f7f9;
}
@media (max-width:991px) {
  #inscription-chantiers-entreprise .image-wrapper .wrapped-content .wording-column {
    margin-top: 0;
  }
}
@media (max-width:991px) {
  #inscription-chantiers-entreprise .image-wrapper .wrapped-content .wording-column .title {
    display: none;
  }
}
#inscription-chantiers-entreprise .image-wrapper .wrapped-content .wording-column img {
  margin-top: 5px;
}
#inscription-chantiers-entreprise .image-wrapper .wrapped-content .wording-column p {
  font-size: 1.6rem;
  line-height: 2.2rem;
}
#inscription-chantiers-entreprise .image-wrapper .wrapped-content .wording-column p.sub-title {
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #474747;
  font-size: 2.3rem;
  line-height: 3.2rem;
  margin-top: 10px;
  text-align: center;
}
#inscription-chantiers-entreprise .image-wrapper .wrapped-content .wording-column p.testimony {
  margin: 30px 0 10px;
  text-align: right;
  color: #727272;
}
#inscription-chantiers-entreprise .image-wrapper .wrapped-content .wording-column h1 {
  font-size: 1.6rem;
  line-height: 2.2rem;
}
#inscription-chantiers-entreprise .image-wrapper .wrapped-content .wording-column h1.sub-title {
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #474747;
  font-size: 2.3rem;
  line-height: 3.2rem;
  margin-top: 10px;
  text-align: center;
  text-transform: none;
}
#inscription-chantiers-entreprise .image-wrapper .wrapped-content .reassurances {
  margin: 40px 0;
}
#inscription-chantiers-entreprise .image-wrapper .wrapped-content .reassurances p {
  font-size: 1.6rem;
  line-height: 2.8rem;
  margin-bottom: 20px;
  color: #FFFFFF;
}
#inscription-chantiers-entreprise .image-wrapper .wrapped-content .reassurances p .block {
  display: block;
}
#inscription-chantiers-entreprise .contacts {
  color: #FFFFFF;
  background-color: #11404d;
  padding: 30px 0;
}
#inscription-chantiers-entreprise .contacts p {
  font-size: 2rem;
  line-height: 2rem;
}
#inscription-chantiers-entreprise .contacts p:last-of-type {
  font-size: 1.2rem;
}
#inscription-chantiers-entreprise .contacts a {
  color: #FFFFFF;
  font-size: 2.4rem;
  text-align: center;
  display: block;
  font-weight: bold;
  margin-top: 20px;
}
#inscription-chantiers-entreprise .contacts a:hover,
#inscription-chantiers-entreprise .contacts a:focus {
  color: #FFFFFF;
}
#inscription-chantiers-entreprise .cgu p {
  color: #727272;
  font-size: 1.2rem;
  line-height: 1.2rem;
}
#inscription-chantiers-entreprise .cgu p a {
  color: #727272;
  text-decoration: none;
}
#inscription-chantiers-entreprise .cgu p a:hover,
#inscription-chantiers-entreprise .cgu p a:focus {
  color: #727272;
}
#press .grid-top .object-repeater-container .logo-container {
  display: table;
}
@media (max-width:767px) {
  #press .grid-top .object-repeater-container .logo-container {
    height: 70px;
  }
}
#press .grid-top .object-repeater-container .logo-container .positioner {
  display: table-cell;
  vertical-align: middle;
}
#press .grid-top .object-repeater-container .logo-container .positioner .logo {
  background-image: url('/images/habitatpresto/sprites.png');
  background-repeat: no-repeat;
  margin: 30px auto 0 auto;
}
#press .grid-top .object-repeater-container .logo-container .positioner .logo.directmatin {
  background-position: 0 -64px;
  width: 109px;
  height: 26px;
}
#press .grid-top .object-repeater-container .logo-container .positioner .logo.marieclaire {
  background-position: -115px -62px;
  width: 114px;
  height: 31px;
}
#press .grid-top .object-repeater-container .logo-container .positioner .logo.rmc {
  background-position: -238px -63px;
  width: 81px;
  height: 34px;
}
#press .grid-top .object-repeater-container .logo-container .positioner .logo.maisonmagazine {
  background-position: -327px -62px;
  width: 71px;
  height: 39px;
}
#press .grid-top .object-repeater-container .logo-container .positioner .logo.telepoche {
  background-position: -404px -57px;
  width: 50px;
  height: 39px;
}
#press .grid-top .object-repeater-container .logo-container .positioner .logo.rustica {
  background-position: -458px -62px;
  width: 104px;
  height: 33px;
}
#press .grid-top .object-repeater-container .logo-container .positioner .logo.lofficiel {
  background-position: -564px -61px;
  width: 82px;
  height: 20px;
}
#press .grid-top .object-repeater-container .logo-container .positioner .logo.rtl {
  background-position: 0 -98px;
  width: 109px;
  height: 29px;
}
#press .grid-top .object-repeater-container .logo-container .positioner .logo.ecomaison {
  background-position: -116px -100px;
  width: 114px;
  height: 48px;
}
#press .grid-top .object-repeater-container .logo-container .positioner .logo.maisonapart {
  background-position: -651px -58px;
  width: 59px;
  height: 37px;
}
#press .section-button {
  margin-top: 60px;
  text-align: center;
  padding-bottom: 60px;
}
#press .section-button .button {
  color: #ff804b;
  border: 1px solid #ff804b;
  font-weight: bold;
  background-color: transparent;
  transition: 0.3s;
  border-radius: 0.8rem 0.8rem 0.8rem 0.8rem;
  padding: 10px 20px;
  display: inline-block;
  margin-bottom: 20px;
}
#press .section-button .button:hover {
  color: white;
  border: 1px solid #ff804b;
  font-weight: bold;
  background-color: #ff804b;
  transition: 0.3s;
  border-radius: 0.8rem 0.8rem 0.8rem 0.8rem;
}
/*******block chantier***********/
#chantier .sprite-fleche {
  background-position: -124px -226px;
  width: 25px;
  height: 21px;
  background-image: url('/images/habitatprestopro/sprites.png');
  background-repeat: no-repeat;
  margin-left: 5px;
}
#chantier .block-chantier {
  border: 3px solid #28a3b2;
  background-color: #FFF;
  width: 100%;
  height: 215px;
  border-radius: 3px;
  padding: 10px;
  line-height: 18px;
  font-size: 14px;
  cursor: pointer;
}
@media (max-width:1199px) {
  #chantier .block-chantier {
    height: 250px;
  }
}
@media (max-width:767px) {
  #chantier .block-chantier {
    height: 250px;
  }
}
#chantier .block-chantier .nom-cat {
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #474747;
  font-size: 3.0rem;
  line-height: 3.3rem;
  color: #28a3b2;
  border-bottom: 1px solid #28a3b2;
  width: 230px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
@media (max-width:991px) {
  #chantier .block-chantier .nom-cat {
    font-size: 2.4rem;
    width: 230px;
  }
}
@media (max-width:767px) {
  #chantier .block-chantier .nom-cat {
    font-size: 2.4rem;
    width: 170px;
  }
}
#chantier .block-chantier .date-chantier {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.2rem;
  line-height: 1.5rem;
  color: #474747;
  font-size: 12px;
  color: #727272;
}
#chantier .block-chantier .consumer-chantier {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.2rem;
  line-height: 1.5rem;
  color: #474747;
  font-size: 12px;
  color: #727272;
  text-align: right;
}
#chantier .block-chantier .sprite-fleche {
  background-position: -106px -211px;
  width: 5px;
  height: 8px;
  background-image: url('/images/habitatprestopro/sprites.png');
  background-repeat: no-repeat;
}
#chantier .block-chantier .ico-cat {
  position: absolute;
  margin-top: -30px;
}
@media (max-width:767px) {
  #chantier .block-chantier .ico-cat {
    right: 0;
  }
}
.reference-quotation {
  background-color: #cefbfd;
  padding: 20px;
  width: 100%;
  height: 300px;
  text-align: justify;
}
@media (max-width:1199px) {
  .reference-quotation {
    height: 350px;
  }
}
@media (max-width:991px) {
  .reference-quotation {
    height: auto;
  }
}
.reference-quotation .qmark {
  margin: 10px auto 0 auto;
  background-image: url('/images/habitatprestopro/sprites.png');
  background-repeat: no-repeat;
  background-position: 0 -170px;
  width: 47px;
  height: 46px;
}
.reference-quotation .block-text {
  height: 230px;
}
@media (max-width:1199px) {
  .reference-quotation .block-text {
    height: 300px;
  }
}
@media (max-width:991px) {
  .reference-quotation .block-text {
    height: auto;
  }
}
.reference-quotation .text {
  background-image: url('/images/habitatprestopro/guillemet-haut.png'), url('/images/habitatprestopro/guillemet-bas.png');
  background-repeat: no-repeat;
  background-position: left top, right bottom;
  line-height: 24px;
  padding: 15px;
  text-indent: 3em;
}
@media (max-width:767px) {
  .reference-quotation .text {
    font-size: 1.5rem;
  }
}
.reference-quotation .reference-source {
  text-align: right;
}
.wrapper-gris {
  background-color: #f0f0f0;
}
.block-hotline {
  padding: 15px;
}
.block-hotline h5 {
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #474747;
}
.block-hotline .sprite-tel {
  background-position: 0 -101px;
  width: 106px;
  height: 106px;
  background-image: url('/images/habitatprestopro/sprites.png');
  background-repeat: no-repeat;
}
.block-hotline .tel {
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #474747;
  font-weight: bold;
  color: #28a3b2;
  line-height: 45px;
}
.community h5 {
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #474747;
}
.community .facebook {
  background-image: url('/images/habitatprestopro/sprites.png');
  background-repeat: no-repeat;
  background-position: -323px -115px;
  width: 44px;
  height: 42px;
}
.community .twitter {
  background-image: url('/images/habitatprestopro/sprites.png');
  background-repeat: no-repeat;
  background-position: -370px -115px;
  width: 42px;
  height: 42px;
}
.block-img-article .big-img {
  width: 100%;
  height: 580px;
  background: url('/images/habitatprestopro/edito.jpg') no-repeat center;
  background-size: cover;
  cursor: pointer;
}
.block-img-article .horizontal-img {
  width: 100%;
  height: 210px;
  background: url('/images/habitatprestopro/edito.jpg') no-repeat center;
  background-size: cover;
  cursor: pointer;
}
.block-img-article .little-img {
  width: 100%;
  height: 210px;
  background: url('/images/habitatprestopro/edito.jpg') no-repeat center;
  background-size: cover;
  cursor: pointer;
}
.block-img-article .vertical-img {
  width: 100%;
  height: 580px;
  background: url('/images/habitatprestopro/edito.jpg') no-repeat center;
  background-size: cover;
}
.block-img-article h3 {
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #474747;
  margin-top: 5px;
  font-size: 24px;
  line-height: 28px;
}
.block-img-article h3 a,
.block-img-article h3 a:active,
.block-img-article h3 a:visited {
  color: #474747;
  text-decoration: none;
}
.block-img-article h3 a:hover {
  text-decoration: underline;
}
.block-img-article p {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: #474747;
  margin-top: 5px;
  line-height: 24px;
  text-align: justify;
}
.block-img-article p a,
.block-img-article p a:hover,
.block-img-article p a:active,
.block-img-article p a:visited {
  color: #474747;
  text-decoration: none;
}
.block-img-article .date {
  color: #727272;
  font-size: 12px;
}
.news-horizontal {
  background-color: #28a3b2;
  width: 100%;
}
.news-horizontal h2 {
  color: #FFF;
  font-weight: bold;
}
.news-horizontal p.subtitle {
  color: #FFF;
  font-weight: normal;
}
.news-horizontal input {
  font-size: 1.8rem;
  height: 45px;
}
.news-horizontal select {
  font-size: 1.8rem;
  height: 45px;
}
.news-horizontal .bt-bleu {
  border: 1px solid #FFF;
  font-size: 18px;
  border-radius: 3px;
  height: 45px;
  text-transform: uppercase;
  font-weight: bold;
}
.article-plus-lus .nb-plus-lus {
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #474747;
  font-weight: bold;
  margin-top: 20px;
}
.article-plus-lus h4 {
  font-size: 2.4rem;
  line-height: 3rem;
  color: #474747;
  margin-top: 10px;
}
.article-plus-lus .date {
  color: #727272;
  margin-top: 10px;
  font-size: 12px;
  display: block;
}
.article-plus-lus a.gris,
.article-plus-lus a.gris:active,
.article-plus-lus a.gris:visited {
  color: #727272;
  text-decoration: none;
}
.article-plus-lus a.gris:hover {
  color: #727272;
  text-decoration: underline;
}
.article-plus-lus .sprite-fleche-grise {
  background-image: url('/images/habitatprestopro/sprites.png');
  background-repeat: no-repeat;
  background-position: -293px -228px;
  width: 6px;
  height: 11px;
  display: inline-block;
  margin-right: 5px;
  margin-left: 5px;
}
.article-plus-lus .filet {
  background: url('/images/habitatprestopro/pointille-gris.png') repeat-x;
  height: 5px;
  display: block;
}
.article-plus-lus .bold {
  font-weight: bold;
}
.article-plus-lus .chemin {
  line-height: 18px;
}
@media (max-width:767px) {
  .article-plus-lus .margin {
    margin-right: 15px;
    margin-left: 15px;
  }
}
#categories .grid-top {
  margin-top: -30px;
}
#categories .grid-top .object-repeater-container {
  float: left;
  width: 100%;
}
#categories .grid-top .object-repeater-container .cat-cell {
  padding: 30px 10px 0 10px;
}
#categories .grid-top .object-repeater-container .cat-cell .img-cat {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: table;
  height: 265px;
  width: 100%;
  cursor: pointer;
}
#categories .grid-top .object-repeater-container .cat-cell .img-cat .bg_opacity {
  background-color: rgba(0, 0, 0, 0.45);
  display: table-cell;
  height: 100%;
  overflow: hidden;
  text-align: center;
  vertical-align: middle;
  width: 100%;
}
#categories .grid-top .object-repeater-container .cat-cell .img-cat .bg_opacity .txt {
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #474747;
  color: white;
}
#categories .grid-top .object-repeater-container .cat-cell .img-cat .bg_opacity:hover {
  background-color: rgba(0, 0, 0, 0.7);
  transition: background-color 500ms linear;
}
#categories .grid-top .object-repeater-container .cat-cell .img-cat .bg_opacity:hover .positioner .button-container {
  bottom: -60px;
  /*.transition(bottom 250ms linear 0s);*/
}
#categories .grid-top .object-repeater-container .cat-cell .img-cat .bg_opacity:hover .positioner .button-container .button {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity=(100));
  opacity: 1;
  transition: opacity 350ms linear;
}
#categories .grid-top .object-repeater-container .cat-cell .img-cat .bg_opacity .positioner {
  margin: 0 auto;
  position: relative;
  width: 100%;
}
#categories .grid-top .object-repeater-container .cat-cell .img-cat .bg_opacity .positioner .button-container {
  position: absolute;
  left: 0;
  bottom: -100px;
  width: 100%;
}
#categories .grid-top .object-repeater-container .cat-cell .img-cat .bg_opacity .positioner .button-container .button {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=(0));
  opacity: 0;
  color: white;
  border: 1px solid #ff804b;
  font-weight: bold;
  background-color: #ff804b;
  transition: 0.3s;
  border-radius: 0.8rem 0.8rem 0.8rem 0.8rem;
  padding: 10px 20px;
  margin: 0 auto;
  display: inline-block;
  padding: 10px 15px;
}
#categories .grid-top .object-repeater-container .cat-cell .img-cat .bg_opacity .positioner .button-container .button:hover {
  color: white;
  border: 1px solid #e77544;
  font-weight: bold;
  background-color: #e77544;
  transition: 0.3s;
  border-radius: 0.8rem 0.8rem 0.8rem 0.8rem;
}
#categories .section-button {
  margin-top: 42px;
  text-align: center;
  padding-bottom: 40px;
}
#categories .section-button .button {
  color: #ff804b;
  border: 1px solid #ff804b;
  font-weight: bold;
  background-color: transparent;
  transition: 0.3s;
  border-radius: 0.8rem 0.8rem 0.8rem 0.8rem;
  padding: 10px 20px;
  display: inline-block;
  margin-bottom: 20px;
}
#categories .section-button .button:hover {
  color: white;
  border: 1px solid #ff804b;
  font-weight: bold;
  background-color: #ff804b;
  transition: 0.3s;
  border-radius: 0.8rem 0.8rem 0.8rem 0.8rem;
}
.how-it-works {
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.44);
}
.how-it-works h2 {
  margin: 30px 0;
  font-size: 3.6rem;
  line-height: 3.6rem;
  color: #474747;
  text-align: center;
  font-weight: bold;
}
.how-it-works .object-repeater-container {
  margin-top: 10px;
  width: 100%;
  float: left;
}
.how-it-works .object-repeater-container .icon {
  display: none;
  background-image: url('/images/habitatprestopro/sprites.png');
  background-repeat: no-repeat;
  margin: 0 auto 10px auto;
}
@media (min-width:768px) {
  .how-it-works .object-repeater-container .icon {
    display: block;
  }
}
.how-it-works .object-repeater-container .icon.needs {
  background-position: -306px 0;
  width: 105px;
  height: 106px;
}
.how-it-works .object-repeater-container .icon.contacts {
  background-position: -413px 0;
  width: 105px;
  height: 105px;
}
.how-it-works .object-repeater-container .icon.home {
  background-position: -520px 0;
  width: 105px;
  height: 105px;
}
.how-it-works .object-repeater-container .heading {
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #474747;
  text-align: center;
  margin-bottom: 10px;
  font-weight: normal;
  text-transform: initial;
}
@media (min-width:992px) {
  .how-it-works .object-repeater-container .heading {
    width: 200px;
    margin: 0 auto 10px auto;
  }
}
.how-it-works .object-repeater-container .text {
  text-align: center;
  display: none;
  line-height: 18px;
}
@media (min-width:768px) {
  .how-it-works .object-repeater-container .text {
    display: block;
  }
}
.how-it-works .object-repeater-container .object-cell-separator {
  display: table;
}
@media (min-width:768px) {
  .how-it-works .object-repeater-container .object-cell-separator {
    height: 220px;
    margin: 0 -5%;
  }
}
.how-it-works .object-repeater-container .object-cell-separator .positioner {
  display: table-cell;
  vertical-align: middle;
}
.how-it-works .object-repeater-container .object-cell-separator .positioner .arrow {
  background-image: url('/images/habitatprestopro/sprite-navigation.png');
  background-repeat: no-repeat;
  margin: 0 auto;
}
@media (max-width:767px) {
  .how-it-works .object-repeater-container .object-cell-separator .positioner .arrow {
    transform: rotate(90deg);
  }
}
.how-it-works.blue {
  background-color: #11404d;
  padding-bottom: 40px;
}
.how-it-works.blue h2 {
  color: white;
}
.how-it-works.blue .object-repeater-container .heading {
  color: white!important;
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #474747;
  font-weight: bold;
  text-transform: uppercase;
}
.how-it-works.blue .object-repeater-container .text {
  color: white;
}
.how-it-works.blue .object-repeater-container .object-cell-separator .positioner .arrow {
  background-position: 0 0;
  width: 25px;
  height: 45px;
}
.how-it-works.grey {
  background-color: #28a3b2;
  padding: 10px 0 40px 0;
}
.how-it-works.grey h2 {
  color: white;
  margin-top: 0 !important;
}
.how-it-works.grey .object-repeater-container .heading {
  color: white !important;
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #474747;
  font-weight: bold;
  text-transform: uppercase;
}
.how-it-works.grey .object-repeater-container .text {
  color: white;
}
.how-it-works.grey .object-repeater-container .object-cell-separator .positioner .arrow {
  background-position: 0 0;
  width: 25px;
  height: 45px;
}
.how-it-works .section-button {
  margin-top: 42px;
  text-align: center;
  padding-bottom: 40px;
}
.how-it-works .section-button .button {
  color: white;
  border: 1px solid #ff804b;
  font-weight: bold;
  background-color: #ff804b;
  transition: 0.3s;
  border-radius: 0.8rem 0.8rem 0.8rem 0.8rem;
  padding: 10px 20px;
  font-size: 18px;
}
.how-it-works .section-button .button:hover {
  color: white;
  border: 1px solid #e77544;
  font-weight: bold;
  background-color: #e77544;
  transition: 0.3s;
  border-radius: 0.8rem 0.8rem 0.8rem 0.8rem;
}
#references .grid-top .object-repeater-container {
  float: left;
  width: 100%;
}
@media (max-width:991px) {
  #references .grid-top .object-repeater-container .object-cell:nth-child(n+4) .cell {
    display: none;
  }
}
#references .grid-top .object-repeater-container .cell .reference-quotation {
  background-color: #cefbfd;
  padding: 20px;
  width: 100%;
  height: 300px;
  text-align: justify;
}
@media (max-width:1199px) {
  #references .grid-top .object-repeater-container .cell .reference-quotation {
    height: 370px;
  }
}
@media (max-width:991px) {
  #references .grid-top .object-repeater-container .cell .reference-quotation {
    height: 410px;
    margin-bottom: 20px;
  }
}
#references .grid-top .object-repeater-container .cell .reference-quotation .qmark {
  margin: 10px auto 0 auto;
  background-image: url('/images/habitatprestopro/sprites.png');
  background-repeat: no-repeat;
  background-position: 0 -170px;
  width: 47px;
  height: 46px;
}
#references .grid-top .object-repeater-container .cell .reference-quotation .block-text {
  height: 230px;
}
@media (max-width:1199px) {
  #references .grid-top .object-repeater-container .cell .reference-quotation .block-text {
    height: 310px;
  }
}
@media (max-width:991px) {
  #references .grid-top .object-repeater-container .cell .reference-quotation .block-text {
    height: 330px;
  }
}
#references .grid-top .object-repeater-container .cell .reference-quotation .text {
  background-image: url('/images/habitatprestopro/guillemet-haut.png'), url('/images/habitatprestopro/guillemet-bas.png');
  background-repeat: no-repeat;
  background-position: left top, right bottom;
  line-height: 24px;
  padding: 15px;
  text-indent: 3em;
}
@media (max-width:767px) {
  #references .grid-top .object-repeater-container .cell .reference-quotation .text {
    font-size: 1.5rem;
  }
}
#references .grid-top .object-repeater-container .arrow-separator-green .arrow {
  background-position: -110px 0;
  width: 33px;
  height: 22px;
}
#references .grid-top .object-repeater-container .reference-source {
  text-align: right;
  margin-top: 5px;
  line-height: 18px;
}
@media (max-width:767px) {
  #references .grid-top .object-repeater-container .reference-source {
    margin-bottom: 15px;
  }
}
#references .grid-top .object-repeater-container .reference-source strong {
  font-weight: bold;
}
#references .section-button {
  margin-top: 60px;
  text-align: center;
  padding-bottom: 40px;
}
#references .section-button .button {
  color: #ff804b;
  border: 1px solid #ff804b;
  font-weight: bold;
  background-color: transparent;
  transition: 0.3s;
  border-radius: 0.8rem 0.8rem 0.8rem 0.8rem;
  padding: 10px 20px;
  display: inline-block;
  margin-bottom: 20px;
}
#references .section-button .button:hover {
  color: white;
  border: 1px solid #ff804b;
  font-weight: bold;
  background-color: #ff804b;
  transition: 0.3s;
  border-radius: 0.8rem 0.8rem 0.8rem 0.8rem;
}
#references .section-title {
  margin: 60px 0;
}
#universes .grid-top {
  margin-top: -30px;
}
@media (max-width:767px) {
  #universes .grid-top {
    margin: 0 20px;
  }
}
@media (max-width:767px) {
  #universes .grid-top .object-cell:nth-child(n+6) .universe {
    display: none;
  }
}
@media (max-width:991px) {
  #universes .grid-top .object-cell:nth-child(n+8) .universe {
    display: none;
  }
}
#universes .grid-top .universe {
  position: relative;
  margin-top: 30px;
  float: left;
  height: 480px;
}
@media (max-width:767px) {
  #universes .grid-top .universe {
    height: auto;
    padding-bottom: 30px;
    border-top: 0;
    border-bottom: 1px solid #cecece;
    width: 100%;
  }
}
@media (max-width:767px) {
  #universes .grid-top .universe .content {
    height: 220px;
  }
}
#universes .grid-top .universe .content .img-container {
  float: left;
}
@media (max-width:767px) {
  #universes .grid-top .universe .content .img-container {
    position: absolute;
    top: 50px;
    left: 0;
    padding: 0;
  }
}
#universes .grid-top .universe .content .img-container .img-univers {
  height: 170px;
  width: 100%;
}
#universes .grid-top .universe .content .heading-container {
  float: left;
}
@media (max-width:767px) {
  #universes .grid-top .universe .content .heading-container {
    position: absolute;
    top: 0;
    left: 0;
  }
}
#universes .grid-top .universe .content .heading-container .heading {
  float: left;
  padding-bottom: 10px;
}
@media (max-width:767px) {
  #universes .grid-top .universe .content .heading-container .heading {
    width: 100%;
  }
}
@media (min-width:768px) {
  #universes .grid-top .universe .content .heading-container .heading {
    margin-top: 10px;
    border-top: 0;
    border-bottom: 1px solid #cecece;
    width: 100%;
  }
}
#universes .grid-top .universe .content .heading-container .heading .title {
  font-size: 2.4rem;
  line-height: 3rem;
  color: #474747;
  float: left;
}
@media (max-width:767px) {
  #universes .grid-top .universe .content .heading-container .heading .title {
    width: 100%;
    text-align: center;
  }
}
#universes .grid-top .universe .content .articles {
  float: left;
  height: 200px;
}
@media (min-width:768px) {
  #universes .grid-top .universe .content .articles {
    margin-top: 20px;
  }
}
@media (max-width:767px) {
  #universes .grid-top .universe .content .articles {
    float: right;
    top: 50px;
    right: 0;
    height: auto;
  }
}
#universes .grid-top .universe .content .articles li {
  margin-bottom: 10px;
}
@media (max-width:767px) {
  #universes .grid-top .universe .content .articles .item:nth-child(n+3) {
    display: none;
  }
}
#universes .grid-top .universe .content .articles .item .title {
  color: #727272;
  text-transform: uppercase;
}
#universes .grid-top .universe .content .articles .item .title a {
  color: #727272;
}
#universes .grid-top .universe .content .articles .item .text {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: #474747;
  margin: 5px 0 10px;
  line-height: 18px;
  display: inline-block;
}
#universes .grid-top .universe .content .articles .item .text:hover {
  text-decoration: underline;
}
#universes .grid-top .universe .link {
  margin-top: 20px;
  color: #ff804b;
  float: right;
}
@media (max-width:767px) {
  #universes .grid-top .universe .link {
    margin-top: 40px;
  }
}
#universes .grid-top .universe .link:hover {
  text-decoration: underline;
}
#universes .grid-top .universe .link .arrow {
  background-image: url('/images/habitatprestopro/sprite-navigation.png');
  background-repeat: no-repeat;
  background-position: -50px -20px;
  width: 5px;
  height: 8px;
  display: inline-block;
  vertical-align: middle;
}
#universes .section-button {
  margin-top: 60px;
  text-align: center;
  padding-bottom: 60px;
}
#universes .section-button .button {
  color: #ff804b;
  border: 1px solid #ff804b;
  font-weight: bold;
  background-color: transparent;
  transition: 0.3s;
  border-radius: 0.8rem 0.8rem 0.8rem 0.8rem;
  padding: 10px 20px;
  display: inline-block;
  margin-bottom: 20px;
}
#universes .section-button .button:hover {
  color: white;
  border: 1px solid #ff804b;
  font-weight: bold;
  background-color: #ff804b;
  transition: 0.3s;
  border-radius: 0.8rem 0.8rem 0.8rem 0.8rem;
}
#consoneo .navigation-container {
  box-shadow: none;
  background-color: #FFFFFF;
}
@media (min-width:1200px) {
  .popup-promo .modal-wide .modal-dialog {
    width: 44%;
  }
}
.popup-promo .modal-header {
  border-bottom: 0 solid #FFF !important;
}
#band-benefits .wrapper-bleu {
  width: 100%;
  background-color: #cefbfd;
}
#band-benefits .wrapper-bleu .no-padding {
  padding: 0;
}
#band-benefits .wrapper-bleu .bg-bleu {
  background-color: #28a3b2;
}
#band-benefits .wrapper-bleu .bg-bleu h2 {
  font-weight: bold;
  font-size: 4.8rem;
  line-height: 4.8rem;
  color: white;
  font-size: 36px;
}
#band-benefits .wrapper-bleu .bg-bleu p {
  color: #FFF;
  line-height: 18px;
  margin-bottom: 40px;
}
#band-benefits .wrapper-bleu .bg-bleu-clair {
  background-color: #cefbfd;
}
#band-benefits .wrapper-bleu .bg-bleu-clair h2 {
  font-weight: bold;
  font-size: 4.8rem;
  line-height: 4.8rem;
  color: #474747;
  font-size: 36px;
}
#band-benefits .wrapper-bleu .bg-bleu-clair p {
  margin-bottom: 40px;
}
#band-benefits .wrapper-bleu .sprite-chantier {
  background-position: 0 0;
  width: 101px;
  height: 101px;
  background-image: url('/images/habitatprestopro/sprites.png');
  background-repeat: no-repeat;
  margin-top: 40px;
  margin-bottom: 10px;
}
#band-benefits .wrapper-bleu .sprite-search {
  background-position: -101px 0;
  width: 101px;
  height: 101px;
  background-image: url('/images/habitatprestopro/sprites.png');
  background-repeat: no-repeat;
  margin-top: 40px;
  margin-bottom: 10px;
}
#band-benefits .wrapper-bleu .sprite-coupe {
  margin-top: 40px;
  margin-bottom: 10px;
}
.offer-listing {
  display: flex;
  flex-flow: row wrap;
}
.offer-listing .offer-item {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 2rem;
  border: 3px solid #f5f5f5;
  border-radius: 10px;
}
.offer-listing .offer-item > div:last-child {
  margin-top: auto;
}
.offer-listing .offer-item.primary {
  border-color: #ff844b;
}
.offer-listing .offer-item .panel-heading {
  padding: 2rem;
  color: #333333;
  background-color: #f5f5f5;
  border-color: 1px solid #f2f2f2;
  border-radius: 5px 5px 0 0;
  text-align: center;
  font-size: 28px;
  font-weight: bold;
  font-family: 'Open Sans Condensed', sans-serif;
}
.offer-listing .offer-item .panel-heading.primary {
  position: relative;
  background-color: #ff844b;
  color: white;
}
.offer-listing .offer-item .panel-heading.primary > strong {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: block;
  font-size: 13px;
  margin-bottom: 1rem;
}
.offer-listing .offer-item .offer-price {
  padding: 2rem;
  font-size: xx-large;
  font-weight: bold;
}
.offer-listing .offer-item .offer-price.noprice {
  padding: 3rem;
}
.offer-listing .offer-item .offer-price p {
  font-size: small;
  margin-top: 1rem;
}
.offer-listing .offer-item .offer-body {
  display: flex;
  flex-direction: column;
  padding: 20px;
}
.offer-listing .offer-item .offer-body .offer-informations {
  color: #ff844b;
  font-weight: bold;
  line-height: 2.4rem;
}
.offer-listing .offer-item .offer-body .offer-description {
  margin-top: 2rem;
  padding: 1rem;
  list-style: circle;
  line-height: 2.4rem;
}
.offer-listing .offer-item .offer__btn {
  margin: 2rem;
  padding: 10px 20px;
  border: 2px solid #ff844b;
  border-radius: 10px;
  color: #FFF;
  font-weight: bold;
  color: #ff844b;
  transition: 0.3s;
}
.offer-listing .offer-item .offer__btn:hover {
  transform: scale(1.05);
}
.offer-listing .offer-item .offer__btn.primary {
  background-color: #ff844b;
  color: white;
}
.offers-review {
  position: relative;
  padding: 2rem;
  background-color: #fbfbfb;
}
.offers-review img.heart {
  position: absolute;
  right: 10%;
  top: 20%;
  width: 320px;
  opacity: 0.04;
}
.offers-review .offer-item {
  display: flex;
  margin: 2rem 0;
}
.offers-review .offer-item img.guillemets {
  width: 100px;
  height: 100px;
}
.offers-review .offer-item .offer-content {
  max-width: 600px;
  display: flex;
  flex-direction: column;
  padding: 2rem;
}
.offers-review .offer-item .offer-content.second {
  margin-left: auto;
}
.offers-review .offer-item .offer-content p {
  line-height: 4rem;
  font-size: 18px;
}
.offers-review .offer-item .offer-content i {
  margin-top: 2rem;
}
.offers-our-services {
  position: relative;
  padding: 2rem;
  margin-bottom: 3rem;
}
.offers-our-services .title-option {
  font-size: 2.4rem;
  line-height: 3rem;
  color: #474747;
  text-transform: uppercase;
}
.offers-our-services .picto-1 {
  background-image: url('/images/habitatprestopro/sprites.png');
  background-repeat: no-repeat;
  background-position: 0 -283px;
  width: 83px;
  height: 83px;
}
.offers-our-services .picto-2 {
  background-image: url('/images/habitatprestopro/sprites.png');
  background-repeat: no-repeat;
  background-position: -85px -285px;
  width: 83px;
  height: 83px;
}
.offers-our-services .picto-3 {
  background-image: url('/images/habitatprestopro/sprites.png');
  background-repeat: no-repeat;
  background-position: -171px -287px;
  width: 83px;
  height: 83px;
}
.offers-our-services .picto-4 {
  background-image: url('/images/habitatprestopro/sprites.png');
  background-repeat: no-repeat;
  background-position: -257px -287px;
  width: 83px;
  height: 83px;
}
.offers-our-services .picto-5 {
  background-image: url('/images/habitatprestopro/sprites.png');
  background-repeat: no-repeat;
  background-position: -342px -289px;
  width: 83px;
  height: 83px;
}
.offers-our-services .picto-6 {
  background-image: url('/images/habitatprestopro/sprites.png');
  background-repeat: no-repeat;
  background-position: -426px -289px;
  width: 83px;
  height: 83px;
}
.offers-our-services p {
  text-align: center;
}
.ask__review__form {
  position: relative;
  padding: 3rem;
  text-align: center;
}
.ask__review__form .dismiss__btn {
  position: absolute;
  top: 0;
  right: 0;
  color: #969696;
  font-weight: bold;
  font-size: 1.8rem;
  cursor: pointer;
}
.ask__review__form > img {
  width: 200px;
  height: auto;
}
.ask__review__form .title__review {
  margin: 2rem 0;
  text-align: center;
  font-weight: bold;
}
.ask__review__form > p {
  text-align: center;
  line-height: 2.2rem;
}
.ask__review__form .confirm {
  display: flex;
  justify-content: center;
}
.ask__review__form .confirm .accept__btn {
  padding: 1rem 2rem;
  border: 3px solid #ff804b;
  border-radius: 1rem;
  background: #ff804b;
  color: #ffffff;
  font-size: 1.4rem;
  font-weight: bold;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
}
.ask__review__form .confirm .cancel__btn {
  display: flex;
  align-items: center;
  padding: 1rem 2rem;
  margin: 0 1rem;
  border: 3px solid #969696;
  border-radius: 1rem;
  color: #969696;
  font-weight: bold;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
}
.update-contact-form {
  border: 1px solid #cecece;
  background-color: #FFFFFF;
  margin-bottom: 30px;
  width: 100%;
  padding: 15px;
}
.update-contact-form h2 {
  font-size: 2.4rem;
  line-height: 3rem;
  color: #474747;
  text-transform: uppercase;
  margin-bottom: 15px;
}
.update-contact-form .bt-border-orange:hover {
  background-color: #ff580c;
  color: #FFF;
  border: 1px solid #ff580c;
  border-radius: 3px;
  padding: 10px 20px;
}
.update-contact-form .block-input-form {
  margin-bottom: 20px;
  padding: 0;
}
.update-contact-form .block-input-form .bt_orange_1 {
  font-size: 13px;
}
.update-contact-form .block-input-form .input-group-btn {
  width: 0 !important;
}
.update-contact-form .block-input-form input:last-child {
  height: 39px;
  width: 100%;
  padding-left: 215px;
}
.update-contact-form form .form-control {
  font-size: 1.4rem;
}
.update-contact-form form .form-group {
  margin-right: 0;
  margin-left: 0;
  text-align: left;
  width: 100%;
}
.update-contact-form form .form-group label {
  padding: 5px 0;
}
.update-contact-form form .add-label {
  line-height: 18px;
  font-size: 14px;
  color: #303030;
  text-align: justify;
}
.update-contact-form form textarea {
  margin-bottom: 15px;
  min-height: 200px;
}
.update-contact-form form .alert-img {
  color: #ff580c;
  font-style: italic;
  font-size: 14px;
  margin-bottom: 15px;
  background-color: #ffd9cc;
  border: 1px solid #ff580c;
  padding: 10px;
}
.update-contact-form form select {
  height: 33px !important;
}
.update-contact-form form .select2-container {
  width: 200px !important;
}
.update-contact-form form .select2-choice {
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 34px;
  line-height: 30px;
  padding: 0 0 0 11px;
}
.update-contact-form form .select2-choice .select2-arrow {
  height: 26px;
  position: absolute;
  background: #fff;
  border-left: none;
}
.update-contact-form form .select2-choice .select2-arrow b {
  border-color: #555 transparent transparent transparent;
  border-style: solid;
  border-width: 6px 3px 0 3px;
  height: 0;
  margin-left: 4px;
  position: absolute;
  top: 50%;
  width: 0;
}
@media screen and (max-width:991px) {
  .update-contact-form {
    margin-top: 20px;
  }
}
.reset-password {
  border: 1px solid #cecece;
  background-color: #FFFFFF;
  margin-bottom: 30px;
  width: 100%;
  padding: 15px;
}
.reset-password h2 {
  font-size: 2.4rem;
  line-height: 3rem;
  color: #474747;
  text-transform: uppercase;
  margin-bottom: 15px;
}
.reset-password .reset-password-block {
  width: 50%;
  margin: auto;
}
@media screen and (max-width:991px) {
  .reset-password .reset-password-block {
    width: 100%;
  }
}
.reset-password .reset-password-block .form-group {
  margin-right: 0;
  margin-left: 0;
}
.reset-password .reset-password-block ul {
  list-style: inside;
}
.reset-password .reset-password-block ul li {
  line-height: 20px;
}
.reset-password .reset-password-block .new label,
.reset-password .reset-password-block .old label {
  margin-top: 15px;
  margin-bottom: 10px;
}
.reset-password .bt-border-orange {
  margin-top: 20px;
}
.reset-password .bt-border-orange:hover {
  background-color: #ff580c;
  color: #FFF;
  border: 1px solid #ff580c;
  border-radius: 3px;
  padding: 10px 20px;
}
@media screen and (max-width:991px) {
  .reset-password {
    margin-top: 20px;
  }
}
.reject-project {
  border: 1px solid #cecece;
  background-color: #FFFFFF;
  margin-bottom: 30px;
  width: 100%;
  padding: 15px;
}
.reject-project h2 {
  font-family: 'Open Sans', sans-serif;
  font-size: 2.4rem;
  line-height: 3rem;
  color: #474747;
  margin-bottom: 15px;
}
.reject-project form {
  width: 50%;
  margin: auto;
}
@media screen and (max-width:991px) {
  .reject-project form {
    width: 100%;
  }
}
.reject-project form select {
  width: 100% !important;
  margin-top: 15px;
  font-size: 1.3rem;
}
.reject-project form .bt-border-orange {
  margin-top: 20px;
}
.reject-project form .bt-border-orange:hover {
  background-color: #ff580c;
  color: #FFF;
  border: 1px solid #ff580c;
  border-radius: 3px;
  padding: 10px 20px;
}
@media screen and (max-width:991px) {
  .reject-project {
    margin-top: 20px;
  }
}
.load-docs-form {
  border-radius: 1rem;
  background-color: #FFFFFF;
  margin-bottom: 30px;
  width: 100%;
  padding: 15px;
}
.load-docs-form h2 {
  font-size: 2.4rem;
  line-height: 3rem;
  color: #474747;
  text-transform: uppercase;
  margin-bottom: 15px;
}
.load-docs-form .bt-border-orange:hover {
  background-color: #ff580c;
  color: #FFF;
  border: 1px solid #ff580c;
  border-radius: 3px;
  padding: 10px 20px;
}
.load-docs-form .alert-pdf {
  color: #ff580c;
  font-style: italic;
  font-size: 13px;
  margin-bottom: 15px;
  background-color: #ffd9cc;
  border: 1px solid #ff580c;
  padding: 10px;
}
.load-docs-form .form-control {
  font-size: 1.3rem;
}
.load-docs-form textarea {
  margin-top: 15px;
  margin-bottom: 15px;
}
.load-docs-form .form-load-doc {
  margin-bottom: 15px;
}
.load-docs-form .form-load-doc .bt_orange_1 {
  font-size: 13px;
  border-radius: 0;
}
.load-docs-form .input-group,
.load-docs-form .select-date {
  margin-bottom: 15px;
}
.load-docs-form .input-group .monthpicker,
.load-docs-form .select-date .monthpicker {
  margin-left: 10px;
  margin-right: 10px;
}
@media screen and (max-width:575px) {
  .load-docs-form .input-group .monthpicker,
  .load-docs-form .select-date .monthpicker {
    margin: 10px 0 10px 0;
  }
}
.load-docs-form select {
  width: 100% !important;
  margin-bottom: 15px;
  font-size: 1.3rem;
}
@media screen and (max-width:991px) {
  .load-docs-form {
    margin-top: 20px;
  }
}
#form-horizontal {
  background-color: #28a3b2;
  width: 100%;
}
#form-horizontal h2 {
  color: #FFF;
  font-weight: bold;
}
#form-horizontal p.subtitle {
  color: #FFF;
  font-weight: normal;
}
#form-horizontal input {
  font-size: 1.8rem;
  height: 45px;
}
@media (max-width:767px) {
  #form-horizontal .form-group {
    margin-right: 0;
    margin-left: 0;
  }
}
#form-horizontal select {
  font-size: 1.8rem;
  height: 45px;
}
#form-horizontal .bt-bleu {
  border: 1px solid #FFF;
  font-size: 18px;
  border-radius: 3px;
  text-transform: uppercase;
  font-weight: bold;
  padding: 13px 20px;
}
.form-vertical {
  background-color: #28a3b2;
  width: 100%;
  color: #ffffff;
  padding: 15px;
}
.form-vertical h2 {
  color: #FFF !important;
  font-weight: bold;
}
.form-vertical p.subtitle {
  color: #FFF;
}
.form-vertical input {
  font-size: 1.8rem;
  height: 45px;
}
@media (max-width:767px) {
  .form-vertical .form-group {
    margin-right: 0;
    margin-left: 0;
  }
}
.form-vertical select {
  font-size: 1.8rem;
  height: 45px;
}
.form-vertical .bt-bleu {
  border: 1px solid #FFF;
  font-size: 18px;
  border-radius: 3px;
  padding: 13px 20px;
  text-transform: uppercase;
  font-weight: bold;
}
.accepted_project__modal {
  position: relative;
  padding: 3rem;
  text-align: center;
}
@media (max-width: 480px) {
  .accepted_project__modal {
    padding: 1rem;
    font-size: 1.6rem;
  }
}
.accepted_project__modal .dismiss__btn {
  position: absolute;
  top: 0;
  right: 0;
  color: #969696;
  font-weight: bold;
  font-size: 1.8rem;
  cursor: pointer;
}
.accepted_project__modal > img {
  width: 200px;
  height: auto;
}
.accepted_project__modal .title {
  margin: 2rem 0;
  font-weight: bold;
}
.accepted_project__modal .info {
  line-height: 2.5rem;
  text-align: center;
}
.accepted_project__modal .cta {
  margin: 4rem 0;
}
.accepted_project__modal .cta .js-consumer-contact button {
  padding: 1rem 2rem;
  border: 3px solid #ff804b;
  border-radius: 1rem;
  background: #ff804b;
  color: #FFFFFF;
  font-size: 1.4rem;
  font-weight: bold;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
}
.accepted_project__modal .tips {
  padding: 2rem;
  border-radius: 1rem;
  background-color: #daebff;
}
.accepted_project__modal .tips p {
  text-align: left;
  line-height: 2.2rem;
}
.accepted_project__modal .tips p .link {
  display: block;
  text-decoration: underline;
  cursor: pointer;
  color: #ff804b;
}
.accountant-card {
  margin-bottom: 30px;
}
.accountant-card img {
  width: 350px;
}
#cpdp .heading {
  margin: 60px 0 30px 0;
  text-align: left;
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #474747;
  text-transform: uppercase;
}
#cpdp .text {
  text-align: justify;
  display: block;
  line-height: 24px;
}
#cpdp .text a,
#cpdp .text a:visited,
#cpdp .text a:active {
  color: #ff804b;
}
#cpdp .text a:hover {
  color: #ff804b;
  text-decoration: underline;
}
#cpdp .text strong {
  font-weight: bold;
}
@media (min-width:768px) {
  #cpdp .text {
    display: block;
  }
}
#cpdp .text.mb-60 {
  margin-bottom: 60px;
}
#cpdp ul {
  margin-top: 10px;
  margin-left: 20px;
  margin-bottom: 10px;
}
#cpdp ul li {
  list-style: disc inside;
  margin-top: 10px;
}
#cgv .heading {
  margin: 60px 0 30px 0;
  text-align: left;
  font-size: 3.3rem;
  line-height: 3.6rem;
  color: #474747;
  text-transform: uppercase;
}
#cgv .text {
  text-align: justify;
  display: block;
  line-height: 24px;
}
#cgv .text a,
#cgv .text a:visited,
#cgv .text a:active {
  color: #ff804b;
}
#cgv .text a:hover {
  color: #ff804b;
  text-decoration: underline;
}
#cgv .text strong {
  font-weight: bold;
}
@media (min-width:768px) {
  #cgv .text {
    display: block;
  }
}
#cgv ul {
  margin-top: 10px;
  margin-left: 20px;
  margin-bottom: 10px;
}
#cgv ul li {
  list-style: disc inside;
  margin-top: 10px;
}
#cgv .mb-60 {
  margin-bottom: 60px;
}
.applimobile img {
  max-width: 350px;
  margin: 0 auto;
}
@media (max-width:991px) {
  .applimobile img {
    width: 100%;
  }
}
@media (max-width:767px) {
  .applimobile img {
    width: 100%;
    padding: 0 30px;
  }
}
.applimobile a.not-available-btn {
  border: 1px solid #727272;
  border-radius: 1.4rem;
  padding: 15px;
  color: #727272;
  line-height: 24px;
  width: 200px;
  height: 80px;
  display: block;
  cursor: inherit;
  opacity: 0.8;
}
@media (max-width:767px) {
  .applimobile a.not-available-btn {
    float: none;
    margin: auto;
  }
}
.applimobile a.bt-appli,
.applimobile a.bt-appli:active,
.applimobile a.bt-appli:visited {
  border: 1px solid #727272;
  border-radius: 5px;
  padding: 15px;
  height: 80px;
  color: #727272;
  line-height: 24px;
  width: 200px;
}
.applimobile a.bt-appli:hover {
  border: 1px solid #000;
  background-color: #000;
  color: #FFF;
}
.applimobile .ico-apple {
  background-image: url(/images/habitatprestopro/apple-1.png);
  background-repeat: no-repeat;
  background-position: center center;
  width: 41px;
  height: 50px;
  display: inline-block;
  float: left;
  margin-right: 15px;
}
.applimobile a.apple:hover .ico-apple {
  background-image: url(/images/habitatprestopro/apple-2.png);
  background-repeat: no-repeat;
  background-position: center center;
}
.applimobile .ico-android {
  background-image: url(/images/habitatprestopro/play-1.png);
  background-repeat: no-repeat;
  background-position: center center;
  width: 41px;
  height: 50px;
  display: inline-block;
  float: left;
  margin-right: 15px;
}
.applimobile a.android:hover .ico-android {
  background-image: url(/images/habitatprestopro/play-2.png);
  background-repeat: no-repeat;
  background-position: center center;
}
.applimobile .store-appli {
  font-size: 20px;
}
@media (max-width:767px) {
  .applimobile .mt-100 {
    margin-top: 40px;
  }
}
@media (min-width:768px) and (max-width:991px) {
  .applimobile .pl-40 {
    padding-left: 40px;
  }
}
.applimobile .pr-40 {
  padding-right: 40px;
}
@media (max-width:991px) {
  .applimobile .pr-40 {
    padding-right: 0;
  }
}
.application__advantages__block {
  margin: 5rem 0;
}
.application__advantages__block .application__advantages {
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 1175px) {
  .application__advantages__block .application__advantages {
    flex-direction: column;
  }
}
.application__advantages__block .application__advantages .advantages__header__mobile,
.application__advantages__block .application__advantages .advantages__header__desktop {
  width: 100%;
  margin-bottom: 5rem;
  text-align: center;
  font-weight: bold;
  font-size: 2.6rem;
}
.application__advantages__block .application__advantages .advantages__header__mobile {
  display: none;
}
@media only screen and (max-width: 1175px) {
  .application__advantages__block .application__advantages .advantages__header__mobile {
    display: block;
  }
}
@media only screen and (max-width: 1175px) {
  .application__advantages__block .application__advantages .advantages__header__desktop {
    display: none;
  }
}
.application__advantages__block .application__advantages .main__img {
  width: 30%;
  margin-left: 10rem;
  min-width: 400px;
}
@media only screen and (max-width: 1175px) {
  .application__advantages__block .application__advantages .main__img {
    max-width: 400px;
    margin: 5rem 0 0 0;
  }
}
@media only screen and (max-width: 460px) {
  .application__advantages__block .application__advantages .main__img {
    min-width: 100%;
    max-width: 100%;
    margin: 5rem 0;
    padding: 0 3rem;
  }
}
.application__advantages__block .application__advantages .advantages__content {
  width: 70%;
  margin-left: 5rem;
}
@media only screen and (max-width: 1175px) {
  .application__advantages__block .application__advantages .advantages__content {
    width: auto;
    margin: 5rem 0;
  }
}
.application__advantages__block .application__advantages .advantages__content .advantages__list {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}
.application__advantages__block .application__advantages .advantages__content .advantages__list .advantages__item {
  display: flex;
  flex-flow: column;
  flex-basis: calc(39%);
  align-items: center;
  width: 250px;
  margin: 0 2rem 5rem 2rem;
}
@media only screen and (max-width: 440px) {
  .application__advantages__block .application__advantages .advantages__content .advantages__list .advantages__item {
    flex-basis: auto;
    width: 100%;
  }
}
.application__advantages__block .application__advantages .advantages__content .advantages__list .advantages__item .advantages__img {
  width: 80px;
  height: 80px;
  margin: 0 auto;
}
.application__advantages__block .application__advantages .advantages__content .advantages__list .advantages__item .advantages__title {
  margin: 2rem 0;
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
}
.application__advantages__block .application__advantages .advantages__content .advantages__list .advantages__item > p {
  line-height: 2.8rem;
  font-size: 1.6rem;
  text-align: center;
}
.badge-info-block {
  padding: 1rem;
}
.badge-info-block .strong {
  font-weight: bold;
}
.badge-info-block .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0;
  font-weight: bold;
}
.badge-info-block .header button {
  border: none;
  background: none;
  font-size: 2rem;
  cursor: pointer;
}
.badge-info-block .horizontal-line {
  border: 0.1rem solid #dad8d8;
  margin-bottom: 3rem;
}
.badge-info-block .info {
  margin: 1rem 0;
  text-align: center;
}
.badge-info-block .info .title {
  font-weight: bold;
  color: #ff914d;
  margin-bottom: 2rem;
}
.badge-info-block .info .desc {
  display: flex;
  line-height: 2rem;
  margin-bottom: 2rem;
}
.badge-info-block .info img {
  margin-left: 2rem;
  width: 50px;
  height: auto;
}
.badge-info-block .progress-info {
  padding: 2rem;
  margin-bottom: 3rem;
  background-color: #cefffa;
  color: black;
}
.badge-info-block .progress-info .title {
  margin-bottom: 1rem;
}
.badge-info-block .progress-info .get-badge {
  margin-bottom: 2rem;
}
.badge-info-block .progress-info ul {
  list-style: circle;
  padding-left: 2rem;
  text-align: left;
  line-height: 2rem;
}
.badge-info-block .footer {
  line-height: 2rem;
  margin-bottom: 1rem;
}
.badge-info-block .footer > a {
  color: #ff914d;
  text-decoration: underline;
}
.badge-info-block .exit {
  padding: 1rem;
  text-align: right;
}
.badge-info-block .exit .exit-button {
  padding: 0.6rem 2rem;
  border-radius: 5rem;
  background-color: #ff914d;
  color: #FFFFFF;
  font-weight: bold;
  border: none;
  cursor: pointer;
}
.pro-callback-unreachable-block {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  line-height: 2rem;
}
.pro-callback-unreachable-block .dismiss__btn {
  text-align: right;
  cursor: pointer;
}
.pro-callback-unreachable-block img {
  margin: 0 auto;
}
.pro-callback-unreachable-block .title {
  font-weight: bold;
  margin-top: 2rem;
  margin-bottom: 4rem;
  text-align: center;
}
.pro-callback-unreachable-block .sms_content {
  text-align: center;
  margin-bottom: 3rem;
}
.pro-callback-unreachable-block .info {
  text-align: center;
  margin-bottom: 2rem;
}
.pro-callback-unreachable-block form {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  margin-bottom: 3rem;
}
.pro-callback-unreachable-block form .accept__btn {
  padding: 1rem 2rem;
  border: 3px solid #ff804b;
  border-radius: 1rem;
  background: #ff804b;
  color: #ffffff;
  font-size: 1.4rem;
  font-weight: bold;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
}
.pro-callback-unreachable-block form .cancel__btn {
  display: flex;
  align-items: center;
  padding: 1rem 2rem;
  margin: 0 1rem;
  border: 3px solid #969696;
  border-radius: 1rem;
  color: #969696;
  font-weight: bold;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  height: 100%;
}
.getting-new-badge .header {
  text-align: right;
}
.getting-new-badge .header button {
  border: none;
  background: none;
  font-size: 2rem;
  cursor: pointer;
}
.getting-new-badge .info {
  padding: 1rem;
  margin: 1rem 0;
  text-align: center;
}
.getting-new-badge .info .title {
  font-weight: bold;
  color: #ff914d;
  margin-bottom: 2rem;
}
.getting-new-badge .info .desc {
  display: flex;
  line-height: 2rem;
  margin-bottom: 2rem;
}
.getting-new-badge .info img {
  width: 80px;
  height: auto;
  margin-bottom: 2rem;
}
.getting-new-badge .next-step {
  padding: 2rem 4rem;
  background-color: #cefffa;
  text-align: center;
}
.getting-new-badge .exit {
  padding: 1rem;
  text-align: right;
}
.getting-new-badge .exit .exit-button {
  padding: 0.6rem 2rem;
  border-radius: 5rem;
  background-color: #ff914d;
  color: #FFFFFF;
  font-weight: bold;
  border: none;
  cursor: pointer;
}
.kilometer-zone {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  line-height: 2rem;
}
.kilometer-zone .dismiss__btn {
  text-align: right;
  cursor: pointer;
}
.kilometer-zone .text-center {
  text-align: center;
}
.kilometer-zone form {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  margin: 3rem 0;
}
.kilometer-zone form .accept__btn {
  padding: 1rem 2rem;
  border: 3px solid #ff804b;
  border-radius: 1rem;
  background: #ff804b;
  color: #ffffff;
  font-size: 1.4rem;
  font-weight: bold;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
}
.kilometer-zone form .cancel__btn {
  display: flex;
  align-items: center;
  padding: 1rem 2rem;
  margin: 0 1rem;
  border: 3px solid #969696;
  border-radius: 1rem;
  color: #969696;
  font-weight: bold;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  height: 100%;
}
.ekomi-widget-container #ekw105 {
  width: 234px;
  height: auto;
  background: #f4f4f4;
  margin-top: 10px;
  border-radius: 3px;
  padding: 0;
}
.ekomi-widget-container #ekw105 .widget-right {
  margin-top: 6px;
  width: 200px;
}
.ekomi-widget-container #ekw105 .widget-right .stars-grey {
  margin-left: 15px;
  margin-right: 11px;
}
.ekomi-widget-container #ekw105 .widget-right .descrip-row,
.ekomi-widget-container #ekw105 .widget-right .main-heading {
  display: none;
}
.ekomi-widget-container #ekw105 .widget-right .rating-obtained {
  color: #b8b5b5;
  font-size: 18px;
  top: 6px;
  position: relative;
}
.ekomi-widget-container #ekw105 .widget-right .rating-obtained .outoff {
  font-weight: normal;
}
.ekomi-widget-container #ekw105 .widget-right .rating-row {
  margin-bottom: 6px;
}
.ekomi-widget-container #ekw105 .widget-left {
  transform: none;
  top: 0;
  margin-top: 10px;
  width: 24px;
  height: 24px;
}
.ekomi-widget-container #ekw105 .widget-left a {
  background: url('/theme/lisboa/ekomi-like.png');
  width: 20px;
  height: 22px;
  margin-left: 13px;
  background-size: 100%;
  background-repeat: no-repeat;
  display: block;
}
.ekomi-widget-container #ekw105 .widget-left a img {
  display: none;
}
.ekomi-widget-container #ekw105 .stars-white {
  margin: 6px 5px 6px 10px;
  background: url('https://sw-assets.ekomiapps.de/resources/stars_light_grey-132x24.png.png') #f4f4f4 no-repeat;
}
.ekomi-widget-container #ekw100 .wrapper-left {
  display: none;
}
.ekomi-widget-container #ekw100 .wrapper-right {
  width: 100%;
}
@media screen and (max-width:991px) {
  .quickaccess .ekomi-widget-container,
  .login-contact .ekomi-widget-container {
    display: none;
  }
}
@media screen and (max-width:767px) {
  .ekomi-footer {
    display: inline-block;
    margin-left: 15px;
    margin-bottom: 10px;
  }
  .ekomi-footer .ekomi-overlay {
    height: 47px;
    width: 234px;
    z-index: 999;
    position: absolute;
    cursor: pointer;
  }
}
@media screen and (min-width:768px) {
  .ekomi-footer {
    display: none;
  }
}
@media screen and (min-width:992px) {
  .ekomi-overlay {
    height: 47px;
    width: 234px;
    z-index: 999;
    position: absolute;
    cursor: pointer;
  }
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}

/*# sourceMappingURL=habitatprestopro_less.css.map */
